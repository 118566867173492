import Vue from 'vue/dist/vue.esm'
import axios from 'axios';
import qs from 'qs';
import { debounce } from 'lodash';

import TurbolinksAdapter from 'vue-turbolinks';
Vue.use(TurbolinksAdapter);

document.addEventListener('turbolinks:load', () => {
  const element = document.getElementById('admin-order-edit-vue');
  if (element === null) {
    return
  }

  new Vue({
    el: "#admin-order-edit-vue",
    data: function (){
      return {
        order: {},
        consigneeSearch: {
          modalInstance: null,
          users: [],
          q: {}
        },
        order_user_id: null,
        isSubmitButtonDisabled: false,
        isUserIdInputDisabled: false,
        userIdCngMsg: "",
        carStatusChangeAlert:false,
        CheckTtCopyExistence:{
          remove_tt_copy_1:false,
          remove_tt_copy_2:false,
          remove_tt_copy_3:false,
          remove_tt_copy_4:false,
          remove_tt_copy_5:false,
        }
      }
    },

    watch: {
      // ユーザーidが変更された場合、ユーザーidの変更による前金下限確認
      'order.user_id': debounce(function(afterUserId, _) {
        if (this.order_user_id && afterUserId && (this.order_user_id !== afterUserId)) {
          this.isUserIdInputDisabled = true; // ユーザーid入力欄を無効化
          this.userIdCngMsg = "";

          this.comparePaymentLevelPercentage(this.order_user_id, afterUserId).then((result) => {
            this.userIdCngMsg = result.detail;
          }).catch((error) => {
            console.debug(`compare PaymentLevelPercentage error`, error);
          }).finally(() => {
            this.isUserIdInputDisabled = false; // ユーザーid入力欄を有効化
          });
        }
      }, 500) // 500ms delay
    },

    mounted: function(){
      const container = this.$el;
      this.order = JSON.parse(container.getAttribute('data-order'));
      //tt_copy保存済みをtrue 
      for(let i = 0; i < 5 ; i++) {
        var number = i + 1
        if (document.getElementById(`order_tt_copy_${number}`) === null) {
          this.CheckTtCopyExistence[`remove_tt_copy_${number}`] = true
        }
      }
      this.order_user_id = this.order['user_id'];
    },
    methods: {
      showConsigneeSearchModal() {
        this.$set(this.consigneeSearch.q,"user_id_eq", this.order['user_id']);
        this.consigneeSearch.modalInstance = $(this.$refs.consigneeSearchModal).modal({keyboard: false, show:true});
        const $modalBody = $('.modal-body', this.$refs.consigneeSearchModal)
        $modalBody.height($modalBody.height() + 1)
      },
      hideConsigneeSearchModal() {
        if (this.consigneeSearch.modalInstance){
          this.consigneeSearch.modalInstance.modal('hide');
        }
      },
      onClickConsigneesSearch: async function (){
        const result = await axios.get(
          '/admin/api/search_consignees.json',
          {
            params: {
              q: this.consigneeSearch.q
            },
            paramsSerializer: function(params) {
              return qs.stringify(params, {arrayFormat: 'brackets'})
            }
          }
        )
        this.consigneeSearch.users = result.data;
      },
      //過去のConsigneeからDHLに値を入れる
      onClickFillConsignee4: async function(id){
        const result = await axios.get(`/admin/api/search_consignee/${id}`)
        const user = result.data

        if (!user) {
          return
        }
        this.order['dhl_username'] = user['username'];
        this.order['dhl_country_id'] = "";
        this.order['dhl_address'] = user['address'];
        this.order['dhl_city'] = "";
        this.order['dhl_tel'] = user['tel'];
        this.order['dhl_email'] = user['email'];
        this.hideConsigneeSearchModal();
      },
      showNotifyPartySearchModal(index) {
        this.$set(this.consigneeSearch.q,"user_id_eq", this.order['user_id']);
        this.consigneeSearch.modalInstance = $(this.$refs.notifyPartySearchModal).modal({keyboard: false, show:true});
        const $modalBody = $('.modal-body', this.$refs.notifyPartySearchModal)
        $modalBody.height($modalBody.height() + 1)
      },
      hideNotifyPartySearchModal() {
        if (this.consigneeSearch.modalInstance){
          this.consigneeSearch.modalInstance.modal('hide');
        }
      },
      //過去のNotify PartyからDHLに値を入れる
      onClickFillNotifyParty4: async function(id){
        const result = await axios.get(`/admin/api/search_consignee/${id}`)
        const user = result.data

        if (!user) {
          return
        }
        this.order['dhl_username'] = user['notify_party_username'];
        this.order['dhl_country_id'] = "";
        this.order['dhl_city'] = "";
        this.order['dhl_address'] = user['notify_party_address'];
        this.order['dhl_tel'] = user['notify_party_tel'];
        this.order['dhl_email'] = user['notify_party_email'];
        this.hideConsigneeSearchModal();
      },
      showDHLSearchModal(index){
        this.$set(this.consigneeSearch.q,"user_id_eq", this.order['user_id']);
        this.consigneeSearch.modalInstance = $(this.$refs.dhlSearchModal).modal({keyboard: false, show:true});
        const $modalBody = $('.modal-body', this.$refs.dhlSearchModal)
        $modalBody.height($modalBody.height() + 1)
      },
      //過去のDHL情報からDHLに値を入れる
      onClickFillDhl: async function(id){
        const result = await axios.get(`/admin/api/search_consignee/${id}`)
        const user = result.data

        if (!user) {
          return
        }
        this.order['dhl_username'] = user['dhl_username'];
        this.order['dhl_country_id'] = user['dhl_country_id'];
        this.order['dhl_city'] = user['dhl_city'];
        this.order['dhl_address'] = user['dhl_address'];
        this.order['dhl_tel'] = user['dhl_tel'];
        this.order['dhl_email'] = user['dhl_email'];
        this.order['dhl_postal_code'] = user['dhl_postal_code'];
        this.hideConsigneeSearchModal();
      },
      searchCarHistoryByVin: async function(){
        const new_vin = $('#after_vin')[0].value
        if(!new_vin){
          return
        }
        const result = await axios.get(`/admin/orders/search_car_history/${new_vin}`)
        const last_car_history_id = result.data
        $('#after_car_history_id')[0].value= last_car_history_id
      },

      // tt_copy削除のチェックボックスを選択した時、最終的に0枚になるか？確認する。
      onChangeRemoveTtCopy: function(column_name){
        if (this.order[`${column_name}`]){
          // 今から削除するtt_copyをfalseにする
          this.CheckTtCopyExistence[`${column_name}`] = false
        }else{
          // チェックボックス を外すとture
          this.CheckTtCopyExistence[`${column_name}`] = true
        }
        // tt_copy削除の結果、このorderのtt_copyが0枚になるケースを判定
        var tt_copy_remaining = false
        for (let key in this.CheckTtCopyExistence) {
          if ( this.CheckTtCopyExistence[key] ==  true ){
            tt_copy_remaining = true
            break
          }
        }
        // 画面上に注意文を出力
        if (!tt_copy_remaining){
          this.carStatusChangeAlert = true
        }else{
          this.carStatusChangeAlert = false
        }
      },
      // tt_copyファイル参照時するとcarStatusChangeAlertはfalse
      onChangeTtCopy: function(num){
        this.CheckTtCopyExistence[`remove_tt_copy_${num}`] = true
        this.carStatusChangeAlert = false
      },

      comparePaymentLevelPercentage: async function(beforeUserId, afterUserId) {
        const result = await axios.get(
          '/admin/api/compare_two_users_payment_level_percentage.json',
          {
            params: {
              before_user_id: beforeUserId,
              after_user_id: afterUserId
            },
          }
        )
        return result.data
      },

      // 登録ボタンを無効化
      disableSubmitButton() {
        this.isSubmitButtonDisabled = true;
      },
      // 登録ボタンを有効化
      enableSubmitButton() {
        this.isSubmitButtonDisabled = false;
      }
    }
  })
});