import Vue from 'vue/dist/vue.esm'
// import axios from 'axios';
// import qs from 'qs';

import TurbolinksAdapter from 'vue-turbolinks';
Vue.use(TurbolinksAdapter);

import {enablePaymentDetailModal} from "./admin_orders_payment_detail_modal";

document.addEventListener('turbolinks:load', () => {

  const selector = '.order_update_files_area';
  const elements = document.querySelectorAll(selector);
  if (elements.length === 0) {
      return
  }

  // 支払い詳細モーダル機能を有効化します。
  enablePaymentDetailModal();

  new Vue({
    el: ".order_update_files_area",
    data: function (){
      return {
        order: {},
        carStatusChangeAlert:false,
        CheckTtCopyExistence:{
          remove_tt_copy_1:false,
          remove_tt_copy_2:false,
          remove_tt_copy_3:false,
          remove_tt_copy_4:false,
          remove_tt_copy_5:false,
        }
      }
    },
    mounted: function(){
      const container = this.$el;
      this.order = JSON.parse(container.getAttribute('data-order'));

      //tt_copy保存済みをtrue 
      for(let i = 0; i < 5 ; i++) {
        var number = i + 1
        if (document.getElementById(`order_tt_copy_${number}`) === null) {
          this.CheckTtCopyExistence[`remove_tt_copy_${number}`] = true
        }
      }
    },
    methods: {
      // tt_copy削除のチェックボックスを選択した時、最終的に0枚になるか？確認する。
      onChangeRemoveTtCopy: function(column_name){
        if (this.order[`${column_name}`]){
          // チェックボックを入れる
          this.CheckTtCopyExistence[`${column_name}`] = false
        }else{
          // チェックボックを外す
          this.CheckTtCopyExistence[`${column_name}`] = true
        }
        // tt_copy削除の結果、このorderのtt_copyが0枚になるケースを判定
        var tt_copy_remaining = false
        for (let key in this.CheckTtCopyExistence) {
          if ( this.CheckTtCopyExistence[key] ==  true ){
            tt_copy_remaining = true
            break
          }
        }
        // 画面上に注意文を出力
        if (!tt_copy_remaining){
          this.carStatusChangeAlert = true
        }else{
          this.carStatusChangeAlert = false
        }
      },
      // tt_copyファイル参照時するとcarStatusChangeAlertはfalse
      onChangeTtCopy: function(num){
        this.CheckTtCopyExistence[`remove_tt_copy_${num}`] = true
        this.carStatusChangeAlert = false
      },
    }
  })
});
