import Vue from 'vue/dist/vue.esm'
import axios from 'axios'

import TurbolinksAdapter from 'vue-turbolinks';
import _ from "lodash";
Vue.use(TurbolinksAdapter);

document.addEventListener('turbolinks:load', () => {
    const selector = '.auction-search-vue';
    const elements = document.querySelectorAll(selector);
    if (elements.length === 0) {
        return
    }

    new Vue({
        el: selector,
        data: {
            csrfToken: '',

            auctionSites : {},
            checkedWeekdays: [],
            showAuctionSites: false,
            showAuctionSitesCollapse: null,

            loading: false,
            modelNames: [],
            q: {
                'auction_date_gteq': '',
                'auction_date_lteq': '',
                'marka_name_eq': '',
                'model_name_eq': '',
                'year_gteq': '',
                'year_lteq': '',
                'mileage_gteq': '',
                'mileage_lteq': '',
                'eng_v_gteq': '',
                'eng_v_lteq': '',
                'start_gteq': '',
                'start_lteq': '',
                'finish_gteq': '',
                'finish_lteq': '',
                'kuzov_in': [],
                'rate_in': [],
                'auction_in': [],
                'color_in': [],
                'status_in': [],
                'kpp_in': [],
                'lots_text': [],
                'auction_exhibit_favorites_group_in':[],
            }
        },
        mounted() {
            this.csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

            const container = this.$el;//<div class="auction-search-vue">

            this.auctionSites = JSON.parse(container.getAttribute('data-auction-sites'));

            Object.assign(this.q, JSON.parse(container.getAttribute('data-q')))
            this.showAuctionSites = (this.q['auction_in'].length > 0)
            this.showAuctionSitesCollapse = $('.show-auction-sites-collapse').collapse({
                toggle: this.showAuctionSites
            })

            this.loadModelNames();
        },
        destroyed() {
            this.showAuctionSitesCollapse.collapse('dispose')
        },
        watch:{
            showAuctionSites: function(isShown){
                if (isShown) {
                    this.showAuctionSitesCollapse.collapse('show')
                } else {
                    this.showAuctionSitesCollapse.collapse('hide')
                }
            }
        },
        computed: {
            disabled: function(){
                return this.loading;
            }
        },
        methods: {
            onClickWeekday: function (){
                this.showAuctionSites = true;
                for (const dayName in this.auctionSites) {
                    let auctions = [];
                    if (this.checkedWeekdays.includes(dayName)) {
                        auctions = _.concat(this.q['auction_in'], this.auctionSites[dayName])
                    } else {
                        auctions = _.difference(this.q['auction_in'], this.auctionSites[dayName])
                    }
                    this.q['auction_in'] = _.uniq(auctions)
                }
            },
            async loadModelNames() {
                this.loading = true;
                this.modelNames = [];
                try{
                    const result = await axios.get('/auction/model_names.json',{
                        params: {
                            'maker_name': this.q['marka_name_eq']
                        }
                    });
                    this.modelNames = result.data;
                }catch (e) {
                    console.log(e)
                }
                this.loading = false;
            },
            async saveToMySearch() {
                this.loading = true;
                try{
                    const result = await axios.post(`/auction/queries.json`,
                        {
                            auction_query:{
                                json: JSON.stringify(this.q)
                            }
                        },
                        {
                            headers:{
                                "X-Requested-With": "XMLHttpRequest",
                                "X-CSRF-Token": this.csrfToken
                            }
                        }
                    );
                    location.href = '/auction/queries'
                }catch (e) {
                    console.log(e)
                }
                this.loading = false;
            }
        }
    })
})