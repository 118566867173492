import Vue from 'vue/dist/vue.esm'
import axios from 'axios'

import TurbolinksAdapter from 'vue-turbolinks';
Vue.use(TurbolinksAdapter);

document.addEventListener('turbolinks:load', () => {
  const element = document.getElementById('admin-amend-bl-form-vue');
  if (element === null) {
    return
  }

  new Vue({
    el: "#admin-amend-bl-form-vue",
    data: function (){
      return {
        invoice: {},
        change_datas: {},//編集画面でのみ利用
        toPortOptions: [['選択して下さい', '']],
        loading: false,
        finalDestinationCityNotes:null,
        cfsNotes:null
      }
    },
    mounted: function(){
      const container = this.$el;//<div id="admin-invoice-form-vue">

      const properties = JSON.parse(container.getAttribute('data-properties-json'));
      this.invoice = properties['invoice'];
      
      this.change_datas = properties['change_datas'];//編集画面でのみ利用
      
      //編集画面の場合change_datasに値がある場合、そちらを利用（ない場合はinvoiceの値を利用）
      if (this.change_datas){
        this.change_datas = JSON.parse(this.change_datas)

        if (this.change_datas['to_country_id']){
          this.invoice['to_country_id'] = this.change_datas['to_country_id'];
        }
        if (this.change_datas['to_port_id']){
          this.invoice['to_port_id'] = this.change_datas['to_port_id'];
        }
        if (this.change_datas['final_destination_country_id']){
          this.invoice['final_destination_country_id'] = this.change_datas['final_destination_country_id'];
        }
        if (this.change_datas['final_destination_city']){
          this.invoice['final_destination_city'] = this.change_datas['final_destination_city'];
        }
        if (this.change_datas['cfs']){
          this.invoice['cfs'] = this.change_datas['cfs'];
        }

      }

      //空文字列にしなければ<option value>選択して下さい</option>が表示されない問題を修正
      Object.keys(this.invoice).forEach((key)=>{
        if (this.invoice[key] === null) {
          this.invoice[key] = '';
        }
      })

      this.getToPortOptions();
      
    },
    methods: {
      onChangeToCountry: function (){
        this.invoice['to_port_id'] = '';
        this.getToPortOptions();
        this.cfsCheck();
      },
      onChangeFinalDestinationCountry: function (){
        this.invoice['final_destination_city'] = "";
        this.finalDestinationCityNotes= "Please reconfigure Final destination_city ";
        this.cfsCheck();
      },
      onChangefinalDestinationCity: function (){
        this.finalDestinationCityNotes = null;
      },
      onChangeCfs: function (){
        this.cfsCheck();
      },

      cfsCheck(){
        //TODO 
        //2元管理を避けるためadmin_invoice_form_vue.jsのcheckInspectionAndCfsメソッドと同じ処理を入れたい
        //next.js実装時に行う
   
        var country_id = this.invoice['final_destination_country_id'];
        if (!country_id) {
          country_id = this.invoice['to_country_id'];
        }
   
        // // country_id が KENIAの場合cfsの入力を推奨
        if (!this.invoice['cfs'] && country_id == "1") {
          this.cfsNotes = "This country may need a CFS nomination";
        } else{
          this.cfsNotes = null;
        }
      },

      getToPortOptions: async function (){
        const ports = []
        ports.push(['選択して下さい', ''])

        if(!this.invoice['to_country_id']){
          return;
        }
        this.loading = true;
        try{
          const result = await axios.get(`/api/port/${this.invoice['to_country_id']}`);
          this.toPortOptions = ports.concat(result.data);
        }catch (e){
          console.log(e)
        }
        this.loading = false;
      },
      

    },
  })

});