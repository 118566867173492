import Vue from 'vue/dist/vue.esm'
import axios from 'axios';
import qs from 'qs';

import TurbolinksAdapter from 'vue-turbolinks';
Vue.use(TurbolinksAdapter);

//import consumer from "../channels/consumer"

let $notification = null;

let subscription = null;
document.addEventListener('turbolinks:load', () => {
    const selector = '.admin_carried_forwards_transfer';
    const elements = document.querySelectorAll(selector);
    if (elements.length === 0) {
        return
    }
    
    new Vue({
      el: ".admin_carried_forwards_transfer",
      data: function (){
        return {
          carried_forward: {},
          Step:'',
          userSearch: {
            modalInstance: null,
            users: [],
            q: {}
          },
          userSearch: {
            modalInstance: null,
            users: [],
            q: {},
            purpose:''//利用目的（from_user_select or to_user_select）
          },
          //移動元のユーザー情報
          fromUserData:{
            user_id:null,
            name:"",
            dollar_balance:0,
            yen_balance:0,
            not_find:null
          },
          //移動先のユーザー情報
          toUserData:{
            user_id:null,
            name:"",
          }

        }
      },
      mounted: function(){
        const container = this.$el;
        this.carried_forward = JSON.parse(container.getAttribute('data-carried_forward'));
        //通貨の初期値
        if (this.carried_forward.currency == null){
          this.carried_forward.currency = 'dollar'
          this.Step = '0.01'
        }
        else if(this.carried_forward.currency == 'dollar'){
          this.Step = '0.01'
        }else if(this.carried_forward.currency == 'yen'){
          this.Step = '1'
          this.carried_forward.fee = Number(this.carried_forward.fee)
        }
      },
      beforeDestroy(){//インスタンスが破棄される前に
        if (this.userSearch.modalInstance){
          $('.modal-backdrop').remove();//モーダルを表示したままブラウザの戻るボタンや進むボタンを押した際に操作できなくなる問題対応
          this.userSearch.modalInstance.modal('dispose');
        }
      },
      methods: {
        showUserSearchModal(purpose) {
          this.userSearch.modalInstance = $(this.$refs.userSearchModal).modal({keyboard: false, show:true});
          const $modalBody = $('.modal-body', this.$refs.userSearchModal)
          $modalBody.height($modalBody.height() + 1)
          this.userSearch.purpose = purpose //検索フォームの利用用途（from_user_select／to_user_select）
        },
        hideUserSearchModal() {
          if (this.userSearch.modalInstance){
            this.userSearch.modalInstance.modal('hide');
          }
        },
        onClickChooseUser: function(userId){
          document.querySelector('#user_id').value = userId;
          this.hideUserSearchModal();
        },
        onClickUserSearch: async function (){
          const result = await axios.get(
              '/admin/api/search_user2.json',
              {
                params: {
                  q: this.userSearch.q
                },
                paramsSerializer: function(params) {
                  return qs.stringify(params, {arrayFormat: 'brackets'})
                }
              }
          )
          this.userSearch.users = result.data;
        },

        //From Userを選ぶ
        onClickFromUserSelect: function(userId){
          // ユーザー検索のモーダルを閉じる
          if (this.userSearch.modalInstance){
            this.userSearch.modalInstance.modal('hide');
            //検索をクリア
            this.userSearch.users = []
            this.userSearch.q = {}
            this.userSearch.purpose = ''
          }
          // 残高取得
          this.getCarriedForwardBalance(userId);
          // フォームにuser_idをセット
          document.querySelector('#from_user_id').value = userId;
        },
        //To Userを選ぶ
        onClickToUserSelect: function(userId, userName, adminUserId, adminUserUsername){
          // ユーザー検索のモーダルを閉じる
          if (this.userSearch.modalInstance){
            this.userSearch.modalInstance.modal('hide');
            //検索をクリア
            this.userSearch.users = []
            this.userSearch.q = {}
            this.userSearch.purpose = ''
          }
          // フォームにuser_idをセット
          document.querySelector('#to_user_id').value = userId;
          this.toUserData.user_id = userId
          this.toUserData.name = userName
          this.toUserData.admin_user_id = adminUserId
          this.toUserData.admin_user_username = adminUserUsername
        },
        //ユーザーの繰越金残高を取得する
        getCarriedForwardBalance: async function (userId){
          if(userId != ""){
            try{
              const result = await axios.get(`/admin/request_payment_confirmations/api/${userId}/carried_forward_user_balance`)
              this.fromUserData.user_id = result.data.user_id
              this.fromUserData.name = result.data.name
              this.fromUserData.dollar_balance = result.data.dollar_balance
              this.fromUserData.yen_balance = result.data.yen_balance
              this.fromUserData.not_find = result.data.not_find
              this.fromUserData.admin_user_id = result.data.admin_user_id
              this.fromUserData.admin_user_username = result.data.admin_user_username
            }catch (e){
              console.log(e)
            }
          }
        },
        //通貨によって金額のstepを変える
        currencyChange(currency) {
          if (currency == 'dollar'){
              this.Step = '0.01'
              this.carried_forward.fee = ''
            }else if(currency == 'yen'){
              this.Step = '1'
              this.carried_forward.fee = ''
          }
        },
      },
  })
});

document.addEventListener('turbolinks:visit', () => {
});