import Vue from 'vue/dist/vue.esm'
import _ from 'lodash'

import TurbolinksAdapter from 'vue-turbolinks';
import axios from "axios";
import qs from "qs";

Vue.use(TurbolinksAdapter);

document.addEventListener('turbolinks:load', () => {
  const selector = '.admin-orders-recommend-form-vue';
  const elements = document.querySelectorAll(selector);
  if (elements.length === 0) {
    return
  }

  new Vue({
    el: selector,

    data: function(){
      return {
        csrfToken: '',
        axiosInstance: null,

        order: {},

        selectedCar: {
          id: null
        },

        carSearch: {
          loading: false,
          loadError: '',
          modalInstance: null,
          carModels: [],
          years: [],
          cars: [],
          q: {
            'maker_id_eq': '',
            'car_model_id_eq': '',
            'registration_gteq': '',
            'registration_lteq': '',
          },
          page: {
            'current_page': 1,
            'is_first_page': true,
            'is_last_page': false,
            'prev_page': null,
            'next_page': null,
            'total_pages': 0,
            'limit_value': 0,
            'total_count': 0,
            'count': 0
          }
        },

        text: '',
        messageTemplate: null,
        messageTemplates: [],
      }
    },

    mounted() {
      this.csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

      const container = this.$el;//<div class="admin-orders-recommend-form-vue">
      this.order = JSON.parse(container.getAttribute('data-order'));

      //https://axios-http.com/docs/config_defaults#custom-instance-defaults
      this.axiosInstance = axios.create({
        headers:{
          "X-Requested-With": "XMLHttpRequest",
          "X-CSRF-Token": this.csrfToken
        }
      })
      const date = new Date();
      this.carSearch.years = _.range(1980, date.getFullYear()+1).reverse();
      this.loadMessageTemplates();
      this.searchCars()
      //this.showCarSearchModal();
    },
    beforeDestroy(){//インスタンスが破棄される前に
      if (this.carSearch.modalInstance){
        $('.modal-backdrop').remove();//モーダルを表示したままブラウザの戻るボタンや進むボタンを押した際に操作できなくなる問題対応
        this.carSearch.modalInstance.modal('dispose');
      }
      this.axiosInstance = null;
    },
    filters: {
      year: function (dateString) {
        if (!dateString) {
          return dateString
        }
        const yearMonthDate = dateString.registration.split('-')
        return yearMonthDate[0]
      },
      unit: function(data, unit){
        if (!data) {
          return data;
        }
        return `${data}${unit}`;
      },
      numberFormat: function (number) {
        if (!number) {
          return
        }
        return Intl.NumberFormat().format(Number(number))
      }
    },
    computed: {
      yearFrom: {
        set: function(year){
          if (year) {
            this.carSearch.q['registration_gteq'] = `${year}-01-01`;
          } else {
            this.carSearch.q['registration_gteq'] = '';
          }
        },
        get: function(){
          return String(this.carSearch.q['registration_gteq']).split('-')[0]
        }
      },
      yearTo: {
        set: function(year){
          if (year) {
            this.carSearch.q['registration_lteq'] = `${year}-12-31`;
          } else {
            this.carSearch.q['registration_lteq'] = '';
          }
        },
        get: function(){
          return String(this.carSearch.q['registration_lteq']).split('-')[0]
        }
      }
    },
    methods: {
      onChangedMaker() {
        this.setModelOptions();
      },
      async setModelOptions (){
        this.carSearch.q['car_model_id_eq'] = ''
        this.carSearch.carModels = []
        this.carSearch.loadError = ''
        try {
          const result = await axios.get(`/admin/cars/${this.carSearch.q['maker_id_eq']}/model`, {
            params:{maker_id: this.carSearch.q['maker_id_eq']}
          });
          this.carSearch.carModels = result.data;
        }catch(e){
          console.log(e)
          this.carSearch.loadError= e;
        }
      },      
      async searchCars(page) {
        this.carSearch.loading = true;
        this.carSearch.loadError= ''
        this.carSearch.cars = [];
        try{
          const result = await this.axiosInstance.get(`/admin/orders/${this.order['id']}/search_recommend_cars.json`,{
            params: {
              q: this.carSearch.q,
              page: page
            },
            paramsSerializer: function(params) {
              return qs.stringify(params, {arrayFormat: 'brackets'})
            }
          });
          this.carSearch.cars = result.data.cars;
          this.carSearch.page = result.data.page;
        }catch (e){
          console.log(e)
          this.carSearch.loadError= e;
        }
        this.carSearch.loading = false;
      },
      onClickSelectCar (car){
        this.selectCar(car);
        this.hideCarSearchModal();
      },
      selectCar: function(car) {
        this.selectedCar = car;
      },
      deselectCar: function() {
        this.selectedCar = {id: null};
      },
      showCarSearchModal() {
        this.carSearch.modalInstance = $(this.$refs.carSearchModal).modal({keyboard: false, show:true});
      },
      hideCarSearchModal() {
        if (this.carSearch.modalInstance){
          this.carSearch.modalInstance.modal('hide');
        }
      },
      onTemplateChanged: function () {
        if (!this.messageTemplate) {
          return;
        }

        this.text = this.messageTemplate.message;
      },
      async loadMessageTemplates(){
        return new Promise(async (resolve, reject)=>{
          try{
            const response = await axios.get(`/admin/message_templates.json`);
            resolve(this.messageTemplates = response.data);
          }catch (error) {
            reject(error)
          }
        })
      },
    }
  })
})