import Vue from 'vue/dist/vue.esm'
import axios from 'axios'
import { DateTime } from "luxon";
import _ from 'lodash'

import TurbolinksAdapter from 'vue-turbolinks';
Vue.use(TurbolinksAdapter);

import VueCookies from 'vue-cookies';
Vue.use(VueCookies);

document.addEventListener('turbolinks:load', () => {
    const selector = '.auction-list-vue';
    const elements = document.querySelectorAll(selector);
    if (elements.length === 0) {
        return
    }

    new Vue({
        el: selector,
        data: {
            csrfToken: '',
            axiosInstance: null,
            exhibits: [],
            exhibit_favorites: [],
            bids: [],
            bulkFavoriteEnabled: true,
            bulkBids: [],
            bulkExhibits: [],
            bulkFavorite: {
                operation: 'add',
                group: 'A'
            },

            bidModal: null,
            exhibitIndex: 0,
            exhibitImageLoading: false,
            exhibitImageUrls: [],
            exhibitImageIndex: 0,
            showRawImage: true,

            price: 0,
            incoterms: 'FOB',
            countryId: '',
            portId: '',
            note: '',

            loading: false,
            sendErrors: {},

            estimateInfo: {
                'dimensions': {},
                'heights': {},
                'countries': [],
                'profit': 0,
                'service_fee_min': 0,
                'inspection_fee': 0,
                'indirect_cost': 0,
                'dollar_yen_rate': 1
            },
            ports: [],
            isFavoriteLotsPage: false,
            freight: null,
        },
        created() {
            this.csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            this.exhibits = JSON.parse(document.querySelector(selector).getAttribute('data-exhibits'));
            this.exhibit_favorites = JSON.parse(document.querySelector(selector).getAttribute('data-exhibit-favorites'));
            this.bids = JSON.parse(document.querySelector(selector).getAttribute('data-bids'));
            this.countryId = document.querySelector(selector).getAttribute('data-default-country-id');
            this.portId = document.querySelector(selector).getAttribute('data-default-port-id');
            this.estimateInfo = JSON.parse(document.querySelector(selector).getAttribute('data-estimate-info'));

            const controller = document.querySelector(selector).getAttribute('data-controller');
            this.isFavoriteLotsPage = (new URL(document.location)).searchParams.get('only_favorites');
            this.bulkFavoriteEnabled = (controller === 'auction/bids' || this.isFavoriteLotsPage)

            this.$cookies.set('auction_country_id', this.countryId);
            this.$cookies.set('auction_port_id', this.portId);

            //https://axios-http.com/docs/config_defaults#custom-instance-defaults
            this.axiosInstance = axios.create({
                headers:{
                    "X-Requested-With": "XMLHttpRequest",
                    "X-CSRF-Token": this.csrfToken
                }
            })
        },
        mounted() {//インスタンスがマウントされた後に
            //this.showBidModal(0);//モーダル実装処理用
            this.setupBidModal();
        },
        beforeDestroy(){//インスタンスが破棄される前に
            if (this.bidModal){
                $('.modal-backdrop').remove();//モーダルを表示したままブラウザの戻るボタンや進むボタンを押した際に操作できなくなる問題対応
                this.bidModal.modal('dispose');
            }
            this.axiosInstance = null;
        },
        filters: {
            numberFormat: function(number){
                if (!number) {
                    return
                }
                return Intl.NumberFormat().format(Number(number))
            }
        },
        computed: {
            errors: function(){
                return {};
            },
            hasErrors: function(){
                return Object.keys(this.errors).length > 0;
            },
            sendErrorMessages: function (){
                const errorMessages = [];
                for(const fieldName of Object.keys(this.sendErrors)){
                    const fieldMessages = this.sendErrors[fieldName]
                    errorMessages.push(`${fieldName} ${fieldMessages}`)
                }
                return errorMessages
            },
            exhibit: function(){
                return this.exhibits[this.exhibitIndex]
            },
            exhibit_favorite_groups: function(){
                return this.exhibit_favorites.filter(fav => fav.exhibit_id === this.exhibit.exhibit_id).map(fav => fav['group'])
            },
            bid: function(){
                return this.findBid(this.exhibit)
            },
            largeImageUrl: function(){
                if (this.exhibitImageUrls.length == 0){
                    return '/assets/default.jpg';
                }

                if (this.showRawImage){
                    return this.exhibitImageUrls[this.exhibitImageIndex];
                }

                if (this.exhibitImageUrls[this.exhibitImageIndex]){
                    return `${this.exhibitImageUrls[this.exhibitImageIndex]}&w=320`;
                } else {
                    return false
                }

            },
            disabled: function(){
                return this.loading || this.hasErrors;
            },
            serviceFee() {
                let serviceFee = this.price * this.estimateInfo['profit']
                if(serviceFee < this.estimateInfo['service_fee_min']) {
                    serviceFee = this.estimateInfo['service_fee_min']
                }
                return serviceFee;
            },
            inspectionRequired() {
                const country = this.estimateInfo['countries'][this.countryId]
                if (country && country.inspection){
                    return true;
                }
                return false;
            },
            estimateJPY: function (){
                if (this.price == 0){
                    return null;
                }

                if (!this.exhibit){
                    return null;
                }

                if (this.incoterms == 'C&F' && !this.freight){
                    // Cost and Freight が選ばれている場合にfreightが計算できなければ算出不可能
                    return null
                }

                let estimatedPrice = this.price + this.estimateInfo['indirect_cost'] + this.serviceFee;

                if (this.inspectionRequired){
                    estimatedPrice += this.estimateInfo['inspection_fee']
                }

                if (this.incoterms == 'C&F' && this.freight){
                    estimatedPrice += this.freight
                }
                return Math.ceil(estimatedPrice);//小数点切り上げ
            },
            estimateUSD: function (){
                return Math.round(this.estimateJPY / this.estimateInfo['dollar_yen_rate'])
            },
            askForAnEstimate: function (){
                if (this.incoterms == 'C&F' && this.portId && !this.freight){
                    // Cost and Freight かつ portId の指定がありfreightが計算できなければ ASK
                    return true
                }
                return false
            }
        },
        watch: {
            exhibitIndex: function (){
                this.setupBidModal()
            }
        },
        methods: {
            back() {
                if (this.exhibitIndex === 0){
                    return ;
                }
                this.exhibitIndex--;
            },
            next() {
                if (this.exhibitIndex === this.exhibits.length-1){
                    return ;
                }
                this.exhibitIndex++;
            },
            setupBidModal() {
                this.sendErrors = {}

                if (this.bid) {
                    this.price = this.bid['price'];
                    this.incoterms = this.bid['incoterms'];
                    this.countryId = this.bid['country_id'];
                    this.portId = this.bid['port_id'];
                    this.note = this.bid['note'];
                } else {
                    this.price = 0;
                    this.note = '';
                }
                this.loadFullSizeImageUrls();
                this.loadPorts();
                this.loadFreightYen();
            },
            showBidModal(index) {
                this.exhibitIndex = index;
                this.bidModal = $(this.$refs.bidModal).modal({keyboard: false, show:true});//<div class="modal fade" ref="bidModal">
            },
            hideBidModal() {
                if (this.bidModal){
                    this.bidModal.modal('hide');
                }
            },
            findBid(exhibit){
                return this.bids.find((bid)=>bid['exhibit_id'] == exhibit['exhibit_id'])
            },
            bidden(exhibit){
                return Boolean(this.findBid(exhibit))
            },
            canBid(exhibit){
                const currentJst = DateTime.now().setZone('Asia/Tokyo')
                const auctionDateJst = DateTime.fromSQL(exhibit['auction_date'], { zone: 'Asia/Tokyo' })//YYYY-mm-ddというSQL形式の日付フォーマットなのでfromSQL()

                // Luxon の set()メソッドは新しいインスタンスを生成する。(currentJst.hourに変化はない) https://moment.github.io/luxon/#/tour?id=set
                const closeTimeJst = currentJst.set({hour: 5, minute: 30, second: 0, millisecond:0})
                if (currentJst <= closeTimeJst) { // https://moment.github.io/luxon/#/math?id=comparing-datetimes
                    //AM5:30までは当日開催分以降の応札であれば変更できる
                    return currentJst.startOf('day') <= auctionDateJst.startOf('day')
                } else {
                    //AM5:30以降は翌日開催以降の応札であれば変更できる
                    return currentJst.plus({days: 1}).startOf('day') <= auctionDateJst.startOf('day')
                }
            },
            isUss(exhibit){
                return String(exhibit['auction']).startsWith('USS')
            },
            isStats(exhibit){
                let auctionDate = new Date(this.exhibit['auction_date']);
                let nowDateTime = new Date();
                let nowDate = new Date(nowDateTime.getFullYear(), nowDateTime.getMonth(), nowDateTime.getDate(), 0, 0, 0);
                return String(nowDate > auctionDate)
            },
            onChangeCountry(){
                this.portId = ''
                this.$cookies.set('auction_country_id', this.countryId);
                this.$cookies.set('auction_port_id', this.portId);
                this.loadPorts()
            },
            onChangePort(){
                this.$cookies.set('auction_country_id', this.countryId);
                this.$cookies.set('auction_port_id', this.portId);
                this.loadFreightYen();
            },
            async loadPorts(){
                this.loading = true;
                this.ports = []
                this.freight = null;
                if (!this.countryId){
                    this.loading = false;
                    return;
                }
                try{
                    const response = await this.axiosInstance.get('/auction/exhibits/ports.json', {
                        params: {
                            'country_id': this.countryId
                        }
                    })
                    this.ports = response.data
                }catch(e){
                    console.error(e)
                }
                this.loading = false;
            },
            async loadFreightYen(){
                this.loading = true;
                this.freight = null;
                if (!this.portId){
                    this.loading = false;
                    return;
                }
                try{
                    const dimension = this.estimateInfo['dimensions'][this.exhibit['exhibit_id']]
                    const height = this.estimateInfo['heights'][this.exhibit['exhibit_id']]
                    const response = await this.axiosInstance.get('/auction/exhibits/freight_yen.json', {
                        params: {
                            'port_id': this.portId,
                            'dimension': dimension,
                            'height': height,
                        }
                    })
                    this.freight = response.data
                }catch(e){
                    console.error(e)
                }
                this.loading = false;
            },
            async loadFullSizeImageUrls(){
                this.exhibitImageLoading = true;
                this.exhibitImageUrls = [];
                if (!this.exhibit){
                    return;
                }
                const exhibitId = this.exhibit['exhibit_id']//画像取得が完了する前にモーダルを切り替えられた場合の不具合に対応
               try{
                    const urls = await this.requestImageUrls(exhibitId, this.isUss(this.exhibit), this.isStats(this.exhibit), 3);

                    if (exhibitId === this.exhibit['exhibit_id']) {//画像取得が完了する前にモーダルを切り替えられた場合の不具合に対応するif文
                        this.exhibitImageUrls = urls
                    }
                }catch(e){
                    console.error(e)
                }
                this.exhibitImageLoading = false;
            },
            async requestImageUrls(exhibitId, isUss, isStats, remainingRetries){
                if (remainingRetries == 0) {
                    //残りリトライ回数が0ならば、取得を諦めて空の配列を返す。
                    return [];
                }
                const response = await this.axiosInstance.get('/auction/exhibits/full_size_image_urls', {
                    params: {
                        'exhibit_id': exhibitId,
                        'is_uss': isUss,
                        'is_stats': isStats
                    }
                })
                const urls = response.data

                if (urls[0].startsWith('_')) {
                    //最初の要素の先頭が_で始まる文字列である場合は、1分後に再読込する必要がある。
                    return await new Promise((resolve,reject)=>{
                        setTimeout(async ()=>{
                            try{
                                const newRemainingRetries = remainingRetries -1;
                                const maybeUrls = await this.requestImageUrls(exhibitId, isUss, isStats, newRemainingRetries);//残り実行回数を一つ減らして再実行
                                resolve(maybeUrls)
                            }catch(e) {
                                reject(e)
                            }
                        }, 60000)
                    })
                } else {
                    return urls;
                }
            },
            async addExhibitFavorite(exhibit){
                this.sendErrors = {}
                this.loading = true;
                try{
                    let result_exhibit_favorites = null;
                    result_exhibit_favorites = await this.createExhibitFavorite(exhibit)
                    this.exhibit_favorites.push(result_exhibit_favorites[0])
                }catch(e){
                    this.sendErrors = e.response.data
                }
                this.loading = false;
            },
            async createExhibitFavorite(exhibit) {
                const result = await this.axiosInstance.post("/auction/exhibits/favorites/add.json", {
                    'favorites': {
                        'exhibit_ids': [exhibit['exhibit_id']],
                        'auction_dates': [exhibit['auction_date']]
                    },
                })
                return result.data;
            },
            async removeExhibitFavorite(exhibit){
                this.sendErrors = {}
                this.loading = true;
                this.removeExhibitFavoriteExecute(exhibit)
                this.loading = false;
            },
            async removeExhibitFavoriteExecute(exhibit){
                try{
                    let result_exhibit_favorites = null;
                    result_exhibit_favorites = await this.destroyExhibitFavorite(exhibit)
                    result_exhibit_favorites.forEach(result_exhibit_favorite=>{
                        this.exhibit_favorites = this.exhibit_favorites.filter((exhibit_favorite)=>exhibit_favorite['exhibit_id'] !== result_exhibit_favorite['exhibit_id']);
                    })
                }catch(e){
                    this.sendErrors = e.response.data
                }
            },
            async destroyExhibitFavorite(exhibit) {
                const result = await this.axiosInstance.post("/auction/exhibits/favorites/remove.json", {
                    'favorites': {
                        'exhibit_ids': [exhibit['exhibit_id']]
                    },
                })
                return result.data;
            },
            async doBid(){
                this.sendErrors = {}
                this.loading = true;
                try{
                    let bid = null;
                    if (!this.bidden(this.exhibit)){
                        //新規
                        bid = await this.createBid()
                        this.bids.push(bid)
                        await this.removeExhibitFavoriteExecute(this.exhibit)
                        //Favorite LotsページからBIDした場合は、表示リストから除外する
                        if(this.isFavoriteLotsPage){
                            const index = this.exhibits.findIndex((exhibit)=>exhibit['exhibit_id'] == this.exhibit['exhibit_id']);
                            this.exhibits.splice(index, 1)
                        }
                    } else {
                        if (this.canBid(this.exhibit)) {
                            //更新
                            bid = await this.updateBid()
                        } else {
                            bid = await this.updateBidNote()
                        }
                        const index = this.bids.findIndex((bid)=>bid['exhibit_id'] == this.exhibit['exhibit_id']);
                        this.bids[index] = bid;
                    }

                    this.hideBidModal()
                }catch(e){
                    this.sendErrors = e.response.data
                }
                this.loading = false;
            },
            async createBid() {
                const result = await this.axiosInstance.post("/auction/bids.json", {
                    'bid': {
                        'price': this['price'],
                        'exhibit_id': this.exhibit['exhibit_id'],
                        'incoterms': this['incoterms'],
                        'country_id': this.countryId,
                        'port_id': this.portId,
                        'note': this.note,
                        'estimated_price': this.estimateUSD,
                    },
                    'favorites':{
                        'groups': this.exhibit_favorite_groups
                    },
                })
                return result.data;
            },
            async updateBid() {
                const result = await this.axiosInstance.patch(`/auction/bids/${this.bid['id']}.json`, {
                    'bid': {
                        'price': this['price'],
                        'incoterms': this['incoterms'],
                        'country_id': this.countryId,
                        'port_id': this.portId,
                        'note': this.note,
                        'estimated_price': this.estimateUSD,
                    },
                })
                return result.data;
            },
            async updateBidNote() {
                const result = await this.axiosInstance.patch(`/auction/bids/${this.bid['id']}/update_note.json`, {
                    'bid': {
                        'note': this.note,
                    },
                })
                return result.data;
            },
            async cancelBid(){
                if (!confirm('Are you sure?')){
                    return ;
                }

                this.sendErrors = {}
                this.loading = true;
                try{
                    const id = this.bid.id;
                    this.bids =  this.bids.filter((bid)=>bid['id'] != id);
                    await this.axiosInstance.delete(`/auction/bids/${id}.json`)
                    this.hideBidModal()
                }catch(e){
                    this.sendErrors = e.response.data
                }
                this.loading = false;
            },
            isFavorite(bid, group){
                return bid.bid_favorites.some(favorite=>favorite.group == group);
            },
            isExhibitFavoriteGroup(exhibit, group){
                return this.exhibit_favorites.some(exhibit_fav=>exhibit_fav.exhibit_id == exhibit.exhibit_id && exhibit_fav.group == group);
            },
            inExhibitFavorite(exhibit){
                return this.exhibit_favorites.some(exhibit_fav=>exhibit_fav.exhibit_id == exhibit.exhibit_id);
            },
            async toggleFavorite(bid, group){
                const addOrRemove = this.isFavorite(bid, group) ? 'remove': 'add';
                const resultBids = await this.sendFavorites([bid.id], group, addOrRemove)
                resultBids.forEach(resultBid=>{
                    const index = this.bids.findIndex((bid)=>bid['id'] == resultBid['id']);
                    this.bids.splice(index, 1, resultBid);
                })
            },
            async toggleExhibitFavoriteGroup(exhibit, group){
                const addOrRemove = this.isExhibitFavoriteGroup(exhibit, group) ? 'remove': 'add';
                const resultExhibitsFavs = await this.sendExhibitFavoriteGroups([exhibit.exhibit_id], group, addOrRemove)
                resultExhibitsFavs.forEach(resultExhibitFav=>{
                    if(addOrRemove==='add'){
                        this.exhibit_favorites.push(resultExhibitFav);
                    }else{
                        const index = this.exhibit_favorites.findIndex((exhibit_fav)=>exhibit_fav['exhibit_id'] == resultExhibitFav['exhibit_id'] && exhibit_fav['group'] == group);
                        this.exhibit_favorites.splice(index, 1)
                    }
                })
            },
            onClickCheckAll(){
                if(this.isFavoriteLotsPage){
                    this.bulkExhibits = this.exhibits;
                }else{
                    this.bulkBids = this.bids;
                }
            },
            onClickUncheckAll(){
                if(this.isFavoriteLotsPage){
                    this.bulkExhibits = [];
                }else{
                    this.bulkBids = [];
                }
            },
            async onClickBulkFavorite(){
                if(this.isFavoriteLotsPage){
                    const exhibit_ids = this.bulkExhibits.map(exhibit=>exhibit.exhibit_id);
                    const resultExhibitFavs = await this.sendExhibitFavoriteGroups(exhibit_ids, this.bulkFavorite.group, this.bulkFavorite.operation);
                    resultExhibitFavs.forEach(resultExhibitFav=>{
                        if(this.bulkFavorite.operation==='add'){
                            this.exhibit_favorites.push(resultExhibitFav);
                        }else{
                            const index = this.exhibit_favorites.findIndex((exhibit_fav)=>exhibit_fav['exhibit_id'] == resultExhibitFav['exhibit_id'] && exhibit_fav['group'] == this.bulkFavorite.group);
                            this.exhibit_favorites.splice(index, 1)
                        }
                    })
                }else{
                    const ids = this.bulkBids.map(bid=>bid.id);
                    const resultBids = await this.sendFavorites(ids, this.bulkFavorite.group, this.bulkFavorite.operation);
                    resultBids.forEach(resultBid=>{
                        const index = this.bids.findIndex((bid)=>bid['id'] == resultBid['id']);
                        this.bids.splice(index, 1, resultBid);
                    })
                }
            },
            async sendFavorites(bidIds, group, addOrRemove){
                try{
                    const result = await this.axiosInstance.post(`/auction/bids/favorites/${addOrRemove}.json`, {
                        'favorites':{
                            'bid_ids': bidIds,
                            'group': group
                        }
                    })
                    const bids = result.data;
                    return bids;
                }catch(e){
                    console.log(e)
                }
            },
            async sendExhibitFavoriteGroups(exhibitIds, group, addOrRemove){
                try{
                    const result = await this.axiosInstance.post(`/auction/exhibits/favorites/group/${addOrRemove}.json`, {
                        'favorites':{
                            'exhibit_ids': exhibitIds,
                            'group': group
                        }
                    })
                    const exhibits = result.data;
                    return exhibits;
                }catch(e){
                    console.log(e)
                }
            }
        }
    })
})