import consumer from "../../channels/consumer"

// 参考
// 6.1 例1: ユーザーアピアランスの表示
// https://railsguides.jp/action_cable_overview.html#%E4%BE%8B1-%E3%83%A6%E3%83%BC%E3%82%B6%E3%83%BC%E3%82%A2%E3%83%94%E3%82%A2%E3%83%A9%E3%83%B3%E3%82%B9%E3%81%AE%E8%A1%A8%E7%A4%BA
consumer.subscriptions.create("AppearanceChannel", {
  // サブスクリプションが作成されると1度呼び出される
  initialized() {
    // console.log('initialized')
    this.update = this.update.bind(this)
  },
  // サブスクリプションがサーバーで利用可能になると呼び出される
  connected() {
    // console.log('connected')
    this.install()
    this.update()
  },
  // WebSocketコネクションがクローズすると呼び出される
  disconnected() {
    // console.log('disconnected')
    this.uninstall()
  },
  // サブスクリプションがサーバーで却下されると呼び出される
  rejected() {
    // console.log('rejected')
    this.uninstall()
  },
  update(e) {
    // console.log({event:e?.type, isActive:this.documentIsActive(), direct: document.visibilityState == "visible" && document.hasFocus(), visibilityState:document.visibilityState, hasFocus:document.hasFocus()})
    this.documentIsActive() ? this.appear() : this.away()
  },
  appear() {
    // サーバーの`AppearanceChannel#appear(data)`を呼び出す
    this.perform("appear", { appearing_on: this.appearingOn() })
  },
  away() {
    // サーバーの`AppearanceChannel#away`を呼び出す
    this.perform("away")
  },
  install() {
    // console.log('install')
    window.addEventListener("focus", this.update)
    window.addEventListener("blur", this.update)
    document.addEventListener("turbolinks:load", this.update)
    document.addEventListener("visibilitychange", this.update)
  },
  uninstall() {
    // console.log('uninstall')
    window.removeEventListener("focus", this.update)
    window.removeEventListener("blur", this.update)
    document.removeEventListener("turbolinks:load", this.update)
    document.removeEventListener("visibilitychange", this.update)
  },
  documentIsActive() {
    return document.visibilityState == "visible" && document.hasFocus()
  },
  appearingOn() {
    const element = document.querySelector("[data-appearing-on]")
    return element ? element.getAttribute("data-appearing-on") : null
  }
})
