import Vue from 'vue/dist/vue.esm'
import axios from 'axios';
import qs from 'qs';

import TurbolinksAdapter from 'vue-turbolinks';
Vue.use(TurbolinksAdapter);

import consumer from "../channels/consumer"

let $notification = null;

let subscription = null;
document.addEventListener('turbolinks:load', () => {
    const selector = '.admin-request_payment_confirmations-index';
    const elements = document.querySelectorAll(selector);
    if (elements.length === 0) {
        return
    }
    
    new Vue({
      el: ".admin-request_payment_confirmations-index",
      data: function (){
        return {
          userSearch: {
            modalInstance: null,
            users: [],
            q: {},
            purpose:''//利用目的（search or carried_forward）
          },
          useCarriedForward: {
            modalInstance: null
            //user_id:null,
          },
          userCarriedForwardBalance:{
            user_id:null,
            name:"",
            dollar_balance:0,
            yen_balance:0,
            not_find:null
          }
        }
      },
      beforeDestroy(){//インスタンスが破棄される前に
        if (this.userSearch.modalInstance){
          $('.modal-backdrop').remove();//モーダルを表示したままブラウザの戻るボタンや進むボタンを押した際に操作できなくなる問題対応
          this.userSearch.modalInstance.modal('dispose');
        }
      },
      methods: {
        showUserSearchModal(purpose) {
          this.userSearch.modalInstance = $(this.$refs.userSearchModal).modal({keyboard: false, show:true});
          const $modalBody = $('.modal-body', this.$refs.userSearchModal)
          $modalBody.height($modalBody.height() + 1)
          this.userSearch.purpose = purpose //検索フォームの利用用途（search／carried_forward）
        },
        hideUserSearchModal() {
          if (this.userSearch.modalInstance){
            this.userSearch.modalInstance.modal('hide');
          }
        },
        onClickChooseUser: function(userId){
          document.querySelector('#user_id').value = userId;
          this.hideUserSearchModal();
        },
        onClickUserSearch: async function (){
          const result = await axios.get(
              '/admin/api/search_user2.json',
              {
                params: {
                  q: this.userSearch.q
                },
                paramsSerializer: function(params) {
                  return qs.stringify(params, {arrayFormat: 'brackets'})
                }
              }
          )
          this.userSearch.users = result.data;
        },

        //繰越金のユーザーを選ぶ
        onClickChooseUserCarriedForward: function(userId){
          // ユーザー検索のモーダルを閉じる
          if (this.userSearch.modalInstance){
            this.userSearch.modalInstance.modal('hide');
          }
          //繰越金残高を調べるモーダルを開く
          this.useCarriedForward.modalInstance = $(this.$refs.useCarriedForwardModal).modal({keyboard: false, show:true});
          const $modalBody = $('.modal-body', this.$refs.useCarriedForwardModal)
          $modalBody.height($modalBody.height() + 1)
          // 残高取得
          this.getCarriedForwardBalance(userId);
        },
        hideCarriedForwardModal() {
          if (this.useCarriedForward.modalInstance){
            this.useCarriedForward.modalInstance.modal('hide');
          }
        },
        //ユーザーの繰越金残高を取得する
        getCarriedForwardBalance: async function (userId){
          if(userId != ""){
            try{
              const result = await axios.get(`/admin/request_payment_confirmations/api/${userId}/carried_forward_user_balance`)
              this.userCarriedForwardBalance.user_id = result.data.user_id
              this.userCarriedForwardBalance.name = result.data.name
              this.userCarriedForwardBalance.dollar_balance = result.data.dollar_balance
              this.userCarriedForwardBalance.yen_balance = result.data.yen_balance
              this.userCarriedForwardBalance.not_find = result.data.not_find
            }catch (e){
              console.log(e)
            }
          }
        },
      },
  })
});

document.addEventListener('turbolinks:visit', () => {
});