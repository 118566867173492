import Vue from 'vue/dist/vue.esm'
import TurbolinksAdapter from 'vue-turbolinks';
import { debounce } from 'lodash';

Vue.use(TurbolinksAdapter);

document.addEventListener('turbolinks:load', () => {
  const element = document.querySelector("#top_page_banner_form");
  if (!element) {
    return
  }

  const app = new Vue({
    el: "#top_page_banner_form",

    // Data option
    data: {
      message: "",
      displayFlag: false,
      initialDisplayFlag: false,
      todayString: new Date().toISOString().slice(0, 10), // "YYYY-MM-DD"
    },
    computed: {
    },

    watch: {
    },

    methods: {
      setInitialDisplayFlag: function() {
        this.initialDisplayFlag = this.$el.querySelector('#display_flag').checked;
        this.displayFlag = this.initialDisplayFlag;
      },

      checkDisplayPeriod: function () {
        this.displayFlag = this.$el.querySelector('#display_flag').checked;

        let dateMissing = false;
        let messageContent = "";

        // "YYYY-MM-DD"形式stringの日付をDate型に変換
        let startDate = new Date(this.$el.querySelector('#display_period_start_date').value);
        let endDate = new Date(this.$el.querySelector('#display_period_end_date').value);
        let today = new Date(this.todayString);

        // 開始日と終了日のどちらかが入力されていない場合、メッセージを設定
        if ((isNaN(startDate.getTime()) && !isNaN(endDate.getTime()))
          || (!isNaN(startDate.getTime()) && isNaN(endDate.getTime()))) {
          dateMissing = true;
        }

        if (dateMissing) {
          messageContent = "NOTICE: 開始日/終了日を確認して下さい";
          this.message = messageContent;
          return;
        }

        // 開始日 > 終了日の場合
        if (startDate > endDate) {
          messageContent = "NOTICE: 開始日が終了日より後になっています。日付を初期化します。";

          this.$el.querySelector('#display_period_start_date').value = "";
          this.$el.querySelector('#display_period_end_date').value = "";
          this.message = messageContent;
          return;
        }

        // 公開を選択、かつ開始日が今日以降の場合
        if (this.displayFlag && startDate > today) {
          messageContent = "NOTICE: 公開を希望される場合は、開始日を今日またはそれ以前に設定してください。開始日を今日に設定します。";

          // if (this.initialDisplayFlag === false) {
          //   // 初期表示が非公開→公開に変更した場合
          //   messageContent = messageContent.concat("公開します。よろしいですか？");
          // }

          // 開始日を今日に設定
          this.$el.querySelector('#display_period_start_date').value = this.todayString;
          this.message = messageContent;
          return;
        }

        // 本日が掲載期間中で、公開フラグをtrueに変更した場合
        if (this.displayFlag === true
          && (startDate <= today && today <= endDate)) {
          messageContent = "NOTICE: 公開します。よろしいですか？";

          this.message = messageContent;
          return;
        }

        // 本日が掲載期間中で、公開フラグをfalseに変更した場合
        if (this.displayFlag === false
          && (startDate <= today && today <= endDate)) {
          messageContent = "NOTICE: 非公開します。よろしいですか？";

          this.message = messageContent;
          return;
        }

        // 本日が終了日以後で、公開フラグがtrueの場合
        if (this.displayFlag === true && (today > endDate)) {
          messageContent = "NOTICE: 掲載終了日が過ぎています。公開のために、日付を初期化します。";

          // if (this.initialDisplayFlag === false) {
          //   messageContent = messageContent.concat("公開します。よろしいですか？");
          // }

          this.$el.querySelector('#display_period_start_date').value = "";
          this.$el.querySelector('#display_period_end_date').value = "";
          this.message = messageContent;
          return;
        }

        this.message = "";
      },
    },

    // Lifecycle hooks
    beforeCreate: function() {
    },
    created: function() {
    },
    beforeMount: function() {
    },
    mounted: function() {
      this.setInitialDisplayFlag();
      this.checkDisplayPeriod = debounce(this.checkDisplayPeriod, 100);
    },
    beforeUpdate: function() {
    },
    updated: function() {
    },
    beforeDestroy: function() {
    },
    destroyed: function() {
    }
  });
});
