import Vue from 'vue/dist/vue.esm'

import TurbolinksAdapter from 'vue-turbolinks';
Vue.use(TurbolinksAdapter);

document.addEventListener('turbolinks:load', () => {
  const element = document.getElementById('admin-port-form-vue');
  if (element === null) {
    return
  }

  new Vue({
    el: '#admin-port-form-vue',
    data: function (){
      return {
        countries: [],
        selectedUsableCountryId: '',
        selectedUsableCountriesId: [],
        selectedUsableCountriesName: [],
      }
    },
    created() {
      // 操作画面上の国名表示用
      this.countries = JSON.parse(document.getElementById('admin-port-form-vue').getAttribute('data-countries'))
      // Port編集時のUsableCountries初期値表示用
      const usableCountries = JSON.parse(document.getElementById('admin-port-form-vue').getAttribute('data-usable-countries'))
      this.selectedUsableCountriesId = Object.keys(usableCountries)
      this.selectedUsableCountriesName = Object.values(usableCountries)
    },
    methods: {
      addUsableCounty: function(){
        if(this.selectedUsableCountryId){
          this.selectedUsableCountriesId.push(this.selectedUsableCountryId)
          this.selectedUsableCountriesName.push(this.countries[this.selectedUsableCountryId])
          
          // 重複削除
          this.selectedUsableCountriesId = Array.from(new Set(this.selectedUsableCountriesId))
          this.selectedUsableCountriesName = Array.from(new Set(this.selectedUsableCountriesName))
        }
      },
      deleteAllUsableCountries: function(){
        this.selectedUsableCountriesId = []
        this.selectedUsableCountriesName = []
      },
    },
  })
});