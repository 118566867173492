import Vue from 'vue/dist/vue.esm'
import axios from 'axios';

import TurbolinksAdapter from 'vue-turbolinks';
Vue.use(TurbolinksAdapter);

document.addEventListener('turbolinks:load', () => {
    const selector = '.admin-payment-management-file-keeping';
    const elements = document.querySelectorAll(selector);
    if (elements.length === 0) {
        return
    }

    const paymentModalBodyVue = new Vue({
      el: ".admin-payment-management-file-keeping",
      data: function (){
        return {
        csrfToken: '',
        loading: false,
        hasError: false,
        paymentId:null,
        paymentData:{},//親情報
        suppliers:{},
        updateSupplierSuccess:false,
        updateSupplierErr:"",
        documentFiles:{},//保存済みのデータ
        selectedFile:{},//選択しているファイル
        createData:{},//今から保存するデータ
        editData:{},//編集を行うデータ
        createErr:{
          memo:"",
          file:"",
          save_error:"",
        },
        editErr:{
          memo:"",
          save_error:"",
        },
        createProcessing:false,
        updateProcessing:false,
        editMode:null,
        }
      },

      mounted: function(){
        this.csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        const container = this.$el;
        this.suppliers = JSON.parse(container.getAttribute('data-suppliers'));
      },
      methods: {
        //モーダルを開く
        showBody: function (paymentId) {
          this.loading = true;
          this.hasError = false;
          this.paymentId = paymentId;
          this.getDocumentFiles(paymentId);

          //モーダル外の一覧から親情報を取得
          this.paymentData.supplier_id = document.getElementById(`supplier_id_${this.paymentId}`).innerHTML
          this.paymentData.detail = document.getElementById(`detail_${this.paymentId}`).innerHTML
          if(document.getElementById(`transaction_code_${this.paymentId}`)){
            this.paymentData.transaction_code = document.getElementById(`transaction_code_${this.paymentId}`).innerHTML
          }

        },
        //モーダルを閉じる
        hideBody: function () {
          this.loading = true;
          this.hasError = false;
          this.documentFiles = {};
          this.createData ={};
          this.paymentId = null;
          this.paymentData = {};
          this.selectedFile ={};
          this.createErr.save_error = '';
          this.createErr.file = '';
          this.createErr.memo = '';
          this.editErr.save_error = '';
          this.editErr.memo = '';
          this.editMode = null;
          this.updateSupplierErr = "";
        },

        // ファイル一覧情報を取得
        getDocumentFiles(paymentId) {
          return new Promise(async (resolve, reject) => {
            try{
              const values = await axios.get(`/admin/payment_managements/${paymentId}/document_files.json`)
              this.documentFiles = values.data;
              resolve(this.documentFiles);
            }catch (error) {
              this.hasError = true;
              reject(error)
            }
            this.loading = false;
          });
        },

        //ファイルを選択した時の処理
        selectFile(event) {
          this.createErr.file = ""
          this.selectedFile = event.target.files[0];
          let size = this.selectedFile.size,
          type = this.selectedFile.type;

        // 5MBまで
        if (size > 5000000) {
          this.createErr.file = "ファイルサイズは5MBまで";
          }
          if (type != 'image/jpg' && type != 'image/jpeg' && type != 'image/png' && type != 'application/pdf') {
          this.createErr.file = "保存可能なファイルの種類[jpg,jpeg,ping,pdf]";
          }
        },

        inputMemo(type) {
          const limit= 100
          if(type == 'create'){
            this.createErr.memo = ""
            if (this.createData.memo.length > limit){
              this.createErr.memo = `${limit}文字以内で入力してください`;
            }
          }
          if(type == 'edit'){
            this.editErr.memo = ""
            if (this.editData.memo.length > limit){
              this.editErr.memo = `${limit}文字以内で入力してください`;
            }
          }
        },

        //データの新規登録
        createDocumentFiles: async function (){
          if(!this.createErr.memo && !this.createErr.file){

            this.createProcessing = true
     
            // Formデータ作成
            const formData = new FormData();
            formData.append('document_file', this.selectedFile);
            if (this.createData['memo']){
              formData.append('memo', this.createData['memo']);
            }
            formData.append('payment_management_id', this.paymentId);

            return new Promise(async (resolve, reject) => {
              try{
                const result = await axios.post(
                  `/admin/payment_managements/document_files/create`,
                  formData,
                  {
                    headers:{
                      "X-Requested-With": "XMLHttpRequest",
                      "X-CSRF-Token": this.csrfToken
                    }
                  }
                )
                if (result.data.success ==true){
                  // エラーメッセージを削除
                  this.createErr.save_error = ""
                  // 入力した情報をクリア
                  this.createData = {};
                  this.selectedFile ={};
                  //参照中のファイル情報を削除
                  this.$refs.file.value = null;
                  // 一覧情報を取得し直す
                  this.getDocumentFiles(this.paymentId);
                  this.createProcessing = false
                  //モーダル外の一覧のファイル件数を加算
                  var count_area = document.getElementById(`file_count_${this.paymentId}`)
                  document.getElementById(`file_count_${this.paymentId}`).innerHTML = Number(count_area.innerHTML) + 1
                  count_area.style.display = "inline";

                }else{
                  this.createErr.save_error = result.data.error
                  this.createProcessing = false
                }
              
                resolve();
              }catch (error) {
                reject(error)
                this.createProcessing = false
              }
            })
          }
        },

        openForm(id) {
          // openFormにidを設定しフォームを開く
          this.editMode = id

          // エラーメッセージを削除
          this.editErr.memo = ""
          this.editErr.save_error = ""

          //対象のフォームを探す
          var document_file = this.documentFiles.find(e => e.id === id);

          //保存済みの値をフォームに設定
          this.editData.memo = document_file.memo
          this.editData.validity = document_file.validity
        },

        closeForm(id) {
          // idを除去しフォームを閉じる
          this.editMode = null
          // エラーメッセージを削除
          this.editErr.memo = ""
          this.editErr.save_error = ""
          // 入力した情報をクリア
          this.editData = {};
        },
        
        updateDocumentFile: async function (id){
          if(!this.editErr.memo){
            this.updateProcessing = true

            //更新対象の行を探す
            let document_file = this.documentFiles.find(e => e.id === id);

            //有効無効を変更した場合モーダル外のファイルの数を加減する
            let change_count = null
            if (this.editData.validity != document_file.validity){
              if (this.editData.validity == false){
                change_count = -1 //無効にしたのでマイナス1
              }
              if (this.editData.validity == true){
                change_count = 1 //有効にしたのでプラス1
              }
            }
    
            return new Promise(async (resolve, reject) => {
              try{
                const result = await axios.post(
                  `/admin/payment_managements/document_files/update`,
                  {
                    id:id,
                    memo:this.editData.memo,
                    validity:this.editData.validity
                  },
                  {
                    headers:{
                      "X-Requested-With": "XMLHttpRequest",
                      "X-CSRF-Token": this.csrfToken
                    }
                  }
                )
                if (result.data.success ==true){
                  //画面描画用に対象データを書き換える
                  //var document_file = this.documentFiles.find(e => e.id === id);
                  document_file.memo = this.editData.memo
                  document_file.validity = this.editData.validity

                  //モーダル外の一覧のファイル件数を加算／減算
                  if (change_count ){
                    var count_area = document.getElementById(`file_count_${this.paymentId}`)
                    var file_count = Number(count_area.innerHTML) + change_count
                    document.getElementById(`file_count_${this.paymentId}`).innerHTML = file_count
                    //加減の結果有効なファイル数が0になった場合、カウント数を非表示にする
                    if (file_count < 1){
                      count_area.style.display = "none";
                    }else{
                      count_area.style.display = "inline";
                    }
                  }

                  //フォームを閉じる
                  this. closeForm(id)
                  this.updateProcessing = false

                }else{
                  this.editErr.save_error = result.data.error
                  this.updateProcessing = false
                }
              
                resolve();
              }catch (error) {
                reject(error)
                this.updateProcessing = false
              }
            })
          }
        },

        downloadDocumentFile(filename, id) {
          // 画像ファイルのURL
          const imageUrl =`../../admin/payment_managements/document_files/${id}/document_file`;

          // ダウンロードリンクを使ってダウンロードを実行
          const a = document.createElement('a');
          a.href = imageUrl;
          a.download = filename;
          a.click();
        },

        updateSupplier: async function (){
            return new Promise(async (resolve, reject) => {
              try{
                const result = await axios.post(
                  `/admin/payment_managements/supplier_update`,
                  {
                    id:this.paymentId,
                    supplier_id:this.paymentData.supplier_id,
                  },
                  {
                    headers:{
                      "X-Requested-With": "XMLHttpRequest",
                      "X-CSRF-Token": this.csrfToken
                    }
                  }
                )
                if (result.data.success ==true){
                  //エラーメッセージを削除
                  this.updateSupplierErr = ""

                  //モーダル外の一覧の取引先を書き換える
                   document.getElementById(`supplier_id_${this.paymentId}`).innerHTML = this.paymentData.supplier_id
                   document.getElementById(`supplier_title_${this.paymentId}`).innerHTML = result.data.supplier_title

                   //success
                   this.updateSupplierSuccess = true
                   setTimeout(() => {
                       this.updateSupplierSuccess = false;
                   }, 2);
                }else{
                  //エラーメッセージ
                  this.updateSupplierErr = result.data.error
                }
                resolve();
              }catch (error) {
                reject(error)
              }
            })
          //}
        },


      },
    });
    
  //_order_task_check.html.slimに、 data-target="#payment_detail_modal" 属性を持つボタンがあります。
  $('#document_files_modal').on('show.bs.modal', function (event) {
    const button = $(event.relatedTarget);
    paymentModalBodyVue.showBody(button.data('id'));
  })
  $('#document_files_modal').on('hide.bs.modal', function (event) {
    paymentModalBodyVue.hideBody();
  })

});



