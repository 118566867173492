import Vue from 'vue/dist/vue.esm'
import axios from 'axios';
import qs from 'qs';

import TurbolinksAdapter from 'vue-turbolinks';
Vue.use(TurbolinksAdapter);

document.addEventListener('turbolinks:load', () => {
    const selector = '.transaction_item_service-edit';
    const elements = document.querySelectorAll(selector);
    if (elements.length === 0) {
        return
    }

    new Vue({
      el: ".transaction_item_service-edit",
      data: function (){
        return {
          csrfToken: '',
          //車両呼び出し関連
          callVinSuccess:null,
          callVinProcess:false,
          callVin:null,
          callAuctionAddCount:null,
          callAuctionProcess:false,
          callAuction:{
            venue:null,
            date:null,
          },
          auctions:{},//オークション会場一覧
          payment:{},
          childItems:{
            complete_request:'',
            car:{},
            sale:{
              sale_payments:{}
            },
          },
          parentErrors:{},
          parentUpdateSuccess: false,
          errors:{},
          updateSuccess: false,
          displayPaymentStatusForm:[],
          saleComparison: {
            modalInstance: null,
            child_id:null,
          },
          saveConfirmation: {
            modalInstance: null,
          },
          loadErrorChildItems: '',
          Suppliers:{},
          Notes:{},
          alreadyInputSum:'',
          savedSum:'',
          inputTotalMatch:false,
          savedTotalMatch:false,
          remainingAmount:'',
          buyElement:0,
          provisionalCalculation:{},
          consumptionTax:0,
          Processing:[],//処理中の行のIDを配列に格納
        }
      },
      mounted: function(){
        this.csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        const container = this.$el;//<div class="transaction_item_service-edit">
        this.payment = JSON.parse(container.getAttribute('data-payment'));
        this.auctions = JSON.parse(container.getAttribute('data-auctions'));

        //消費税
        this.consumptionTax = 1 + (this.payment.consumption_tax /100)
        this.setChildItems(true);
        this.setSuppliers();

      },
      beforeDestroy(){//インスタンスが破棄される前に
        if (this.saleComparison.modalInstance){
          $('.modal-backdrop').remove();//モーダルを表示したままブラウザの戻るボタンや進むボタンを押した際に操作できなくなる問題対応
          this.saleComparison.modalInstance.modal('dispose');
        }
        if (this.saveConfirmation.modalInstance){
          $('.modal-backdrop').remove();//モーダルを表示したままブラウザの戻るボタンや進むボタンを押した際に操作できなくなる問題対応
          this.saveConfirmation.modalInstance.modal('dispose');
        }
      },

      watch:{
      },
      computed:{
      },
      methods: {

        setChildItems() {
          return new Promise(async (resolve, reject) => {
            try{
              const response = await axios.get(`/admin/transaction_item_services/api/${this.payment.id}/child_items.json`)
              this.childItems = response.data
              this.loadErrorChildItems = '';
              //仮計算エリアの表示
              let calculate_sum = this.calculateSum()

              this.savedSum = calculate_sum[0] //保存後の合計額
              this.savedTotalMatch = calculate_sum[1] //入金額と保存後の合計が一致しているか？
              this.alreadyInputSum = calculate_sum[0] //入力中の合計
              this.inputTotalMatch = calculate_sum[1] //入力中の合計と入金額が一致しているか？
              this.remainingAmount = calculate_sum[2] //入力中の合計と入金額の差額（残りどれだけの金額で合計と入金額が一致するか？）

              // 初期値入れない issue 4566
              //saleデータへ反映する為のセレクトボックスに初期値を設定
              // for (let key in this.childItems) {
              //   if (this.childItems[key]['transaction_type']== "販売" && this.childItems[key]['selling_price']){
              //     更新対象は「1」固定 issue4566
              //     this.childItems[key]['sale_update_num'] = "1"
              //     if (this.childItems[key]['sale_update_num'] == null ){
              //       this.childItems[key]['sale_update_num'] = "1"
              //     }
              //     if (this.childItems[key]['complete_request'] == null ){
              //       this.childItems[key]['complete_request'] = "1"
              //     } 
              //   }
              // }
      
              //payment_managements.child_sum_matchを更新する必要がある
              if (this.savedTotalMatch != this.payment.child_sum_match){
                this.changeChildSumMatch()
              }
              resolve();
            }catch (error) {
              this.loadErrorChildItems = 'Faild to load items.';
              reject(error)
            }
          })
        },

        // 一致不一致の更新
        changeChildSumMatch() {
          return new Promise(async (resolve, reject) => {
            try{
              const result = await axios.post(
                `/admin/payment_managements/${this.payment.id}/change_child_sum_match`,
                {
                  child_sum_match:this.savedTotalMatch, //true or false
                },
                {
                  headers:{
                    "X-Requested-With": "XMLHttpRequest",
                    "X-CSRF-Token": this.csrfToken
                  }
                }
              )
              if (result.data.updated){
                this.payment.child_sum_match = result.data.child_sum_match
              }
              resolve();
            }catch (error) {
              reject(error)
            }
          })
        },

        //取引先一覧
        setSuppliers() {
          return new Promise(async (resolve, reject) => {
            try{
              const response = await axios.get(`/admin/payment_managements/api/suppliers`)
              this.Suppliers = response.data
              resolve();
            }catch (error) {
              reject(error)
            }
          })
        },


        calculateSum(exclusion_id = null) {
          //入出金額
          var price = 0
          if (this.payment.deposit_jpy != null ){
            price = Number(this.payment.deposit_jpy)
          }else if (this.payment.withdrawal_jpy != null){
            price = Number( - this.payment.withdrawal_jpy)
          }
          //本体の合計
          var payment_total = 0
          //内訳の金額の合計
          var child_total = 0
          //本体と内訳の合計
          var total = 0 
          var remaining =0
          var machth = false

          //仮計算エリア
          this.provisionalCalculation.buy_lement = 0
          this.provisionalCalculation.auction_fee = 0
          this.provisionalCalculation.buy_expenses = 0
          this.provisionalCalculation.sale_element = 0
          this.provisionalCalculation.sale_expenses = 0

          if (price != null){
            //加算
            //・自税返金分（vehicle_tax）
            //・ペナルティ（入金）※不課税（penalty）
            //・ペナルティ（入金）※課税（penalty_taxation）
            //・その他（支払）

            //減算
            //・入札参加料（bid_fee）
            //・ペナルティ（支払）※不課税（payment_penalty）
            //・ペナルティ（支払）※課税（payment_penalty_taxation）
            //・陸送費（オークション用）(carriage)
            //・その他（入金）

            payment_total = Number(this.payment.vehicle_tax) +  Number(this.payment.penalty) + Number(this.payment.penalty_taxation) + Number(this.payment.other) - (Number(this.payment.payment_penalty) + Number(this.payment.payment_penalty_taxation) + Math.round(Number(this.payment.bid_fee) * this.consumptionTax) + Math.round(Number(this.payment.carriage) * this.consumptionTax) + Number(this.payment.payment_other))
            
            for (let key in this.childItems) {

              //スキップ（削除の場合に利用。削除予定のアイテムを除外して合計を算出）
              if (exclusion_id ==this.childItems[key]['id']){
                continue;
              }

              //////// 購入側各値
              //落札価格(消費税を加えて四捨五入)
              var contract_price = Math.round(this.childItems[key]['contract_price'] * this.consumptionTax)
              // 落札料(消費税を加えて四捨五入)
              var auction_fee = Math.round(this.childItems[key]['auction_fee'] * this.consumptionTax)
              //その他（仕入れ）(消費税を加えて四捨五入)
              var other_fee = Math.round(this.childItems[key]['other_fee'] * this.consumptionTax)

              //購入側諸経費合計
              var buy_expenses = Number(this.childItems[key]['recycling_deposit']) //R預託金
              + Math.round(this.childItems[key]['pre_car_tax'] * this.consumptionTax) //自税相当額
              + Number(this.childItems[key]['payment_security_money']) //保証金（支払）
              + Math.round(this.childItems[key]['carriage'] * this.consumptionTax) //陸送代
              + Math.round(this.childItems[key]['bid_fee'] * this.consumptionTax) //入札手数料
              + Number(this.childItems[key]['payment_penalty']) //ペナルティ（支払）※不課税
              + Number(this.childItems[key]['payment_penalty_taxation'])//ペナルティ（支払）※課税

              //////// 販売側各値
              //販売価格
              var selling_price = Math.round(this.childItems[key]['selling_price'] * this.consumptionTax)
              //その他（販売）
              var reissue_other_fee =  Math.round(this.childItems[key]['reissue_other_fee'] * this.consumptionTax)
         
              //販売側諸経費合計
              var sale_expenses = 
              Number(this.childItems[key]['reissue_recycling_deposit'])//R預託金
               + Number(this.childItems[key]['penalty'])//ペナルティ(不課税)
               + Number(this.childItems[key]['penalty_taxation'])//ペナルティ(課税)
               + Number(this.childItems[key]['security_money'])//保証金（入金）
              -(
                Math.round(this.childItems[key]['exhibiting_fee'] * this.consumptionTax) //出品料
                +  Math.round(this.childItems[key]['contract_fee'] * this.consumptionTax)//成約料
                )

              //購入の場合
              if (this.childItems[key]['transaction_type'] == '購入'){
                child_total = child_total - (contract_price + auction_fee + other_fee + buy_expenses)
                //仮計算エリア
                this.provisionalCalculation.buy_lement =  this.provisionalCalculation.buy_lement + (contract_price + other_fee)
                this.provisionalCalculation.auction_fee = this.provisionalCalculation.auction_fee +  auction_fee

                this.provisionalCalculation.buy_expenses =  this.provisionalCalculation.buy_expenses + (buy_expenses)
              }
              //販売の場合
              if (this.childItems[key]['transaction_type'] == '販売'){
                child_total = child_total + (selling_price + reissue_other_fee + sale_expenses)
                //仮計算エリア
                this.provisionalCalculation.sale_element =  this.provisionalCalculation.sale_element + (selling_price + reissue_other_fee )
                this.provisionalCalculation.sale_expenses =  this.provisionalCalculation.sale_expenses + (sale_expenses)
              }
            }
            //親と子の合計
            total = payment_total + child_total
            remaining = price - total
            if (price == total){
              machth = true
              //this.payment.child_sum_match = true
            }else{
              //this.payment.child_sum_match = false
            }
          }
          return [total, machth,remaining];
        },
        showSaleModal(child_id) {
          this.saleComparison.child_id = child_id
          this.saleComparison.modalInstance = $(this.$refs.showSaleModal).modal({keyboard: false, show:true});
          const $modalBody = $('.modal-body', this.$refs.showSaleModal)
          $modalBody.height($modalBody.height() + 1)
        },
        hideSaleModal() {
          if (this.saleComparison.modalInstance){
            this.saleComparison.modalInstance.modal('hide');
          }
        },
        showSaveModal() {
          this.saveConfirmation.modalInstance = $(this.$refs.showSaveModal).modal({keyboard: false, show:true ,backdrop:"static"});
          const $modalBody = $('.modal-body', this.$refs.showSaveModal)
          $modalBody.height($modalBody.height() + 1)
        },
        hideSaveModal() {
          if (this.saveConfirmation.modalInstance){
            this.saveConfirmation.modalInstance.modal('hide');
          }
        },
        onChangePrice(form_type ,id=""){
          //値が数字ではない場合、空欄に戻す
          if (id == this.payment.id){
            if (['supplier_id','memo'].includes(form_type) == false){
              if (Number.isInteger(this['payment'][`${form_type}`]) != true){
                this['payment'][`${form_type}`] = ""
              }
            }
          }else{
            //対象のフォームを探す
            var child_item = this.childItems.find(e => e.id === id);
            if (['complete_request','transaction_type'].includes(form_type) == false){
              if (Number.isInteger(child_item[`${form_type}`]) != true){
                child_item[`${form_type}`] = ""
              }
            }
          }

          let calculate_sum = this.calculateSum()
          this.alreadyInputSum = calculate_sum[0]
          this.inputTotalMatch = calculate_sum[1]
          this.remainingAmount = calculate_sum[2]

          //保存前の注意書き
          if (child_item){
            // payment_statusをtrueにするフォームを出力
            if (child_item.transaction_type == '販売' && child_item.for_tax_fraction == false){
              if (form_type == "selling_price"){
                // 配列にidを入れる事により、支払い完了ステータスを画面上に描画する
                if (this.displayPaymentStatusForm.indexOf(child_item.id) === -1){
                  this.displayPaymentStatusForm.push(child_item.id)
                  // 初期値設定
                  child_item.complete_request = "1"
                } 
                // 空欄の場合
                if (child_item.selling_price === ""){
                  //配列からidを除去
                  var index = this.displayPaymentStatusForm.indexOf(child_item.id);
                  this.displayPaymentStatusForm.splice(index, 1)
                  //支払い完了ステータスの値を除去
                  child_item.complete_request = null
                }
                if (child_item.selling_price === 0){
                  //
                }
                if (child_item.selling_price > 0){
                  //
                }
              }
            }

            //saleデータへ反映する為のセレクトボックスに初期値を設定
            // if (child_item.transaction_type== "販売"){
            //   if (child_item.sale_update_num == null ){
            //     child_item.sale_update_num = "1"
            //   }
            //   if (child_item.complete_request == null ){
            //     child_item.complete_request = "1"
            //   } 
            // }
            
            var msgs = []//黄色文字の注意文
            var importants =[]//赤文字の注意文

            //salesと入力中のデータを比較
            if (child_item.transaction_type == '購入' && child_item.for_tax_fraction == false){
              //msgs = null
              //落札価格
              if (child_item.sale.contract_price != null && child_item.contract_price != '' && child_item.contract_price != null  && child_item.sale.contract_price != child_item.contract_price){
                msgs.push('保存すると、salesの落札価格が置き換わります');
              }

              //落札料(税抜)
              if (child_item.sale.auction_fee != null && child_item.auction_fee !='' && child_item.auction_fee != null && child_item.sale.auction_fee != child_item.auction_fee){
                msgs.push('保存すると、salesの落札料が置き換わります');
              }
              //その他（仕入）
              if (child_item.sale.other_fee != null && child_item.other_fee !='' && child_item.other_fee != null  &&  child_item.sale.other_fee != child_item.other_fee){
                msgs.push('保存すると、salesのその他（仕入）が置き換わります');
              }
              //R預託金
              if (child_item.sale.recycling_deposit != null && child_item.recycling_deposit !='' && child_item.recycling_deposit != null &&  child_item.sale.recycling_deposit != child_item.recycling_deposit){
                msgs.push('保存すると、salesのR預託金が置き換わります');
              }
              //自税相当額
              if (child_item.sale.pre_car_tax != null && child_item.pre_car_tax !='' && child_item.pre_car_tax != null && child_item.sale.pre_car_tax != child_item.pre_car_tax){
                msgs.push('保存すると、salesの自税相当額が置き換わります');
              }
              //保証金
              if (child_item.sale.payment_security_money != null && child_item.payment_security_money !='' && child_item.payment_security_money != null && child_item.sale.payment_security_money != child_item.payment_security_money){
                msgs.push('保存すると、salesの保証金が置き換わります');
              }
              //陸送代
              if (child_item.sale.carriage != null && child_item.carriage !='' && child_item.carriage != null && child_item.sale.carriage != child_item.carriage){
                msgs.push('保存すると、salesの陸送代が置き換わります');
              }
              //ペナルティ(不課税)
              if (child_item.sale.payment_penalty != null && child_item.payment_penalty !='' && child_item.payment_penalty != null && child_item.sale.payment_penalty != child_item.payment_penalty){
                msgs.push('保存すると、salesのペナルティ(不課税)が置き換わります');
              }
              //ペナルティ(課税)
              if (child_item.sale.payment_penalty_taxation != null && child_item.payment_penalty_taxation !='' && child_item.payment_penalty_taxation != null && child_item.sale.payment_penalty_taxation != child_item.payment_penalty_taxation){
                msgs.push('保存すると、salesのペナルティ(課税)が置き換わります');
              }

            }else if (child_item.transaction_type == '販売' && child_item.for_tax_fraction == false){
              //msgs = null
              if (child_item.sale.sale_payments && child_item.selling_price !='' && child_item.selling_price != null ){
                //対象のsale_paymentレコードを探す
                var target_payment = child_item.sale.sale_payments.find(e => e.num === 1);
                if (target_payment && target_payment.payment > 0  && target_payment.payment != child_item.selling_price){
                  msgs.push('保存すると、salesの支払い金額が置き換わります');
                }
              }
              //その他（販売)
              if (child_item.sale.reissue_other_fee != null && child_item.reissue_other_fee !='' && child_item.reissue_other_fee != null &&  child_item.sale.reissue_other_fee != child_item.reissue_other_fee){
                msgs.push('保存すると、salesのその他（販売)が置き換わります');
              }
              //R預託金
              if (child_item.sale.reissue_recycling_deposit != null && child_item.reissue_recycling_deposit !='' && child_item.reissue_recycling_deposit != null && child_item.sale.reissue_recycling_deposit != child_item.reissue_recycling_deposit){
                msgs.push('保存すると、salesのR預託金が置き換わります');
              }
              //保証金
              if (child_item.sale.security_money != null && child_item.security_money !='' && child_item.security_money != null && child_item.sale.security_money != child_item.security_money){
                msgs.push('保存すると、salesの保証金が置き換わります');
              }
              //ペナルティ(不課税)
              if (child_item.sale.penalty != null && child_item.penalty !='' && child_item.penalty != null && child_item.sale.penalty != child_item.penalty){
                msgs.push('保存すると、salesのペナルティ(不課税)が置き換わります');
              }
              //ペナルティ(課税)
              if (child_item.sale.penalty_taxation != null && child_item.penalty_taxation !='' && child_item.penalty_taxation != null && child_item.sale.penalty_taxation != child_item.penalty_taxation){
                msgs.push('保存すると、salesのペナルティ(課税)が置き換わります');
              }
              //出品料
              //sale側は税込で保存されているので、税込価格に変換してから比較
              if (child_item.sale.exhibiting_fee != null && child_item.exhibiting_fee !='' && child_item.exhibiting_fee != null && child_item.sale.exhibiting_fee != Math.round(child_item.exhibiting_fee * this.consumptionTax)){
                msgs.push('保存すると、salesの出品料が置き換わります');
              }
              //成約料
              //sale側は税込で保存されているので、税込価格に変換してから比較
              if (child_item.sale.contract_fee != null && child_item.contract_fee !='' && child_item.contract_fee != null && child_item.sale.contract_fee != Math.round(child_item.contract_fee * this.consumptionTax)){
                msgs.push('保存すると、salesの成約料が置き換わります');
              }

              // 販売価格（赤文字）
              if ((child_item.car.status =="完了_自社" || child_item.car.status =="完了_AA代行") && (child_item.selling_price !='' && child_item.selling_price != null)){
                importants.push('販売価格への入力は適切ではないかもしれません。車両のステータスを確認してください。');
              }
              // 支払い完了ステータス（赤文字）
              if(child_item.complete_request == true && (child_item.selling_price =='' || child_item.selling_price == null || child_item.selling_price == 0)){
                importants.push('支払い完了ステータスの入力に誤りがあるかもしれません。');
              }
            }
            this.Notes[`update_${child_item.id}`] = msgs
            this.Notes[`important_${child_item.id}`] = importants
          }
        },

        //オークション情報から車両を呼び出す
        addChildItemCallAuction: async function (){
          if (this.callAuction.venue && this.callAuction.date){
            this.callAuctionProcess = true
            this.callAuctionAddCount = null;
            return new Promise(async (resolve, reject) => {
              try{
                const result = await axios.post(
                  `/admin/transaction_item_services/add_child_item_call_auction/${this.payment.id}`,
                  {
                    auction_venue:this.callAuction.venue,
                    purchase_date:this.callAuction.date
                  },
                  {
                    headers:{
                      "X-Requested-With": "XMLHttpRequest",
                      "X-CSRF-Token": this.csrfToken
                    }
                  }
                )
                this.callAuctionAddCount = result.data.add_count

                if (this.callAuctionAddCount >= 1){
                  this.callAuction.venue = ""
                  this.callAuction.date = ""
                  setTimeout(() => {
                      this.callAuctionAddCount = null;
                  }, 2);
                }
                this.setChildItems()
                this.callAuctionProcess = false
                resolve();
              }catch (error) {
                this.callAuctionProcess = false
                reject(error)
              }
            })
          }
        },

        //vinを指定して車両を呼び出す
        addChildItemCallVin: async function (){
          if (this.callVin){
            this.callVinSuccess = null;
            this.callVinProcess = true
            return new Promise(async (resolve, reject) => {
              try{
                const result = await axios.post(
                  `/admin/transaction_item_services/add_child_item_call_vin/${this.payment.id}`,
                  {
                    vin:this.callVin,
                  },
                  {
                    headers:{
                      "X-Requested-With": "XMLHttpRequest",
                      "X-CSRF-Token": this.csrfToken
                    }
                  }
                )
                if (result.data.child_item){
                  this.callVinSuccess = true
                  this.callVin = ""
                  setTimeout(() => {
                      this.callVinSuccess = null;
                  }, 2);
                }else{
                  this.callVinSuccess = false
                }
                this.setChildItems()
                this.callVinProcess = false
                resolve();
              }catch (error) {
                this.callVinProcess = false
                reject(error)
              }
            })
          }
        },

        //親レコードの保存
        updateParentItem: async function (id){
          //送信用に必要なだけsend_dataに格納
          var send_data = {
            id:                          this.payment.id,
            child_sum_match:             this.payment.child_sum_match,
            supplier_id:                 this.payment.supplier_id,
            carriage:                    this.payment.carriage,
            vehicle_tax:                 this.payment.vehicle_tax,
            bid_fee:                     this.payment.bid_fee,
            payment_penalty:             this.payment.payment_penalty,
            payment_penalty_taxation:    this.payment.payment_penalty_taxation,
            penalty:                     this.payment.penalty,
            penalty_taxation:            this.payment.penalty_taxation,
            other:                       this.payment.other,
            payment_other:               this.payment.payment_other,
            memo:                        this.payment.memo,
          }
          return new Promise(async (resolve, reject) => {
            try{
              const result = await axios.post(
                `/admin/transaction_item_services/parent_update/${this.payment.id}`,
                {
                  payment_item: send_data
                },
                {
                  headers:{
                    "X-Requested-With": "XMLHttpRequest",
                    "X-CSRF-Token": this.csrfToken
                  }
                }
              )
              if (result.data.updated ==true){
                this.parentErrors=""
                this.setChildItems()
                this.parentUpdateSuccess = true
                setTimeout(() => {
                  this.parentUpdateSuccess = false
                }, 2);

              }else{
                this.parentErrors = ""
                this.setChildItems()
                this.parentErrors = result.data.errors_message
              }
              resolve();
            }catch (error) {
              reject(error)
            }
          })
        },

        //内訳レコードの保存
        updateChildItem: async function (id){
          //対象のフォームを探す
          let child_item = this.childItems.find(e => e.id === id);
          //処理中の配列にidを入れる
          this.Processing.push(id)
          
          //送信用に必要なだけsend_dataに格納
          var send_data = JSON.parse(JSON.stringify(child_item))
          delete send_data.car;
          delete send_data.sale;
          return new Promise(async (resolve, reject) => {
            try{
              const result = await axios.post(
                `/admin/transaction_item_services/update/${this.payment.id}`,
                {
                  child_item: send_data
                },
                {
                  headers:{
                    "X-Requested-With": "XMLHttpRequest",
                    "X-CSRF-Token": this.csrfToken
                  }
                }
              )

              if (result.data.updated ==true){
                this.errors[`${child_item.id}`]=""
                this.Notes[`update_${child_item.id}`]= "";
                this.Notes[`important_${child_item.id}`]= "";
                this.setChildItems()

                // payment_statusをtrueにするフォームを非表示にする
                var index = this.displayPaymentStatusForm.indexOf(child_item.id);
                this.displayPaymentStatusForm.splice(index, 1)
                child_item.complete_request = null
                

                this.updateSuccess = child_item.id;
                setTimeout(() => {
                    this.updateSuccess = false;
                }, 2);
              }else{
                this.errors[`${child_item.id}`]=""
                this.setChildItems()
                this.errors[`${child_item.id}`] = result.data.errors_message
              }
              //処理中の配列からidを除去
              var index = this.Processing.indexOf(id);
              this.Processing.splice(index, 1)
              resolve();
            }catch (error) {
              //処理中の配列からidを除去
              var index = this.Processing.indexOf(id);
              this.Processing.splice(index, 1)
              reject(error)
            }
          })
        },
        //内訳レコードの削除
        deleteChildItem: async function (id){
          var confirm_message = "削除しますか？"
          //引数に削除のidを入力し、除外した合計を算出
          let calculate_sum = this.calculateSum(id)

          if(!confirm(confirm_message)){
            return false;
              } else {
                return new Promise(async (resolve, reject) => {
                  try{
                    const result = await axios.post(
                      `/admin/transaction_item_services/${this.payment.id}/child_destroy`,
                      {
                        child_id: id,
                        child_sum_match:calculate_sum[1]
                      },
                      {
                        headers:{
                          "X-Requested-With": "XMLHttpRequest",
                          "X-CSRF-Token": this.csrfToken
                        }
                      }
                    )
                    this.setChildItems(true)
                    resolve();
                  }catch (error) {
                    reject(error)
                  }
                })
          }
        },

        //税金の端数対策用のレコードを追加
        createForTaxFraction: async function (id){
          var confirm_message = "税金の端数対策用にレコードを追加します。"
          if(!confirm(confirm_message)){
            return false;
              } else {
                return new Promise(async (resolve, reject) => {
                  try{
                    const result = await axios.post(
                      `/admin/transaction_item_services/for_tax_fraction`,
                      {
                        child_id: id
                      },
                      {
                        headers:{
                          "X-Requested-With": "XMLHttpRequest",
                          "X-CSRF-Token": this.csrfToken
                        }
                      }
                    )
                    this.setChildItems(true)
                    resolve();
                  }catch (error) {
                    reject(error)
                  }
                })
          }
        },

      },
  })
});
document.addEventListener('turbolinks:visit', () => {
    // if (subscription) {
    //     consumer.subscriptions.remove(subscription)
    // }

    // if ($notification){
    //     $('.toast', $notification).toast('dispose');
    //     $notification.remove();
    // }

    // $notification = null;
});