import Vue from 'vue/dist/vue.esm'

import TurbolinksAdapter from 'vue-turbolinks';
Vue.use(TurbolinksAdapter);

document.addEventListener('turbolinks:load', () => {
  const selector = '#admin-vertice-car-model-form-vue';
  const elements = document.querySelectorAll(selector);
  if (elements.length === 0) {
    return
  }

  new Vue({
    el: selector,
    data: {
      model: {},
      makerId: '',
      carModels: [],
      carModelOptions: {}
    },
    mounted() {
      const container = this.$el;//<div id="admin-vertice-car-model-form-vue">

      this.model = JSON.parse(container.getAttribute('data-vertice-car-model'));
      this.carModels = JSON.parse(container.getAttribute('data-car-models'));
      this.onLoad();
    },
    watch: {
      makerId: function(){
        this.carModelOptions = this.carModels.filter(carModel=>carModel['maker_id'] == this.makerId);
      },
      'model["name"]': function(){
        if(this.model['name'] === null){
          this.model['name'] = '';
        }
        this.model['name'] = this.model['name'].normalize("NFKC");
      }
    },
    methods: {
      onLoad: function(){
        const carModel = this.carModels.find(carModel=>carModel['id'] == this.model['car_model_id']);
        this.makerId = carModel['maker_id'];
      },
      onMakerChanged() {
        this.model['car_model_id'] = '';
      }
    }
  })
});