document.addEventListener('turbolinks:load', () => {

  const selector = '.admin_users_index';
  const elements = document.querySelectorAll(selector);
  if (elements.length === 0) {
      return
  }

  //連打対策
  var canAjax = true

    $(function() {
      $(document).on("click", "#csv_urikake_users", function () {
        $("#q_csv_type").val("csv_urikake_users")
        window.confirm('ユーザー別 売掛一覧csvをダウンロードします');
      })
      $(document).on("click", "#csv_urikake_orders", function () {
        $("#q_csv_type").val("csv_urikake_orders")
        window.confirm('Order別 売掛一覧csvをダウンロードします');
      })
      $(document).on("click", "#csv_mail_magazine", function () {
        $("#q_csv_type").val("csv_mail_magazine")
        window.confirm('メールマガジンcsvをダウンロードします');
      })
    });
  
    // All_btn押下時のチェックボックス制御
    $(function () {
      $(document).off("click", "#user_index_check_all").on("click", "#user_index_check_all", function () {
        var check_flg = $("#user_index_check_all").attr("data-check-flg")
        if (check_flg === "true") {
          $(".car_price_index_check_box").prop('checked', false);
          document.getElementById("user_index_check_all").dataset.checkFlg = "false";
        } else {
          $(".car_price_index_check_box").prop('checked', true);
          document.getElementById("user_index_check_all").dataset.checkFlg = "true";
        }
      })
    })
  
    // issue_3013「assign yourself」ボタンを押下時
    $(function(){
      $(document).on("click", ".admin_setting", function () {
        //データのidを取得
        var request_id  =$(this).attr("data-requestId");
        var btn =$(this)
        assign_setting(request_id ,btn)
      })
      function assign_setting(id ,btn){
        if (id) {
          $.ajax({
            type: "POST",
         
            url:  `/admin/users/admin_user_self_assign_setting`,
      
            beforeSend: function(xhr) {xhr.setRequestHeader("X-CSRF-Token", $('meta[name="csrf-token"]').attr('content'))},
            data: {user_id: id}
          }).done(function(json){
            if (json) {
              if (json["error"]) {
                $(`#request_admin_username_${id}`).html('error')
                console.log(json["error"])
                return
              }
  
              if (json["admin_id"] == null){
                $(`#request_admin_username_${id}`).html('Unset')
              } else {
                $(`#request_admin_username_${id}`).html(json["admin_username"])
                btn.css('display', 'none')
              }
            }
          }).fail(function(){
            // エラーの場合処理
            $(`#request_admin_username_${id}`).html('error')
          });
        }
      }
    })

    // issue#4151 顧客属性項目追加
    $(function () {
      $(document).on("click", ".category_dealer_company,.category_individual_importer,.category_no_experience,.update_memo_button", function () {
        //連打対策
        if(!canAjax) {
          return
        }
        let target = null 
        const user_id = $(this).parent()[0].dataset.userId
        const update_memo_flg = $(this).hasClass('update_memo_button')
        let memo_value = null
        let check_flg = null
        const data_user = {}
        
        if(update_memo_flg){
          target = $(this).parent().children(`textarea#memo_${user_id}`)
          memo_value = target.val()
          data_user['memo'] = memo_value
        }else{
          target = $(this)
          const paramName = $(this).attr('class')
          check_flg = $(this).prop("checked")
          data_user[paramName] = check_flg
        }
        const data = { user: data_user }
        $.ajax({
          type: "POST",
          url: `/admin/users/${user_id}/update_category`,
          data: data,
          beforeSend: function(xhr){
            xhr.setRequestHeader("X-CSRF-Token", $('meta[name="csrf-token"]').attr('content'))
          }
        }).done(function(json){
          if(json.status === 'ok'){
            // エラーメッセージが出ていた場合は、フェードアウト
            $(`#user_id_${json.user_id} .${json.target_column}_${json.user_id}.ng_msg`).fadeOut();
            //画面上に「OK」の文字列を出力した後、フェードアウト
            $(`#user_id_${json.user_id} .${json.target_column}_${json.user_id}.done_msg`).fadeIn("fast", function () {
              $(this).delay(500).fadeOut("slow");
            });
            target.prop("checked", check_flg)
            if(update_memo_flg){
              target.val(memo_value)
              // ボタンを非活性
              $(`#note_category_${json.user_id}`).prop('disabled', true);
              $(`#note_category_${json.user_id}`).removeClass("btn-primary");
            }else{
              target.prop("checked", check_flg)
            }
          }else{
            console.log('user update error')
            if(update_memo_flg){
              target.val("error: please reload page")
              // ボタンを非活性
              $(`#note_category_${json.user_id}`).prop('disabled', true);
              $(`#note_category_${json.user_id}`).removeClass("btn-primary");

            }else{
            //画面上に「NG」の文字列を出力
            $(`#user_id_${json.user_id} .${json.target_column}_${json.user_id}.ng_msg`).fadeIn("slow");
              target.prop("checked", !check_flg)
            }
          }
        }).fail(function(){
          console.log('user update error')
          if(update_memo_flg){
            target.val("error: please reload page")
          }else{
            target.prop("checked", !check_flg)
          }
        }).always(function() {
          //連打対策
          canAjax = true
        });        
      })

      //メモに編集を加えたら、ボタンを活性化
      $(".memo").change(function(){
        const user_id = $(this).attr('id').replace("memo_", "")
        $(`#note_category_${user_id}`).prop('disabled', false);
        $(`#note_category_${user_id}`).addClass("btn-primary");
      });
    })


}) ;
document.addEventListener('turbolinks:visit', () => {
  tinymce.remove('.admin_users_index');
}) ;