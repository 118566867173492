import consumer from "../../channels/consumer"
import {win} from "../../../../public/tinymce/js/tinymce/tinymce";

consumer.subscriptions.create("DownloadStatusChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    if ($(".admin_downloads_index" ).length == 0) {
      return
    }

    //時間差によってブロードキャストを受信できなかったときのための対策として、コネクト直後に一度最新情報を取得する
    $( ".admin_downloads_index" ).load( "/admin/downloads .admin_downloads_index" );
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    if ($(".admin_downloads_index" ).length == 0) {
      return
    }
    $( ".admin_downloads_index" ).load( "/admin/downloads .admin_downloads_index" );
  }
});
