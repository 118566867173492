import Vue from 'vue/dist/vue.esm'
import axios from 'axios';
import qs from 'qs';

import TurbolinksAdapter from 'vue-turbolinks';
Vue.use(TurbolinksAdapter);

document.addEventListener('turbolinks:load', () => {
    const selector = '.request-payment-confirmations-edit';
    const elements = document.querySelectorAll(selector);
    if (elements.length === 0) {
        return
    }

    new Vue({
      el: ".request-payment-confirmations-edit",


      data: function (){
        return {
          userSearch: {
            modalInstance: null,
            users: [],
            q: {}
          },
          orderSearch: {
            modalInstance: null,
            orders: [],
            q: {}
          },  
          csrfToken: '',
          unarrived:{},
          childItems:{},
          loadError: '',
          errors:{},
          updateSuccess: false,
          orderSearchTarget:'',
          alreadyInputSum:'',//入力中の内訳合計
          savedSum:'',//保存済みの内訳合計
          inputTotalOver:false,
          activeBtns:{},//各内訳の保存ボタンの「活性」or「非活性」判定
          requestConfirmationBtn:true,//申請ボタンの「活性」or「非活性」判定
          valueChecks:{},//各内訳の値をチェックし、誤りがある場合は赤文字を表示
          bankFeeDisabled:false,//銀行手数料のフォームの「活性」or「非活性」判定
          allApproved:true//すべて承認済みである
        }
      },
      beforeDestroy(){//インスタンスが破棄される前に
        if (this.userSearch.modalInstance){
          $('.modal-backdrop').remove();//モーダルを表示したままブラウザの戻るボタンや進むボタンを押した際に操作できなくなる問題対応
          this.userSearch.modalInstance.modal('dispose');
        }
        if (this.orderSearch.modalInstance){
          $('.modal-backdrop').remove();//モーダルを表示したままブラウザの戻るボタンや進むボタンを押した際に操作できなくなる問題対応
          this.orderSearch.modalInstance.modal('dispose');
        }
      },
      mounted: function(){
        this.csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        const container = this.$el;//<div class="request-payment-confirmations-edit">

        this.unarrived = JSON.parse(container.getAttribute('data-unarrived'));
        this.new_style = JSON.parse(container.getAttribute('data-new-style'));

        this.setChildItems(true);
      },
      watch:{
      },
      computed:{
      },
      methods: {
        // 以下のitemTypeは新規での利用機会はありません。(念のため分岐は残しておきます。) #4713
        //（海外送金手数料、海外車両販売、次回繰越、返金、AuctionDeposit、Amend B/L）

        setChildItems(set_input_total = false) {
          return new Promise(async (resolve, reject) => {
            try{
              const response = await axios.get(`/admin/request_payment_confirmations/api/${this.unarrived.id}/child_items.json`)
              this.childItems = response.data
              this.loadError = '';
              resolve(this.childItems);
            }catch (error) {
              this.loadError = 'Faild to load items.';
              reject(error)
            }
          }).then((child_items) => {
              //保存済みの内訳を合計し、入金予定額と一致しているか調べる
              let calculate_sum = this.calculateSum()
              this.savedSum = calculate_sum[0]

              //削除のケース等入力フォームの合計の計算が必要なケース
              if (set_input_total ==true){
                this.alreadyInputSum = calculate_sum[0]
                this.inputTotalOver = calculate_sum[1]
              }
              //Saveボタンactive判定
              for (let key in child_items) {
                this.setActiveBtns(this.childItems[key])
                this.setIsDisabled(this.childItems[key])
                this.setValueCheck(this.childItems[key])
              }
              this.setRequestConfirmationBtn()
              //bank_feeのフォームを非活性にする条件
              if(this.childItems.find(e => e.item_type == "次回繰越") && this.new_style ){
                this.bankFeeDisabled = true
              }else{
                this.bankFeeDisabled = false
              }

              //内訳が全て承認済みである
              if(this.childItems.find(e => e.status != "approved") ){
                this.allApproved = false
              }else{
                this.allApproved = true
              }

          });
        },
        //Saveボタンactive判定
        //setActiveBtns(id,price,type,order,request_num){
        setActiveBtns(child_item){
            //各値を取得
            var id = child_item.id
            if (this.unarrived.currency == "yen"){
              var price = child_item.fee_jpy
            }else if(this.unarrived.currency == "dollar"){
              var price = child_item.fee_usd
            }
            var type = child_item.item_type
            var order = child_item.order_id
            var request_num = child_item.request_num
            if (!price){
              //金額なし
              this.activeBtns[`${id}`] = false
            }else{
              if (type){
                if (["次回繰越","AuctionDeposit","海外送金手数料"].includes(type)){
                  this.activeBtns[`${id}`] = true
                }else{
                  if ((!order || order == "null") && type != "AuctionDeposit割当"){   
                    //オーダーなし
                    this.activeBtns[`${id}`] = false
                  }else{
                    if(["海外車両販売","繰越割当"].includes(type) && (!request_num || request_num == "null")){
                      //指定回数なし
                      this.activeBtns[`${id}`] = false
                    }else if(["繰越割当","Amend B/L繰越割当","AuctionDeposit割当"].includes(type) && (this.unarrived.available_carried_forward_balance < this.totalUseCarriedForward())){
                      //利用可能な繰越金残高をオーバーしている
                      this.activeBtns[`${id}`] = false
                    }else{
                      this.activeBtns[`${id}`] = true
                    }
                  }
                }
              }else{
                //タイプなし
                this.activeBtns[`${id}`] = false
              }
           } 
        },
        //item_typeによってフォームをを非活性にする
        setIsDisabled(child_item){
          child_item['Disableds'] = {}
          child_item['Disableds'].item_type = false
          child_item['Disableds'].order_id = false
          child_item['Disableds'].price = false
          child_item['Disableds'].request_num = false
          child_item['Disableds'].complete_request = false
          if (["次回繰越","AuctionDeposit","AuctionDeposit割当"].includes(child_item.item_type)){
            child_item.order_id = ""
            child_item.request_num = ""
            child_item.complete_request = false
            child_item.amend_bl_id = "" 
            child_item['Disableds'].order_id = true
            child_item['Disableds'].request_num = true
            child_item['Disableds'].complete_request = true
          }
          if (child_item.item_type=="海外送金手数料"){
            child_item.complete_request = false
            child_item['Disableds'].complete_request = true
            child_item.amend_bl_id = "" 
          }
          if (["海外車両販売","繰越割当"].includes(child_item.item_type)){
            child_item.amend_bl_id = "" 
          }
          if (["Amend B/L","Amend B/L繰越割当"].includes(child_item.item_type)){
            child_item.request_num = ""
            child_item.complete_request = false
            child_item['Disableds'].request_num = true
            child_item['Disableds'].complete_request = true
          }
      },

      //この画面の繰越充当割り当ての合計
      totalUseCarriedForward(){
        var total_carried_forward_balance = 0
        for (let key in this.childItems) {
            //利用する繰越金額が利用可能範囲内かどうかを調べる
            if (["繰越割当","Amend B/L繰越割当","AuctionDeposit割当"].includes(this.childItems[key]['item_type']) && this.childItems[key]['status']!="approved" ){
              if (this.unarrived.currency == "yen"){
                total_carried_forward_balance = Number(total_carried_forward_balance) + Number(this.childItems[key]['fee_jpy'])
              }else if(this.unarrived.currency == "dollar"){
                total_carried_forward_balance =  Number(total_carried_forward_balance) + Number(this.childItems[key]['fee_usd'])
              }
            }
        }
        return total_carried_forward_balance;
      },

      //invoiceと価格を比較し一致していない場合はメッセージを表示
      setValueCheck(child_item){
        //各値を取得
        var id = child_item.id
        if (this.unarrived.currency == "yen"){
          var price = child_item.fee_jpy
        }else if(this.unarrived.currency == "dollar"){
          var price = child_item.fee_usd
        }
        if (child_item.item_type == "海外車両販売" || child_item.item_type == "繰越割当"){
          for (let key in this.childItems) {
            //内訳の中から「海外車両販売」または「繰越割当」且つ、同じorder_idを持つ、且つ、同じ指定回数且つ、自データ以外をpriceに加算し、invoiceとの比較に利用する
            if ((this.childItems[key]['item_type'] == "海外車両販売" || this.childItems[key]['item_type'] == "繰越割当")  && this.childItems[key]['order_id'] == child_item.order_id && this.childItems[key]['request_num'] == child_item.request_num && this.childItems[key]['id']!= child_item.id){
              if (this.unarrived.currency == "yen"){
                price = Number(price) + Number(this.childItems[key]['fee_jpy'])
              }else if(this.unarrived.currency == "dollar"){
                price =  Number(price) + Number(this.childItems[key]['fee_usd'])
              }
            }else{
              continue;
            }
          }
        }

        var msgs = []
        //invoice情報が取得できる
        if (child_item.invoice_prices){
          //海外車両販売且つ、金額・回数が揃っている
          if ((child_item.item_type=="海外車両販売" || child_item.item_type=="繰越割当") && price && child_item.request_num ){
            if (child_item.order.other_reserved){
              msgs.push(`他のorderが予約中です（Other order reserved.）`);
            }
            if (child_item.order.double_booking){
              msgs.push(`ダブルブッキングの可能性があります（Possible double booking.）`);
            }
            for (let key in child_item.invoice_prices) {
              if (child_item.invoice_prices[key].num == child_item.request_num){
                //入金通貨とinvoice通貨が一致しない
                if (child_item.invoice && (child_item.invoice.currency != this.unarrived.currency)){
                  msgs.push(`invoiceの通貨が一致しません`);
                }   
                //入力中の価格とinvoiceの指定の回数の金額を比較
                if (child_item.invoice_prices[key].price != price){
                  msgs.push(`invoiceの価格と一致しません(Does not match invoice price)`);
                }
                //支払い完了を選択している
                if (child_item.complete_request == 'true' || child_item.complete_request == true){
                  if (child_item.request_num != child_item.invoice_prices.slice(-1)[0].num){
                    msgs.push(`${child_item.request_num}回目は、最後の支払いではありません（Please check complete request）`);
                  }
                }else{
                  if (child_item.request_num == child_item.invoice_prices.slice(-1)[0].num){
                    msgs.push('最後の支払いでは支払い完了にしてください（Please check complete request）');
                  }
                }
              }
            }
            if (this.unarrived.bank != "PAYPAL-JPY" && this.unarrived.bank != "PAYPAL-USD" ){
              if (child_item.item_type=="海外車両販売"){
                  if (child_item.request_num == 1 && !child_item.order.extension_1){
                    msgs.push('tt_copy_1がありません。（There is no tt_copy_1.）');
                  }
                  if (child_item.request_num == 2 && !child_item.order.extension_2){
                    msgs.push('tt_copy_2がありません。（There is no tt_copy_2.）');
                  }
                  if (child_item.request_num == 3 && !child_item.order.extension_3){
                    msgs.push('tt_copy_3がありません。（There is no tt_copy_3.）');
                  }
                  if (child_item.request_num == 4 && !child_item.order.extension_4){
                    msgs.push('tt_copy_4がありません。（There is no tt_copy_4.）');
                  }
                  if (child_item.request_num == 5 && !child_item.order.extension_5){
                    msgs.push('tt_copy_5がありません。（There is no tt_copy_5.）');
                  }
              }
            }
          }
          //アメンドBLの金額と一致していることを確認する
          if (["Amend B/L","Amend B/L繰越割当"].includes(child_item.item_type)){
            if (child_item.amend_bl_price != price){
              msgs.push('B/L訂正手数料が一致しません。(Does not match amend B/L price)');
            }
          }
          
          //各内訳をの繰越金の合計が、利用可能な範囲か調べる(承認後は表示しない)
          if(["繰越割当","Amend B/L繰越割当","AuctionDeposit割当"].includes(child_item.item_type) && child_item.status !="approved"){
            if(this.unarrived.available_carried_forward_balance < this.totalUseCarriedForward()){
              msgs.push('利用可能な繰越金残高をオーバーしています(Available carryover balance is exceeded)');
            }
          }
        }
        if (child_item.item_type=="海外送金手数料"){
          //外の内訳に海外車両販売が存在する場合、order情報の入力を求める。
          var has_order = this.childItems.find(e => e.item_type == "海外車両販売");
          if (has_order){
              if(!child_item.order_id || child_item.order_id == "null"){
                msgs.push('Order IDを入力してください。(Please enter your Order ID.)');
              }
              if(!child_item.request_num || child_item.request_num == "null"){
                msgs.push('何回目の支払いか？を入力してください。(How many payments?)');
              }
          }
        }
      this.$set(this.valueChecks, `value_checck_${id}`, msgs ) 
      },
      //申請ボタンの活性条件
      setRequestConfirmationBtn(){
        //availableをオーバしている場合は非活性
        if (this.inputTotalOver){
          this.requestConfirmationBtn = false
        }else{
          this.requestConfirmationBtn = true

        }
      },
      calculateSum() {
        //内訳の金額の合計
        var total =0
        var over =false
        if (this.unarrived.currency == "yen"){
          for (let key in this.childItems) {
            if(["繰越割当","Amend B/L繰越割当","AuctionDeposit割当"].includes(this.childItems[key]['item_type']) && this.childItems[key]['status'] !='approved'){
              total = total + Number(this.childItems[key]['fee_jpy'])
            }
          }
        }else if(this.unarrived.currency == "dollar"){
          
          for (let key in this.childItems) {
            if(["繰越割当","Amend B/L繰越割当","AuctionDeposit割当"].includes(this.childItems[key]['item_type']) && this.childItems[key]['status'] !='approved'){
              total = total + Number(this.childItems[key]['fee_usd'])
            }
          }
        }
        if(this.unarrived.available_carried_forward_balance < total ){
          over = true
        }
        return [total, over];
      },
      ////////小数点まるめ誤差対応して足し算／引き算の値を求める
      getValue(value_1,value_2,addition){
        ////////小数点まるめ誤差対応
        // それぞれの小数点の位置を取得
        var dotPosition1 = this.getDotPosition(value_1);
        var dotPosition2 = this.getDotPosition(value_2);
        
        // 位置の値が大きい方（小数点以下の位が多い方）の位置を取得
        var max = Math.max(dotPosition1,dotPosition2);
      
        // 大きい方に小数の桁を合わせて文字列化、
        // 小数点を除いて整数の値にする
        var intValue1 = parseInt((value_1.toFixed(max) + '').replace('.', ''));
        var intValue2 = parseInt((value_2.toFixed(max) + '').replace('.', ''));
        
        // 10^N の値を計算
        var power = Math.pow(10,max);
        
        //足し算のケース
        if (addition == true){
          // 整数値で引き算した後に10^Nで割る
          return (intValue1 + intValue2) / power
        //引き算のケース
        }else{
          // 整数値で引き算した後に10^Nで割る
          return (intValue1 - intValue2) / power
        }
      },

      onChangeForm(column,id){
        //対象のフォームを探す
        var child_item = this.childItems.find(e => e.id === id);
        //保存済みの内訳を合計し、入金予定額と一致しているか調べる
        if (column == 'item_type' || column == 'price'){
          let calculate_sum = this.calculateSum()
          this.alreadyInputSum = calculate_sum[0]
          this.inputTotalOver = calculate_sum[1]
        }
        //Saveボタンが押せる状態か判断する
        if (column == 'item_type' || column == 'price' ||  column == 'order_id' || column == 'request_num'){
          this.setActiveBtns(child_item)
          if(column == 'item_type'){
            this.setIsDisabled(child_item)
          }
        }
        //フォームの値の正当性をチェック
        if (column == 'item_type' || column == 'price' || column == 'request_num' || column == 'complete_request'){
          this.setValueCheck(child_item)
        }
      },
      showUserSearchModal(index) {
        this.userSearch.modalInstance = $(this.$refs.userSearchModal).modal({keyboard: false, show:true});
        const $modalBody = $('.modal-body', this.$refs.userSearchModal)
        $modalBody.height($modalBody.height() + 1)
      },
      showOrderSearchModal(child_id) {
        this.orderSearch.modalInstance = $(this.$refs.orderSearchModal).modal({keyboard: false, show:true});
        const $modalBody = $('.modal-body', this.$refs.orderSearchModal)
        $modalBody.height($modalBody.height() + 1)
        //入力対象のid
        this.orderSearchTarget = child_id
      },

      hideUserSearchModal() {
        if (this.userSearch.modalInstance){
          this.userSearch.modalInstance.modal('hide');
        }
      },
      hideOrderSearchModal() {
        if (this.orderSearch.modalInstance){
          this.orderSearch.modalInstance.modal('hide');
        }
      },

      onClickChooseUser: function(userId){
        this.hideUserSearchModal();
      },
      onClickChooseOrder: function(orderId,RequestNum,CompleteRequest,Price,AmendBlId){
        this.hideOrderSearchModal();
        //対象のフォームを探して、order_idをセット
        var child_item = this.childItems.find(e => e.id === this.orderSearchTarget);
        child_item['order_id'] = orderId;
        child_item['request_num'] = RequestNum;
        child_item['complete_request'] = CompleteRequest;
        if (this.unarrived.currency == "yen"){
          child_item['fee_jpy'] = Price;
        }else if(this.unarrived.currency == "dollar"){
          child_item['fee_usd'] = Price;
        }

        //typeを予測入力
        if (AmendBlId == ''){
          child_item['item_type'] = "繰越割当";
          child_item['amend_bl_id'] = null
        }else{
          child_item['item_type'] = "Amend B/L繰越割当";
          child_item['amend_bl_id'] = AmendBlId
        }
        //フォームの活性非活性を判定
        this.setIsDisabled(child_item)

        let calculate_sum = this.calculateSum()
        this.alreadyInputSum = calculate_sum[0]
        this.inputTotalOver = calculate_sum[1]
        //saveボタンが押せる状態か判定
        this.setActiveBtns(child_item)
      },
      onClickUserSearch: async function (){
        const result = await axios.get(
            '/admin/api/search_user2.json',
            {
              params: {
                q: this.userSearch.q
              },
              paramsSerializer: function(params) {
                return qs.stringify(params, {arrayFormat: 'brackets'})
              }
            }
        )
        this.userSearch.users = result.data;
      },
      onClickOrderSearch: async function (){
        const result = await axios.get(
            `/admin/request_payment_confirmations/api/${this.unarrived.id}/search_order.json`,
            {
              params: {
                q: this.orderSearch.q
              },
              paramsSerializer: function(params) {
                return qs.stringify(params, {arrayFormat: 'brackets'})
              }
            }
        )
        this.orderSearch.orders = result.data;
      },

      //内訳レコードの追加
      addChildItem: async function (){
        return new Promise(async (resolve, reject) => {
          try{
            const result = await axios.post(
              `/admin/request_payment_confirmations/create_item/${this.unarrived.id}`,
              {
                // message_template:{
                //   // title: title,
                //   // message: message
                // }
              },
              {
                headers:{
                  "X-Requested-With": "XMLHttpRequest",
                  "X-CSRF-Token": this.csrfToken
                }
              }
            )
            this.setChildItems()
            resolve();
          }catch (error) {
            reject(error)
          }
        })
      },
      //内訳レコードの保存
      updateChildItem: async function (id){
        //対象のフォームを探す
        var child_item = this.childItems.find(e => e.id === id);
        return new Promise(async (resolve, reject) => {
          try{
            const result = await axios.post(
              `/admin/request_payment_confirmations/child_update/${this.unarrived.id}`,
              {
                child_id: id,
                item_type: child_item.item_type,
                invoice_code: child_item.invoice_code,
                fee_usd: child_item.fee_usd,
                fee_jpy: child_item.fee_jpy,
                order_id: child_item.order_id,
                request_num: child_item.request_num,
                complete_request: child_item.complete_request,
                memo: child_item.memo,
                amend_bl_id: child_item.amend_bl_id,
              },
              {
                headers:{
                  "X-Requested-With": "XMLHttpRequest",
                  "X-CSRF-Token": this.csrfToken
                }
              }
            )
            if (result.data.updated ==true){
              this.errors[`${child_item.id}`]=""
              this.setChildItems()
              this.updateSuccess = child_item.id;
              setTimeout(() => {
                  this.updateSuccess = false;
              }, 2);

            }else{
              this.errors[`${child_item.id}`]=""
              this.setChildItems()

              this.errors[`${child_item.id}`] = result.data.errors_message
            }
            resolve();
          }catch (error) {
            reject(error)
          }
        })
      },
      //内訳レコードの削除
      deleteChildItem: async function (id){


        if(!confirm('delete it?')){
          return false;
            } else {
            return new Promise(async (resolve, reject) => {
              try{
                const result = await axios.post(
                  `/admin/request_payment_confirmations/child_destroy/${this.unarrived.id}`,
                  {
                    child_id: id
                  },
                  {
                    headers:{
                      "X-Requested-With": "XMLHttpRequest",
                      "X-CSRF-Token": this.csrfToken
                    }
                  }
                )
                this.setChildItems(true)
                this.setRequestConfirmationBtn()
                resolve();
              }catch (error) {
                reject(error)
              }
            })
        }
      },
      //小数点の位置を調べる
      getDotPosition(value){
        // 数値のままだと操作できないので文字列化する
        var strVal = String(value);
        var dotPosition = 0;
          //小数点が存在するか確認
          if(strVal.lastIndexOf('.') !== -1){
            // 小数点があったら位置を取得
            dotPosition = (strVal.length-1) - strVal.lastIndexOf('.');
          }
          return dotPosition;
      },
    },
  })
});

document.addEventListener('turbolinks:visit', () => {
    // if (subscription) {
    //     consumer.subscriptions.remove(subscription)
    // }

    // if ($notification){
    //     $('.toast', $notification).toast('dispose');
    //     $notification.remove();
    // }

    // $notification = null;
});