import Vue from 'vue/dist/vue.esm'
import TurbolinksAdapter from 'vue-turbolinks';
import axios from 'axios';
import { throttle } from 'lodash';

Vue.use(TurbolinksAdapter);

document.addEventListener('turbolinks:load', () => {
  const element = document.querySelector("#faq-chatbot");
  if (!element) {
    return
  }

  const app = new Vue({
    el: "#faq-chatbot",

    data: {
      isFaqChatbotOpen: false,
      userMessage: '',
      disableInput: false,
      isLoading: false,
      messages: [{ text: 'Hello, kindly enter your question', msgClass: 'faq-server-message' }], // , e.g., "What does FOB mean?"
      // INFO: categoriesを修正（質問追加や順番変更など）の場合は、self.quick_question_answersメソッドも修正すること
      categories: {
        'shipping': [
          'How long to receive the car?',
          'Why are there so many changes and delays?',
        ],

        'order': [
          'How to buy a car?',
          'What does FOB means?',
          'What does CIF means?',
        ],

        'register': [
          'How to register?',
          'Is there any membership fee?'
        ],

        'payment': [
          'How to pay?',
          'What currencies can I use?',
          'Can I pay in installments?',
          'What is a TT Copy? Where can I get it?',
        ],

        'other': [
          'Is there an English manual?',
          'What kind of company SBI Motor Japan?',
        ],
      },
      activeCategory: null,
      messagesContainerHeight: 0,

      csrfToken: '',
    },

    watch: {
      activeCategory(newVal, oldVal) {
        let shouldAdjustHeight = false;
        // カテゴリーが選択された場合、メッセージコンテナの高さを調整
        if (newVal !== null) {
          shouldAdjustHeight = true;
        }

        this.handleMessageContainerHeight(shouldAdjustHeight);
      }
    },

    methods: {
      handleMessageContainerHeight(shouldAdjustHeight) {
        this.$nextTick(() => {
          const messagesContainer = this.$el.querySelector('.faq-messages');
          if (shouldAdjustHeight) {
            const quickQuestionsContainer = this.$el.querySelector('.faq-quick-questions');
            const quickQuestionsHeight = quickQuestionsContainer.offsetHeight;
            // const scrollbarHeight = quickQuestionsContainer.offsetHeight - quickQuestionsContainer.clientHeight;
            messagesContainer.style.height = `${this.messagesContainerHeight - (quickQuestionsHeight + 10)}px`; // 初期値から、クイック質問の高さを引く
          } else {
            messagesContainer.style.height = this.messagesContainerHeight + 'px'; // 初期値に戻す
          }
        });
      },

      toggleQuestions(category) {
        // 同じカテゴリーを選択した場合、null (非表示させるため)
        this.activeCategory = this.activeCategory === category ? null : category;
      },

      toggleFaqChatbotDisplay: function() {
        this.isFaqChatbotOpen = !this.isFaqChatbotOpen;
        this.activeCategory = null; // カテゴリーを未選択状態にする
        this.$nextTick(() => {
          if (this.isFaqChatbotOpen) {
            // チャットボットを開いた時に、メッセージコンテナの高さを取得
            this.messagesContainerHeight = this.$el.querySelector('.faq-messages').offsetHeight;
          }
        });
      },

      /* ユーザー入力メッセージの転送 */
      sendMessage: async function() {
        let message = this.userMessage.trim();
        if (!message) return;

        try {
          message = this.stripTagsFromMessage(message);
          this.userMessage = ''; // 入力欄初期化
          await this.processUserMessage(message);
        } catch (error) {
          // Handle error
          console.error(error);
          let errorMsg = "We encountered an error while processing your request. Please kindly try again after a short while."
          this.displayMessage(errorMsg, 'server');
        } finally {
        }
      },

      /* テンプレート質問の転送 */
      selectQuickQuestion: async function(quickMessage, index, category = null) {
        try {
          await this.processUserMessage(quickMessage, index, category);
        } catch (error) {
          // Handle error
          console.error(error);
          let errorMsg = "We encountered an error while processing your request. Please kindly try again after a short while."
          this.displayMessage(errorMsg, 'server');
        } finally {
        }
      },

      /* メッセージ描画 */
      displayMessage: function(message, sender) {
        let senderClass= sender === 'user' ? 'faq-user-message' : 'faq-server-message';

        this.messages.push({ text: message, msgClass: senderClass });

        this.$nextTick(() => { // DOM更新後に実行
          const messagesContainer = this.$el.querySelector('.faq-messages');
          messagesContainer.scrollTop = messagesContainer.scrollHeight; // スクロールを一番下に
        });
      },

      processUserMessage: async function(message, index = null, category = null) {
        this.disableInput = true;
        this.isLoading = true;

        this.displayMessage(message, 'user');

        // TODO: レスポンスを待っている間は、新しいユーザー入力を防ぐ
        try {
          // apis_controllerへのリクエスト&レスポンス
          const response = await axios.post(
            '/api/faq_chatbot_process',
            {
              message: message,
              quick_question_index: index,
              quick_question_category: category,
            },
            {
              headers:{
                "X-Requested-With": "XMLHttpRequest",
                "X-CSRF-Token": this.csrfToken
              }
            }
          );

          // レスポンスが存在するかチェック
          if (response && response.data && response.data.msg) {
            // NOTE: 他の検証必要?
            this.displayMessage(response.data.msg, 'server');
          } else {
            console.error('Invalid response received');
            let errorMsg = "We encountered an error while processing your request. Please kindly try again after a short while."
            this.displayMessage(errorMsg, 'server');
          }
        } catch (error) {
          console.error('Error while processing:', error);
          let errorMsg = "We encountered an error while processing your request. Please kindly try again after a short while."
          this.displayMessage(errorMsg, 'server');
        } finally {
          this.disableInput = false;
          this.isLoading = false;
        }
      },

      /* メッセージからHTMLタグ(< ... >)を削除 */
      stripTagsFromMessage: function(message) {
        return message.replace(/(<([^>]+)>)/gi, "");
      },
    },

    // Lifecycle hooks
    created: function() {
      this.sendMessage = throttle(this.sendMessage, 2000); // 2秒で1回のみ実行
      this.selectQuickQuestion = throttle(this.selectQuickQuestion, 2000); // 2秒で1回のみ実行
    },
    mounted: function() {
      this.csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    },
  });
});
