import consumer from "../../channels/consumer"
import {EventEmitter} from "events";

export const appearingEventEmitter = new EventEmitter();

const appearances = {}

consumer.subscriptions.create("AppearingChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    // console.log('appearing-connected')
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
    // console.log('appearing-disconnected')
  },
  rejected() {
    // Called when the subscription has been terminated by the server
    // console.log('appearing-rejected')
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    // console.log("appearing-received")
    // console.log(data)
    appearances[data['user']['id']] = {
      type: data['type'],
      user: data['user'],
      isOnline: data['is_online'],
    };

    appearingEventEmitter.emit('changed', appearances)
    // console.log(appearances)
  },
});
