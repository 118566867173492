import Vue from 'vue/dist/vue.esm'
import axios from 'axios';
import qs from 'qs';

import TurbolinksAdapter from 'vue-turbolinks';
Vue.use(TurbolinksAdapter);

document.addEventListener('turbolinks:load', () => {
    const selector = '.paypal-invoice-index';
    const elements = document.querySelectorAll(selector);
    if (elements.length === 0) {
        return
    }
    
    new Vue({
      el: ".paypal-invoice-index",
      data: function (){
        return {
          userSearch: {
            modalInstance: null,
            users: [],
            q: {},
          },
          selectCurrency: {
            modalInstance: null
          },
          setParameters:{
            user_id:null,
            username:""
          },
          inputDirectPaypal: {
            modalInstance: null
          },
          saveProcessing:{
            modalInstance: null
          },
          err:"",
          info_link:"",
          processing:false,
          paypalTransaction:{
            id:null,
          },
          paypalConfirmation:false,
        }
      },
      beforeDestroy(){//インスタンスが破棄される前に
        if (this.userSearch.modalInstance){
          $('.modal-backdrop').remove();//モーダルを表示したままブラウザの戻るボタンや進むボタンを押した際に操作できなくなる問題対応
          this.userSearch.modalInstance.modal('dispose');
        }
      },
      methods: {
        showUserSearchModal() {
          this.userSearch.modalInstance = $(this.$refs.userSearchModal).modal({keyboard: false, show:true});
          const $modalBody = $('.modal-body', this.$refs.userSearchModal)
          $modalBody.height($modalBody.height() + 1)
        },
        onClickUserSearch: async function (){
          const result = await axios.get(
              '/admin/api/search_user2.json',
              {
                params: {
                  q: this.userSearch.q
                },
                paramsSerializer: function(params) {
                  return qs.stringify(params, {arrayFormat: 'brackets'})
                }
              }
          )
          this.userSearch.users = result.data;
        },

        //繰越金のユーザーを選ぶ
        onClickChooseUser: function(userId,userName){
          // ユーザー検索のモーダルを閉じる
          if (this.userSearch.modalInstance){
            this.userSearch.modalInstance.modal('hide');
          }
          //通貨選択モーダルを開く
          this.selectCurrency.modalInstance = $(this.$refs.selectCurrencyModal).modal({keyboard: false, show:true});
          const $modalBody = $('.modal-body', this.$refs.selectCurrencyModal)
          $modalBody.height($modalBody.height() + 1)
          
          this.setParameters.user_id = userId
          this.setParameters.username = userName
        },

        //paypal情報入力モーダルを開く
        showGetPaypalModal: function(){
          this.paypalTransaction= {}
          this.paypalConfirmation = false
          this.err = ""
          this.info_link = ""
          this.inputDirectPaypal.modalInstance = $(this.$refs.inputDirectPaypalModal).modal({keyboard: false, show:true});
          const $modalBody = $('.modal-body', this.$refs.inputDirectPaypalModal)
          $modalBody.height($modalBody.height() + 1)
        },
        hideGetPaypalModall: function() {
          if (this.inputDirectPaypal.modalInstance){
            this.inputDirectPaypal.modalInstance.modal('hide');
          }
        },
        getPaypal: function(){
          this.processing = true
          this.err = ""
          this.info_link = ""
          return new Promise(async (resolve, reject) => {
            try{
              const response = await axios.get(`/admin/paypal_invoices/get_paypal_data/${this.paypalTransaction.id}`)
              if(response.data.error){
                this.err = response.data.error
                this.info_link = response.data.info_link
                this.processing = false
              }else{
                // エラーがない場合
                this.paypalTransaction = response.data.paypal_transaction
                //this.info_link = response.data.info_link
                this.processing = false
                this.paypalConfirmation = true
              }
              resolve();
            }catch (error) {
              reject(error)
            }
          })
        },
        openSaveProcessingModal:function(){
          // フォームのモーダルを閉じる
          if (this.inputDirectPaypal.modalInstance){
            this.inputDirectPaypal.modalInstance.modal('hide');
          }
          //処理中モーダルを開く
          this.saveProcessing.modalInstance = $(this.$refs.saveProcessingModal).modal({keyboard: false, show:true});
          const $modalBody = $('.modal-body', this.$refs.selectCurrencyModal)
          $modalBody.height($modalBody.height() + 1)
        },
      },
  })
});

document.addEventListener('turbolinks:visit', () => {
});