import Vue from 'vue/dist/vue.esm'
import axios from 'axios';
import qs from 'qs';

import TurbolinksAdapter from 'vue-turbolinks';
Vue.use(TurbolinksAdapter);

document.addEventListener('turbolinks:load', () => {
  const element = document.getElementById('admin-review-form-vue');
  if (element === null) {
    return
  }

  Vue.directive('init', {
    bind: function (el, binding, vnode) {
      vnode.context[binding.arg] = binding.value
    }
  })


  new Vue({
    el: '#admin-review-form-vue',
    data: function (){
      return {
        user_id: "",
        userSearch: {
          modalInstance: null,
          users: [],
          q: {}
        },
      }
    },
    beforeDestroy(){//インスタンスが破棄される前に
      if (this.userSearch.modalInstance){
        $('.modal-backdrop').remove();//モーダルを表示したままブラウザの戻るボタンや進むボタンを押した際に操作できなくなる問題対応
        this.userSearch.modalInstance.modal('dispose');
      }
    },
    methods: {
      showUserSearchModal() {
        this.userSearch.modalInstance = $(this.$refs.userSearchModal).modal({keyboard: false, show:true});
        const $modalBody = $('.modal-body', this.$refs.userSearchModal)
        $modalBody.height($modalBody.height() + 1)
      },
      hideUserSearchModal() {
        if (this.userSearch.modalInstance){
          this.userSearch.modalInstance.modal('hide');
        }
      },
      onClickChooseUser: function(userId){
        this.user_id = userId
        this.hideUserSearchModal();
      },
      onClickUserSearch: async function (){
        const result = await axios.get(
            '/admin/api/search_user2.json',
            {
              params: {
                q: this.userSearch.q
              },
              paramsSerializer: function(params) {
                return qs.stringify(params, {arrayFormat: 'brackets'})
              }
            }
        )
        this.userSearch.users = result.data;
      },
    },
  })
});