
document.addEventListener("turbolinks:load", function(){
//document.addEventListener('turbolinks:load', () => {
  const selector = '.crop_image_use';
  const elements = document.querySelectorAll(selector);
  if (elements.length === 0) {
    return
  }

  //https://qiita.com/digdugwell/items/a4723c3e476ccd6828a8

  //------------------------------------------------------------------


  $(function() {
    //新規か編集かの判定
    $('#new-item').click(function(){
      var type = "new-item"
      document.getElementById("image_save_type").value = type
    });
    $('#edit-item').click(function(){
      var type = "edit-item"
      document.getElementById("image_save_type").value =type
    });
    
    $('#trim_img_uploder').click(function(){
      $(this).val('');
      document.getElementById("prev_img").style.display = '';
      document.getElementById("cropped_canvas").style.display = 'none';
    });
  
    $('#trim_img_uploder').change(function(){
      document.getElementById("prev_img").style.display = 'none';
      document.getElementById("cropped_canvas").style.display = '';
      $('#modal_area').fadeIn();
    });

    //------------------------------------------------------------------
    let cropper = null;
    const scaled_width = 800;
    //(app/uploaders/exhibit_image_uploader.rb)のresize_to_fitと値を揃えておく事

    const aspect_numerator = parseFloat(document.getElementById("aspect_numerator").value)
    const aspect_denominator = parseFloat(document.getElementById("aspect_denominator").value)
    const crop_aspect_ratio = aspect_denominator / aspect_numerator;

    //新規画像アップロード------------------------------------------------------------------
    const crop_image = function (e) {
      const files = e.target.files;
      if (files.length == 0) {
        return;
      }
      let file = files[0];
      let image = new Image();
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        image.src = e.target.result;
        image.onload = function () {

          //------------------------------------------------------------------
          let scale = scaled_width / image.width;
          const canvas = document.getElementById("source_canvas");
          canvas.width = image.width * scale;
          canvas.height = image.height * scale; 

          let ctx = canvas.getContext("2d");
          ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, canvas.width, canvas.height);

          if (cropper != null) {
            cropper.destroy();
          }

          //------------------------------------------------------------------
          cropper = new Cropper(canvas,
            {
              aspectRatio: crop_aspect_ratio,
              data: {width: canvas.width, height: canvas.width * crop_aspect_ratio},
              crop: function (event) {
                document.getElementById("image_x").value = event.detail.x;
                document.getElementById("image_y").value = event.detail.y;
                document.getElementById("image_w").value = event.detail.width;
                document.getElementById("image_h").value = event.detail.height;
              }
            }
          );

          //------------------------------------------------------------------
          $('#close_button,#modal_back_area').click(function(){
            const cropped_canvas = document.getElementById("cropped_canvas");
            let ctx = cropped_canvas.getContext("2d");
            let cropped_image_width = image.height * crop_aspect_ratio;
            cropped_canvas.width = cropped_image_width * scale;
            cropped_canvas.height = image.height * scale;
            //cropped_canvas.width = 700;
            //cropped_canvas.height = 700;          

            let image_x = document.getElementById("image_x").value;
            let image_y = document.getElementById("image_y").value;
            let image_w = document.getElementById("image_w").value;
            let image_h = document.getElementById("image_h").value;
            ctx.drawImage(image, image_x/scale, image_y/scale, image_w/scale , image_h/scale ,0 ,0 , cropped_canvas.width ,cropped_canvas.height);
            $('#modal_area').fadeOut();
          });
        }
      }
    }
    //既存画像編集------------------------------------------------------------------
    //const resize_crop_image = function (e) {
    function resize_crop_image(){

      const canvas = document.getElementById("source_canvas_2");//モーダルの中のcanvas
        let imagePath = $("#origin_img").attr('src');//既存画像

        let image = new Image();
        image.src = imagePath;
      
        image.onload = function () {
          let scale = scaled_width / image.width;
          canvas.width = image.width * scale;
          canvas.height = image.height * scale; 
          const ctx = canvas.getContext("2d");
          ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, canvas.width, canvas.height);

          if (cropper != null) {
            cropper.destroy();
          }
          cropper = new Cropper(canvas,
            {
              aspectRatio: crop_aspect_ratio,
              data: {width: canvas.width, height: canvas.width * crop_aspect_ratio},
              crop: function (event) {
                document.getElementById("image_x").value = event.detail.x;
                document.getElementById("image_y").value = event.detail.y;
                document.getElementById("image_w").value = event.detail.width;
                document.getElementById("image_h").value = event.detail.height;
              }
            }
          );
          $('#close_button,#modal_back_area').click(function(){
            const cropped_canvas = document.getElementById("cropped_canvas");
            let ctx = cropped_canvas.getContext("2d");
            let cropped_image_width = image.height * crop_aspect_ratio;
            cropped_canvas.width = cropped_image_width * scale;
            cropped_canvas.height = image.height * scale;
    
            let image_x = document.getElementById("image_x").value;
            let image_y = document.getElementById("image_y").value;
            let image_w = document.getElementById("image_w").value;
            let image_h = document.getElementById("image_h").value;
            ctx.drawImage(image, image_x/scale, image_y/scale, image_w/scale , image_h/scale ,0 ,0 , cropped_canvas.width ,cropped_canvas.height);

            document.getElementById("prev_img").style.display = 'none';
            document.getElementById("cropped_canvas").style.display = '';

          });
        }
    }
    // アップローダーに画像が設定されるとcrop_imageを設定
    const uploader = document.getElementById('trim_img_uploder');
    uploader.addEventListener('change', crop_image);

    // 既存画像編集ボタンを押下しモーダルを展開した状態で発動
    $('#exampleModal_2').on('shown.bs.modal', function (e) {
       resize_crop_image();
    })
  });
});

