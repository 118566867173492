import Vue from 'vue/dist/vue.esm'

import TurbolinksAdapter from 'vue-turbolinks';
Vue.use(TurbolinksAdapter);

document.addEventListener('turbolinks:load', () => {
  const element = document.getElementById('admin-csv-for-aggregates-vue');
  if (element === null) {
    return
  }

  new Vue({
    el: "#admin-csv-for-aggregates-vue",
    methods: {
      dateSelect: function(event){
        let date = event.target.value
        window.location.href = `/admin/csv_for_aggregates?date=${date}`
      },
    }
  })
});