import _, { isArray } from 'lodash'
import consumer from "../channels/consumer"

let subscription = null;
document.addEventListener('turbolinks:load', () => {

  const element = $(".real-bids");
  if (element === null) {
    return
  }

  const this_page_id = $('.pageid').text()

  // bid行の数
  const bid_row_length = $('.bid_row').length
      
  //連打対策
  var canAjax = true

  //当日の朝9時以降にCSVにて読み込まれた入札情報レコードは背景色が活性化される
  $('.imported_at').each(function(idx,ele){
    if (ele.innerText){
      //CSVインポート日時(UTCで記録されている)を日本時間に直す
      const date = new Date(ele.innerText)
      date.setHours(date.getHours() + 9)
      //本日9:00（日本時間）
      const today_nine_am = new Date()
      today_nine_am.setHours(9)
      today_nine_am.setMinutes(0)
      if (date >= today_nine_am){
        ele.parentElement.classList.add("table-warning")
      }
    } 
  })

  //collapse表示の制御
  //<tr>全体ではなく、collapse-availableクラスを付けた出力のみの<td>に属性追加することで、
  //入出力できる<td>(FinishPrice入力欄、Result入力欄)クリック時にcollapse表示が閉じてしまうことに対処しています。
  $("[class^='collapse-available']").each(function(i,ele){
    const class_num = ele.className.match(/\d+$/)[0]
    ele.setAttribute('data-toggle','collapse')
    ele.setAttribute('data-target',`.multicollapse${class_num}`)
    ele.setAttribute('aria-expanded','false')
    ele.setAttribute('aria-controls',`collapseNotes_${class_num}  collapseNotes_${class_num}_`)
    
  })
 
  //CSVインポートがあった場合の通知
  //ActionCableで合図を受け取り、ページ更新を促す表示をする
  const subscription = consumer.subscriptions.create("RealBidsChannel", {
    connected(){
      //何もしない
    },
    disconnected(){
      //何もしない
    },
    received(data) {
      //debugger
      var bid_id = data['bid_id']
      
      switch (data['type']){
        //CSVインポート完了時
        case 'IMPORTED':
          ////toastを使った新着通知。
          //$('.new-bids-toast', notification).toast('show')
          //modalで実装。.modal()が反応しないので、modalをtoggleする隠しボタンを叩く方法を採用。
          $('#newBidsModalShow').click()
          $('#reload-page').removeClass('d-none')
          break

        //CSVインポート画面からbidが削除された時
        case 'DELETED':
          //対象のbid行をグレーアウトし、編集・更新不可に設定。
          console.log('Bid deleted.')
          const deleted_bid = $(`#row_${data['bid_id']}`)
          const deleted_bid_update_status = $(`#update_status_${bid_id}`) 
          const deleted_bid_modal_update_status = $(`#modal-update_status_${bid_id}`) 
          const deleted_bid_update_status_text = $(`#update_status_text_${bid_id}`)
          const deleted_bid_modal_update_status_text = $(`#modal-update_status_text_${bid_id}`)
          deleted_bid.removeClass('table-warning table-info')            
          deleted_bid.addClass('table-secondary')

          deleted_bid_update_status.addClass('spinner-border spinner-border-sm')
          deleted_bid_modal_update_status.addClass('spinner-border spinner-border-sm')
          deleted_bid_update_status_text.removeClass('border border-success border-warning')
          deleted_bid_modal_update_status_text.removeClass('border border-success border-warning')
          deleted_bid_update_status_text.text('OTHER ADMIN EDITING...')
          deleted_bid_modal_update_status_text.text('OTHER ADMIN EDITING...')
          
          setTimeout(function(){
            deleted_bid_update_status.removeClass('spinner-border spinner-border-sm')
            deleted_bid_modal_update_status.removeClass('spinner-border spinner-border-sm')
            deleted_bid_update_status_text.text('DELETED')
            deleted_bid_modal_update_status_text.text('DELETED')
            deleted_bid_update_status_text.addClass('border border-dark')
            deleted_bid_modal_update_status_text.addClass('border border-dark')

            $(`#finish_price_${bid_id}, #modal-finish_price_${bid_id}`).prop('disabled', true)
            $(`#result_${bid_id}, #modal-result_${bid_id}`).prop('disabled', true)
            $(`#note_${bid_id}, #modal-note_${bid_id}`).prop('disabled', true)
            $(`#update_btn_${bid_id}, #modal-update_btn_${bid_id}`).prop('disabled', true)
            $(`#send_result_btn_${bid_id}, #modal-send_result_btn_${bid_id}`).prop('disabled', true)
            show_or_hide_create_car_btn(bid_id, false)
            
          }, 5*1000) //EDITINGの表示が終了してからDELETEDの表示に変わるよう時間調整（Promise使うか？）
          break

        //編集中の場合の表示切替
        case 'EDITING':
          console.log('Editing...')
          if(data['page_id'] == this_page_id) {
            //入力中なのが自分である場合はなにもしない。
            return;
          }
          const editing_bid = $(`#row_${bid_id}`)
          const editing_bid_update_status = $(`#update_status_${bid_id}`)
          const editing_bid_update_status_text = $(`#update_status_text_${bid_id}`)
          const editing_bid_modal_update_status = $(`#modal-update_status_${bid_id}`)
          const editing_bid_modal_update_status_text = $(`#modal-update_status_text_${bid_id}`)
          editing_bid_update_status_text.removeClass('border border-success border-warning')
          editing_bid_modal_update_status_text.removeClass('border border-success border-warning')
          
          //背景色を一時退避
          let bgcolor_warning = false
          if(editing_bid.hasClass('table-warning')){
            editing_bid.removeClass('table-warning')
            bgcolor_warning = true
          }
          //5秒間だけ背景色を変更&読込中マークを表示
          editing_bid.addClass('table-secondary')
          editing_bid_update_status.addClass('spinner-border spinner-border-sm')
          editing_bid_modal_update_status.addClass('spinner-border spinner-border-sm')
          editing_bid_update_status_text.text('OTHER ADMIN EDITING...')
          editing_bid_modal_update_status_text.text('OTHER ADMIN EDITING...')
          $(`#update_btn_${bid_id}, #modal-update_btn_${bid_id}`).prop('disabled', true)
          $(`#send_result_btn_${bid_id}, #modal-send_result_btn_${bid_id}`).prop('disabled', true)
            
          setTimeout(function(){
            editing_bid_update_status.removeClass('spinner-border spinner-border-sm')
            editing_bid_modal_update_status.removeClass('spinner-border spinner-border-sm')
            editing_bid.removeClass('table-secondary')
            editing_bid_update_status_text.text('')
            editing_bid_modal_update_status_text.text('')
            if(bgcolor_warning){editing_bid.addClass('table-warning')}
            $(`#update_btn_${bid_id}, #modal-update_btn_${bid_id}`).prop('disabled', false)
            $(`#send_result_btn_${bid_id}, #modal-send_result_btn_${bid_id}`).prop('disabled', false)
            
          }, 5*1000)
          break

        //bidを更新した場合
        case  'UPDATED' :
          console.log('Refleshed the bid.')
          const updated_bid_status = $(`#update_status_${bid_id}`)
          const updated_bid_modal_status = $(`#modal-update_status_${bid_id}`)
          updated_bid_status.addClass('spinner-border spinner-border-sm')
          updated_bid_modal_status.addClass('spinner-border spinner-border-sm')
          setTimeout(function(){
            const updated_bid = $(`#row_${bid_id}`)
            const updated_bid_status_text = $(`#update_status_text_${bid_id}`)
            const updated_bid_modal_status_text = $(`#modal-update_status_text_${bid_id}`)
            //値を更新 finish,result,note
            $(`#finish_price_${bid_id}, #modal-finish_price_${bid_id}`).val(data['finish_price'])
            $(`#result_${bid_id}, #modal-result_${bid_id}`).val(data['result'])
            $(`#note_${bid_id}, #modal-note_${bid_id}`).val(data['note'])
            //UPDATED表示
            updated_bid_status_text.removeClass('border border-warning')
            updated_bid_modal_status_text.removeClass('border border-warning')
            updated_bid_status_text.addClass('border border-success')
            updated_bid_modal_status_text.addClass('border border-success')
            //自分が更新した場合と他のAdminUserが更新した場合で表示を分ける
            if(data['page_id'] == this_page_id) {
               //更新したのが自分である場合
              updated_bid_status_text.text('UPDATED')
              updated_bid_modal_status_text.text('UPDATED')
            }else{
              //更新したのが他のAdminUserである場合
              updated_bid_status_text.text('UPDATED BY OTHER ADMIN')
              updated_bid_modal_status_text.text('UPDATED BY OTHER ADMIN')
            }
            //背景色を変更
            updated_bid.addClass('table-info')
            updated_bid.removeClass('table-warning')
            updated_bid.removeClass('table-secondary')
            
            show_or_hide_create_car_btn(bid_id, data['result'])

            updated_bid_status.removeClass('spinner-border spinner-border-sm')
            updated_bid_modal_status.removeClass('spinner-border spinner-border-sm')

          }, 5*1000) //EDITINGの表示が終了してからUPDATEDの表示に変わるよう時間調整（Promise使うか？）

          break   

        }
    },
    
    editing(bid_id, this_page_id){
      this.perform('editing', {bid_id: bid_id, page_id: this_page_id})
    },
    updated(bid_id,finish_price,result,note, this_page_id){
      this.perform('updated', {bid_id: bid_id, finish_price: finish_price, result: result, note: note, page_id: this_page_id})
    }
  })

  //編集中の処理
  //キー入力のたびに perform を実行するとサーバーに負荷がかかるため、 lodash ライブラリの throttle メソッドで軽減する
  //キー入力中は2秒に1度だけ反応させる
  const handleEditingEvent = function (bid_id){subscription.editing(bid_id, this_page_id)}//本来やりたい処理の関数オブジェクト
  const throttledHandleEditingEvent =  _.throttle(handleEditingEvent, 2000)//2秒に1度だけ反応させるようにした関数オブジェクトを生成する
  $(document).on('input', "[id*='finish_price_'], [id*='note_']", function(){
    const bid_id = this.id.match(/\d+$/)[0]
    throttledHandleEditingEvent(bid_id)
  })
  $(document).on('change', "[id*='result_']", function(){
    const bid_id = this.id.match(/\d+$/)[0]
    subscription.editing(bid_id, this_page_id)
  })
   
  //[UPDATE]ボタン押下時の処理
  //モーダルの入力欄と一覧画面の入力欄は連動しているので、モーダル側で[UPDATE]押下した場合も、一覧画面の入力値を取得
  $(document).on('click', "[id*='update_btn_']", function(){
    //連打対策
    if(!canAjax) {
      return
    }
    canAjax = false
    const bid_id = this.id.match(/\d+$/)[0]
    const update_status = $(`#update_status_${bid_id}`)
    const modal_update_status = $(`#modal-update_status_${bid_id}`)
    const update_status_text = $(`#update_status_text_${bid_id}`)
    const modal_update_status_text = $(`#modal-update_status_text_${bid_id}`)
    const finish_price = $(`#finish_price_${bid_id}`).val()
    const result = $(`#result_${bid_id}`).val()
    const note = $(`#note_${bid_id}`).val()
    if(finish_price){
      update_status_text.text('')
      modal_update_status_text.text('')
      update_status_text.removeClass('border border-warning')
      modal_update_status_text.removeClass('border border-warning')
      update_status.addClass('spinner-border spinner-border-sm')
      modal_update_status.addClass('spinner-border spinner-border-sm')
      $.ajax({
        type: "POST",
        url: "/admin/bids/update_real_bid",
        data: { bid: { id: bid_id, finish: finish_price, result: result, note: note} },
        beforeSend: function(xhr){
          xhr.setRequestHeader("X-CSRF-Token", $('meta[name="csrf-token"]').attr('content'))
        }
      }).done(function(json){
        if(json.status){
          subscription.updated(bid_id,finish_price,result,note, this_page_id)
        }else if(json.error){
          update_status_text.addClass('border border-warning')
          modal_update_status_text.addClass('border border-warning')
          update_status_text.text(`${json.error}`)
          modal_update_status_text.text(`${json.error}`)
        }else{
          update_status_text.addClass('border border-warning')
          modal_update_status_text.addClass('border border-warning')
          update_status_text.text('ERROR')
          modal_update_status_text.text('ERROR')
        }

        console.log("bid updated")
      }).fail(function(){
        // エラーの場合処理
        console.log('bid updated error')
      }).always(function() {
        //連打対策
        canAjax = true
        update_status.removeClass('spinner-border spinner-border-sm')
        modal_update_status.removeClass('spinner-border spinner-border-sm')
        
      });
    }
  })

  //車両新規作成ボタンの表示切替
  //[UPDATE]ボタンの結果、resultがsuccessの場合は表示、それ以外の場合は非表示にする
  function show_or_hide_create_car_btn(num, result){
    let lot = ''
    let auction_venue = ''
    let auction_date = ''
    let stockist = ''
    $(`#lot_${num}`).text() == '--' ? lot = '' : lot = $(`#lot_${num}`).text()
    $(`#auction_venue_name_jp_${num}`).text() == '--' ? auction_venue = '' : auction_venue = $(`#auction_venue_name_jp_${num}`).text()
    $(`#auction_date_${num}`).text() == '--' ? auction_date = '' : auction_date = $(`#auction_date_${num}`).text()
    stockist = $(`#current_admin_user_username`).text()
    let pre_contract_price = $(`#finish_price_${num}`).val()

    let $target = $(`#create_car_for_${num} span`)
    $target.addClass('d-none');
    if(result=="success"){
      $target.removeClass('d-none')
      $target.find('a').attr('href',`/admin/cars/new?auction_number=${lot}&amp;auction_venue=${auction_venue}&amp;bid_id=${num}&amp;locale=en&amp;pre_contract_price=${pre_contract_price}&amp;purchase_date=${auction_date}&amp;stockist=${stockist}`)
      $target.removeClass('d-none');
    }
  }

  //モーダルの表示部にbidの値を設定
  // モーダル情報生成、jsソート機能を考慮し、document記述を追加
  // $(document).on("click", ".js-exhibitImageButton", function(){
  $('.exhibitImageModal').on('show.bs.modal', function(event){
  
    // 次へ、戻るボタン初期化
    reset_button();
    // モーダル用生成画像がある場合は削除
    reset_img();

    // テキスト初期化
    $("#uss-message").text("")
    
    $(this).find('.update_status_text').text('')
    $(this).find('.update_status_text').removeClass('border border-warning border-success')
    

    // モーダル内の要素の一部にidを設定
    var datas = event.relatedTarget.dataset
    var bid_id = datas.bid_id
    var exhibit_id = datas.exhibit_id
    var click_element_num = event.relatedTarget.parentNode.parentNode.dataset.bid_row_index
    
    $(this).find('.finish_price').attr('id', `modal-finish_price_${bid_id}`)
    $(this).find('.note1').attr('id', `modal-note_${bid_id}`)
    $(this).find('.result').attr('id', `modal-result_${bid_id}`)
    $(this).find('.update_btn').attr('id', `modal-update_btn_${bid_id}`)
    $(this).find('.update_status').attr('id', `modal-update_status_${bid_id}`)
    $(this).find('.update_status_text').attr('id', `modal-update_status_text_${bid_id}`)

    // クリック時に押下されたtr要素の番号
    $('input:hidden[name="click_num"]').val(click_element_num)
    
    // 一覧表示（背景画面）のbid_rowから各項目の値を取得
    var user = $(this).parent().find(`#user_${bid_id}`).text()
    var auction = $(this).parent().find(`#auction_venue_name_en_${bid_id}`).text()
    var lot  = $(this).parent().find(`#lot_${bid_id}`).text()
    var car_model  = $(this).parent().find(`#car_model_${bid_id}`).text()
    var model_code = $(this).parent().find(`#model_code_${bid_id}`).text()
    var grade = $(this).parent().find(`#grade_${bid_id}`).text()
    var mileage = $(this).parent().find(`#mileage_${bid_id}`).text()
    var rate = $(this).parent().find(`#rate_${bid_id}`).text()
    var start = $(this).parent().find(`#start_${bid_id}`).text()
    var price = $(this).parent().find(`#price_${bid_id}`).text()
    var auction_fee = $(this).parent().find(`#auction_fee_${bid_id}`).text()
    var country = $(this).parent().find(`#country_${bid_id}`).text()
    var finish_price = $(this).parent().find(`#finish_price_${bid_id}`)[0].value
    var result = $(this).parent().find(`#result_${bid_id}`)[0].selectedIndex
    var auction_date = $(this).parent().find(`#auction_date_${bid_id}`).text()
    var admin_user = $(this).parent().find(`#admin_user_${bid_id}`).text()
    var note = $(this).parent().find(`#note_${bid_id}`)[0].value
    var update_status_text = $(this).parent().find(`#update_status_text_${bid_id}`).text()
    
    // 取得した情報をモーダルへ反映
    $("#modal-user").text(user)
    $("#modal-auction").text(auction) 
    $("#modal-lot").text(lot)
    $("#modal-car_model").text(car_model)
    $("#modal-model_code").text(model_code)
    $("#modal-grade").text(grade)
    $("#modal-mileage").text(mileage)
    $("#modal-rate").text(rate)
    $("#modal-start").text(start)
    $("#modal-price").text(price)
    $("#modal-auction_fee").text(auction_fee)
    $("#modal-country").text(country)
    $("[id^='modal-finish_price_']").val(finish_price)
    $("[id^='modal-result_']")[0].selectedIndex = result
    $("#modal-auction_date").text(auction_date)
    $("#modal-admin_user").text(admin_user)
    $("[id^='modal-note_']").val(note)
    $("[id^='modal-update_status_text_']").text(update_status_text)
    if(update_status_text.includes('UPDATED')){
      $("[id^='modal-update_status_text_']").addClass('border border-success')
    }else if(update_status_text.includes('ERROR') || update_status_text.includes('error')){
      $("[id^='modal-update_status_text_']").addClass('border border-warning')
    }else if(update_status_text.includes('DELETED')){
      $("[id^='modal-update_status_text_']").addClass('border border-dark')
    }    

    var uss_check = auction.indexOf("USS")
    if(uss_check === 0){
      $("#uss-message").text("USS車両のため、画像取得をスキップしました")
      var uss_flg = true
    }else{
      var uss_flg = false
    }

    // メイン画像挿入用htmlタグ,サイズ設定の無い画像の場合、w=320に指定
    var thumbnail = $("<img />" , {
      "class": "img-thumbnail col-12 w-80 h-80 modal-img-thumbnail",
      // src: img_ary[0] + "&w=320"
    })
    $("#put-images").append(thumbnail)


    // 画像取得api
    if(!uss_flg){
      get_fullsize_img(exhibit_id)
    }
    
    // 次へ、戻るボタン状態変更
    if (Number(click_element_num) + 1 === Number(bid_row_length)) {
      $(".modal-next").removeClass('btn-primary');
      $(".modal-next").addClass('btn-secondary');
    }
    if (Number(click_element_num) === 0) {
      $(".modal-back").removeClass('btn-primary');
      $(".modal-back").addClass('btn-secondary');
    }
  })


  //モーダルの入力欄を一覧画面の入力欄と連動させる
  //発火タイミングは、打鍵した時、および値が更新されたとき。
  //モーダル内の要素はjsによって後から生成されるので、$(document)を指定している
  $(document).on('keyup change', "[id^='modal-finish_price_']", function(event){
    var bid_id = $(this).attr('id').match(/\d+$/)[0]
    var new_value = $(this).val()
    $(`#finish_price_${bid_id}`).val(new_value)
  })
  $(document).on('change', "[id^='modal-result_']", function(event){
    var bid_id = $(this).attr('id').match(/\d+$/)[0]
    var new_value = $(this)[0].selectedIndex
    $(`#result_${bid_id}`)[0].selectedIndex = new_value
  })
  $(document).on('keyup change', "[id^='modal-note_']", function(event){
    var bid_id = $(this).attr('id').match(/\d+$/)[0]
    var new_value = $(this).val()
    $(`#note_${bid_id}`).val(new_value)
  })

  // モーダル用画像切り替え処理
  $(document).on("click", ".modal-img-change", function(){
    var src = $(this).attr('src');
    $(".modal-img-thumbnail").attr("src",src);
    return false;
  })

  // 次へボタン処理
  $(function(){
    $('.modal-next').click(function() {
      var tr_position = $('input:hidden[name="click_num"]').val()
      var tr_length = Number(bid_row_length)
      var next_index_num = Number(tr_position) + 1
      // 要素数最大値より大きい場合は取得しない
      if(tr_length !== next_index_num){
        get_car_exhibit_info(next_index_num)
      }
    })
  })

  // 戻るボタン処理
  $(function(){
    $('.modal-back').click(function() {
      var tr_position = $('input:hidden[name="click_num"]').val()
      var back_index_num = Number(tr_position) - 1
      // 要素数最小値より小さい場合は取得しない
      if(back_index_num >= 0){
        get_car_exhibit_info(back_index_num)
      }
    })
  })
  // 次へ、戻るボタン情報取得処理
  function get_car_exhibit_info(index_num){
    // 次へ、戻るボタン初期化
    reset_button();
    // モーダル用生成画像がある場合は削除
    reset_img();
    // メッセージ初期化
    $("#uss-message").text("")
    
    // 対象bid_rowを取得
    var target_tr = $(`[data-bid_row_index="${index_num}"]`)
    
    $('.exhibitImageModal').find('.update_status_text').text('')
    $('.exhibitImageModal').find('.update_status_text').removeClass('border border-warning border-success')
    
    var datas = target_tr.find('.js-exhibitImageButton').children()[0].dataset
    var exhibit_id = datas.exhibit_id
    var bid_id = datas.bid_id
    var click_element_num = index_num
    
    $('.exhibitImageModal').find('.finish_price').attr('id', `modal-finish_price_${bid_id}`)
    $('.exhibitImageModal').find('.note1').attr('id', `modal-note_${bid_id}`)
    $('.exhibitImageModal').find('.result').attr('id', `modal-result_${bid_id}`)
    $('.exhibitImageModal').find('.update_btn').attr('id', `modal-update_btn_${bid_id}`)
    $('.exhibitImageModal').find('.update_status').attr('id', `modal-update_status_${bid_id}`)
    $('.exhibitImageModal').find('.update_status_text').attr('id', `modal-update_status_text_${bid_id}`)

    // 一覧表示（背景画面）のbid_rowから各項目の値を取得
    var user = target_tr.find(`#user_${bid_id}`).text()
    var auction = target_tr.find(`#auction_venue_name_en_${bid_id}`).text()
    var lot  = target_tr.find(`#lot_${bid_id}`).text()
    var car_model  = target_tr.find(`#car_model_${bid_id}`).text()
    var model_code = target_tr.find(`#model_code_${bid_id}`).text()
    var grade = target_tr.find(`#grade_${bid_id}`).text()
    var mileage = target_tr.find(`#mileage_${bid_id}`).text()
    var rate = target_tr.find(`#rate_${bid_id}`).text()
    var start = target_tr.find(`#start_${bid_id}`).text()
    var price = target_tr.find(`#price_${bid_id}`).text()
    var auction_fee = target_tr.find(`#auction_fee_${bid_id}`).text()
    var country = target_tr.find(`#country_${bid_id}`).text()
    var finish_price = target_tr.find(`#finish_price_${bid_id}`)[0].value
    var result = target_tr.find(`#result_${bid_id}`)[0].selectedIndex
    var auction_date = target_tr.find(`#auction_date_${bid_id}`).text()
    var admin_user = target_tr.find(`#admin_user_${bid_id}`).text()
    var note = target_tr.parent().find(`#note_${bid_id}`)[0].value
    var update_status_text = target_tr.find(`#update_status_text_${bid_id}`).text()
    
    // 取得した情報をモーダルへ反映
    $("#modal-user").text(user)
    $("#modal-auction").text(auction) 
    $("#modal-lot").text(lot)
    $("#modal-car_model").text(car_model)
    $("#modal-model_code").text(model_code)
    $("#modal-grade").text(grade)
    $("#modal-mileage").text(mileage)
    $("#modal-rate").text(rate)
    $("#modal-start").text(start)
    $("#modal-price").text(price)
    $("#modal-auction_fee").text(auction_fee)
    $("#modal-country").text(country)
    $("[id^='modal-finish_price_']").val(finish_price)
    $("[id^='modal-result_']")[0].selectedIndex = result
    $("#modal-auction_date").text(auction_date)
    $("#modal-admin_user").text(admin_user)
    $("[id^='modal-note_']").val(note)
    $("[id^='modal-update_status_text_']").text(update_status_text)
    if(update_status_text.includes('UPDATED')){
      $("[id^='modal-update_status_text_']").addClass('border border-success')
    }else if(update_status_text.includes('ERROR') || update_status_text.includes('error')){
      $("[id^='modal-update_status_text_']").addClass('border border-warning')
    }else if(update_status_text.includes('DELETED')){
      $("[id^='modal-update_status_text_']").addClass('border border-dark')
    }

    // クリック時に押下されたtr要素の番号
    $('input:hidden[name="click_num"]').val(click_element_num)

    var uss_check = auction.indexOf("USS")
    if(uss_check === 0){
      $("#uss-message").text("USS車両のため、画像取得をスキップしました")
      var uss_flg = true
    }else{
      var uss_flg = false
    }

    // メイン画像挿入用htmlタグ,サイズ設定の無い画像の場合、w=320に指定
    var thumbnail = $("<img />" , {
      "class": "img-thumbnail col-12 w-80 h-80 modal-img-thumbnail",
      // src: img_ary[0] + "&w=320"
    })
    $("#put-images").append(thumbnail)


    // 画像取得api
    if(!uss_flg){
      get_fullsize_img(exhibit_id)
    }

    // 次へ、戻るボタン状態変更
    if (Number(index_num) + 1 === Number(bid_row_length)) {
      $(".modal-next").removeClass('btn-primary');
      $(".modal-next").addClass('btn-secondary');
    }
    if (Number(index_num) === 0) {
      $(".modal-back").removeClass('btn-primary');
      $(".modal-back").addClass('btn-secondary');
    }

  }
  

});

document.addEventListener('turbolinks:visit', () => {
  if (subscription) {
      consumer.subscriptions.remove(subscription)
  }

});

// 画像取得api非同期処理、画像の取得が完了し次第、DOMへ挿入
function get_fullsize_img(exhibit_id){
  $.ajax({
    type: "GET",
    url:  "/admin/bids/full_size_image_urls",
    data: {exhibit_id: exhibit_id, is_uss: false, is_stats: false}
  }).done(function(img_ary){
    // 画像URLの配列を受け取る
    if(!img_ary || typeof img_ary === 'undefined' || typeof img_ary === 'string' || img_ary.length === 0){
      console.log('error')
      return
    }
    // 画像初期化
    reset_img();
    // メイン画像挿入用htmlタグ
    $(".modal-img-thumbnail").remove();
    $("#put-images").removeClass("h-100");
    var thumbnail = $("<img />" , {
      "class": "img-thumbnail col-12 w-80 h-80 modal-img-thumbnail",
      src: img_ary[0] + "&w=320"
    })
    $("#put-images").append(thumbnail)

    // 画像一覧生成とモーダルへの挿入
    $.each(img_ary, function(index, value){
      var car_image = $("<img />" , {
        "class": "col-2 border px-1 mb-2 w-100 h-100 modal-img-change",
        src: value + "&w=320"
      })
      $("#put-images").append(car_image)
    })
    console.log('done')
  }).fail(function(){
    // エラーの場合処理
    console.log('error')
  });
}

// 画像初期化
function reset_img(){
  $(".modal-img-thumbnail").remove();
  $("#put-images").addClass("h-100");
  var thumbnail = $("<img />" , {
    "class": "rounded mx-auto modal-img-thumbnail",
    src: "/assets/default.webp"
  })
  $("#put-images").append(thumbnail)
  $(".modal-img-change").remove();
}

// 次へ、戻るボタン初期化
function reset_button(){
  if ($(".modal-next").hasClass("btn-secondary")){
    $(".modal-next").removeClass('btn-secondary');
    $(".modal-next").addClass('btn-primary');
  }
  if ($(".modal-back").hasClass("btn-secondary")){
    $(".modal-back").removeClass('btn-secondary');
    $(".modal-back").addClass('btn-primary');
  }
}