document.addEventListener('turbolinks:load', () => {

  const selector = '.editor_use';
  const elements = document.querySelectorAll(selector);
  if (elements.length === 0) {
      return
  }
  $(function() {
    tinymce.init({
      selector:".tiny-mce",
      extended_valid_elements: "*[*]",
      valid_elements : '*[*]',
      valid_children : "+a[div|class|id],+body[style]",
      //extended_valid_elements : "style",
      plugins: "textcolor table lists link code preview image",
      external_plugins: {
        'image': '/tinymce/js/tinymce/plugins/image/plugin.min.js',
        'textcolor': '/tinymce/js/tinymce/plugins/textcolor/plugin.min.js',
        'table': '/tinymce/js/tinymce/plugins/table/plugin.min.js',
        'link': '/tinymce/js/tinymce/plugins/link/plugin.min.js',
        'lists': '/tinymce/js/tinymce/plugins/lists/plugin.min.js',
        'preview': '/tinymce/js/tinymce/plugins/preview/plugin.min.js',
        'code': '/tinymce/js/tinymce/plugins/code/plugin.min.js',
     },
      verify_html: false,
      menubar: "false",
      content_css: "/tinymce/js/tinymce/skins/content/default/content.min.css",
      skin: false,
      // 相対パスに変換されるのを防ぐ
      relative_urls : false,
      convert_urls: false,
      tiny_pageembed_classes: [
        { text: 'Big embed', value: 'my-big-class' },
        { text: 'Small embed', value: 'my-small-class' }
      ],
      toolbar: ['undo redo |formatselect| fontsizeselect| bold italic  | forecolor backcolor | alignleft aligncenter ' +
    'alignright alignjustify | numlist bullist | table | link| image|code | preview' ],
      fontsize_formats: '10px 12px 14px 16px 18px 20px 24px 34px',
      images_upload_url: "/admin/images/"+ type,
      branding: false
    }); 
  });

}) ;
document.addEventListener('turbolinks:visit', () => {
  tinymce.remove('.tiny-mce');
}) ;