<template>
  <div>
    <transition name="admin-sidebar" :duration="250">
      <slot v-if="isAdminSidebarVueOpen"></slot>
    </transition>
  </div>
</template>

<script>
// 管理者サイドバー(app/views/shared/_admin_sidebar.html.slim) で使用。

export default {
  props: ["isAdminSidebarVueOpen"],
};
</script>

<style lang="scss" scoped>

// 管理者サイドバー ハンバーガーメニュー
// 開閉アニメーションCSS
@keyframes sidebar-slide-open {
  0% {
    opacity: 0;
    transform: translateX(-275px);
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.admin-sidebar-enter-active {
  animation: sidebar-slide-open .25s ease-out;
}

.admin-sidebar-leave-active {
  animation: sidebar-slide-open .25s ease-out reverse;
}

</style>