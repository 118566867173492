import Vue from 'vue/dist/vue.esm'
import axios from 'axios';
import qs from 'qs';

import TurbolinksAdapter from 'vue-turbolinks';
Vue.use(TurbolinksAdapter);

import {enablePaymentDetailModal} from "./admin_orders_payment_detail_modal";

import consumer from "../channels/consumer"

let $notification = null;

let subscription = null;
document.addEventListener('turbolinks:load', () => {
    const selector = '.admin-orders-index';
    const elements = document.querySelectorAll(selector);
    if (elements.length === 0) {
        return
    }

    const notificationHtml = `
        <div class="order-index-toast sticky-top" style="z-index: 1025;" ;>
            <div style="position: absolute; top:0; right: 0; color:#242929" role="alert">
                <div class="toast" aria-live="assertive" aria-atomic="true" data-autohide="false">
                    <div class="toast-header">
                        <i class="fas fa-bell"></i>
                        &nbsp;
                        <strong class="mr-auto">New message</strong>
                        <small><!-- Just now. --></small>
                        <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="toast-body">
                        <a href="/admin/orders">Click to reload.</a>
                    </div>
                </div>
            </div>
        </div>
        `;

    $notification = $(notificationHtml);
    $('header.sticky-top').before($notification);
    $('.toast', $notification).toast();

    subscription = consumer.subscriptions.create({'channel': "NewMessageFromUserChannel"}, {
        connected() {
            // Called when the subscription is ready for use on the server
            // console.log("connected")
        },

        disconnected() {
            // Called when the subscription has been terminated by the server
            // console.log("disconnected")
        },

        received(data) {
            // Called when there's incoming data on the websocket for this channel
            //  console.log("received")
            //  console.log(data)
            $('.toast', $notification).toast('show');
        }
    });

    // 支払い詳細モーダル機能を有効化します。
    enablePaymentDetailModal();

    new Vue({
      el: ".admin-orders-search",
      data: function (){
        return {
          userSearch: {
            modalInstance: null,
            users: [],
            q: {}
          },
        }
      },
      beforeDestroy(){//インスタンスが破棄される前に
        if (this.userSearch.modalInstance){
          $('.modal-backdrop').remove();//モーダルを表示したままブラウザの戻るボタンや進むボタンを押した際に操作できなくなる問題対応
          this.userSearch.modalInstance.modal('dispose');
        }
      },
      methods: {
        showUserSearchModal(index) {
          this.userSearch.modalInstance = $(this.$refs.userSearchModal).modal({keyboard: false, show:true});
          const $modalBody = $('.modal-body', this.$refs.userSearchModal)
          $modalBody.height($modalBody.height() + 1)
        },
        hideUserSearchModal() {
          if (this.userSearch.modalInstance){
            this.userSearch.modalInstance.modal('hide');
          }
        },
        onClickChooseUser: function(userId){
          document.querySelector('#user_id').value = userId;
          this.hideUserSearchModal();
        },
        onClickUserSearch: async function (){
          const result = await axios.get(
              '/admin/api/search_user2.json',
              {
                params: {
                  q: this.userSearch.q
                },
                paramsSerializer: function(params) {
                  return qs.stringify(params, {arrayFormat: 'brackets'})
                }
              }
          )
          this.userSearch.users = result.data;
        },
      },
      mounted() {

      }
  })

});

document.addEventListener('turbolinks:visit', () => {
    if (subscription) {
        consumer.subscriptions.remove(subscription)
    }

    if ($notification){
        $('.toast', $notification).toast('dispose');
        $notification.remove();
    }

    $notification = null;
});