import Vue from 'vue/dist/vue.esm'
import axios from 'axios';
import qs from 'qs';

import TurbolinksAdapter from 'vue-turbolinks';
Vue.use(TurbolinksAdapter);

document.addEventListener('turbolinks:load', () => {
    const selector = '.admin-unarrived-form';
    const elements = document.querySelectorAll(selector);
    if (elements.length === 0) {
        return
    }

    new Vue({
      el: ".admin-unarrived-form",

      data: function (){
        return {
          csrfToken: '',
          unarrived:{},
        }
      },
      mounted: function(){
        this.csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        const container = this.$el;//<div class="admin-unarrived-form">

        this.unarrived = JSON.parse(container.getAttribute('data-unarrived'));
        this.setBankCurrency();
      },
      watch:{
      },
      computed:{
      },
      methods: {
        //銀行の選択肢によって金額のフォームを入れ替える
        setBankCurrency() {

          if (this.unarrived.bank == "SBI-USD" || this.unarrived.bank == "MIZUHO-USD" || this.unarrived.bank == "SMBC-USD" || this.unarrived.bank == "PAYPAL-USD"){
            this.unarrived.currency ="dollar"
            this.unarrived.deposit_jpy = ''
      
          }else if(this.unarrived.bank == "SBI-JPY" || this.unarrived.bank == "MIZUHO-JPY" || this.unarrived.bank == "SMBC-JPY" || this.unarrived.bank == "PAYPAL-JPY"){
            this.unarrived.currency ="yen"
            this.unarrived.deposit_usd = ''
          }else if (this.unarrived.bank == "OTHER" && this.unarrived.usage == "allocation" ){
            if (this.unarrived.currency =="dollar"){
              this.unarrived.deposit_jpy = ''
              this.unarrived.deposit_usd = 0
            }else if (this.unarrived.currency =="yen"){
              this.unarrived.deposit_jpy = 0
              this.unarrived.deposit_usd = ''
            }
          }
        },
        onChangeBank(){
          this.setBankCurrency();
        },
      },
  })
});

document.addEventListener('turbolinks:visit', () => {
    // if (subscription) {
    //     consumer.subscriptions.remove(subscription)
    // }

    // if ($notification){
    //     $('.toast', $notification).toast('dispose');
    //     $notification.remove();
    // }

    // $notification = null;
});