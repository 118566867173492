import Vue from 'vue/dist/vue.esm'

import TurbolinksAdapter from 'vue-turbolinks';

document.addEventListener('turbolinks:load', () => {
    const selector = '.vip-partner-code-vue';
    const elements = document.querySelectorAll(selector);
    if (elements.length === 0) {
        return
    }

    new Vue({
        el: selector,
        data: {
            partnerCode: {
                code: '',
                copied: false
            },
            signUpUrl: ''
        },
        mounted() {
            const container = this.$el;//<div class="admin-bids-index-vue">

            const properties = JSON.parse(container.getAttribute('data-properties-json'));
            this.partnerCode.code = properties['partner_code']
            this.signUpUrl = properties['sign_up_url']
        },
        computed: {
            inviteUrl(){
                return `${this.signUpUrl}?partner_code=${this.partnerCode.code}`
            }
        },
        methods: {
            onClickPartnerCode(){
                this.$refs['pertnerCode'].select()
            },
            onClickPartnerCodeCopyButton(){
                navigator.clipboard.writeText(this.inviteUrl)
                this.partnerCode.copied = true
            }
        }
    })
})