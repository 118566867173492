<template>
  <div>
    <div class="sidebar-heading" v-on:click="onClickAdminSidebarHead">
      <slot name="vue-admin-sidebar-heading"></slot>
    </div>
    <transition name="sidebar-list-group" :duration="250">
      <div
        class="list-group list-group-custom list-group-flush"
        v-if="isAdminSidebarAccordionOpen"
      >
        <slot name="vue-admin-sidebar-list"></slot>
      </div>
    </transition>
  </div>
</template>
<script>
// 管理者サイドバー(app/views/shared/_admin_sidebar.html.slim) で使用。
import Vue from "vue/dist/vue.esm";

import VueCookies from "vue-cookies";
Vue.use(VueCookies);

import TurbolinksAdapter from "vue-turbolinks";
Vue.use(TurbolinksAdapter);

export default {
  props: ["majorItemName"],
  data() {
    return {
      // アドミンサイドバーの大項目の名(ex. messages,vehicle)
      headerItemName: this.majorItemName,
      // アコーディオンが開いている true、閉じている false
      isAdminSidebarAccordionOpen: true,
    };
  },
  created() {
    const isOpenCookie = this.$cookies.get(
      `is-open-accordion-${this.headerItemName}`
    );
    // Cookieのvalueの型は、String。Boolean型として、格納する。
    // Cookieが設定されていなければ開いた状態で表示。
    this.isAdminSidebarAccordionOpen = isOpenCookie === "false" ? false : true;
  },
  methods: {
    onClickAdminSidebarHead() {
      this.isAdminSidebarAccordionOpen = !this.isAdminSidebarAccordionOpen;
      // 現在開いている項目ごとの開閉の状態を、Cookieで保存する。
      // 例: Vehicleの項目のアコーディオンの開閉は、is-open-accordion-vehicle に保存される。
      this.$cookies.set(
        `is-open-accordion-${this.headerItemName}`,
        this.isAdminSidebarAccordionOpen
      );
    },
  },
};
</script>

<style scoped>
@keyframes open {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.sidebar-list-group-enter-active {
  animation: open 0.25s ease-out;
}
.sidebar-list-group-leave-active {
  animation: open 0.25s ease-out reverse;
}
</style>