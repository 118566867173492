import Vue from 'vue/dist/vue.esm'
import axios from 'axios'
import 'jquery.cookie';

import TurbolinksAdapter from 'vue-turbolinks';
Vue.use(TurbolinksAdapter);

document.addEventListener('turbolinks:load', () => {
  const selector = '.user_users_form_vue'
  const element = document.querySelector(selector);
  if (element === null) {
    return
  }

  new Vue({
    el: selector,
    data: function (){
      return {
        user: {},
        ports: [['Select Country', '']],
        callingCodes: [],
        user: {},
        sameAsConsignee: true
      }
    },
    mounted: function(){
      const container = this.$el;//<form class="user_users_form_vue">
      // セキュリティのため一部のカラムしか取得していません。
      // カラムを追加したい場合は、users/registrations/edit.html.slim ファイルの.to_jsonメソッドの引数を書き足してください。
      this.user = JSON.parse(container.getAttribute('data-user'));
      this.callingCodes = JSON.parse(container.getAttribute('data-calling-codes'));

      this.getPorts();

      const someFieldHasValue = [
        this.user['notify_party_username'],
        this.user['notify_party_address'],
        this.user['notify_party_tel'],
        this.user['notify_party_fax'],
        this.user['notify_party_email']
      ].some((value)=>Boolean(value))
      this.sameAsConsignee = !someFieldHasValue;
    },
    computed: {
      callingCode: function(){
        if (!this.user['country_id']){
          return '+00(Select country above)';
        }else {
          return this.callingCodes[this.user['country_id']]
        }
      },
      canCfsSelect: function(){
        //ケニアとウガンダの時だけCFSを選択できる
        if (this.user['country_id'] == 1 || this.user['country_id'] == 154) {
          return true;
        } else {
          return false;
        }
      }
    },
    methods: {
      onChangeSameAsConsignee: function () {
        if(this.sameAsConsignee){
          this.user['notify_party_username'] = '';
          this.user['notify_party_address'] = '';
          this.user['notify_party_tel'] = '';
          this.user['notify_party_fax'] = '';
          this.user['notify_party_email'] = '';
        }
      },
      onChangeCountry: function (){
        if (!this.user['country_id']){
          $.removeCookie("country_id", {path: "/"});
        }
        $.removeCookie("port_id", {path: "/"});

        this.user['port_id'] = '';
        this.getPorts();
      },
      getPorts: async function (){
        this.ports = [['Select Country', '']];

        if(!this.user['country_id']){
          return;
        }

        try{
          const result = await axios.get(`/api/port/${this.user['country_id']}`);
          this.ports = this.ports.concat(result.data);
        }catch (e){
          console.log(e)
        }
      },
    },
  })
});