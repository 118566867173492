import Vue from 'vue/dist/vue.esm'
import TurbolinksAdapter from 'vue-turbolinks';

Vue.use(TurbolinksAdapter);

document.addEventListener('turbolinks:load', () => {

  const element = document.querySelector(".admin-invoice-search");
  if (!element) {
    return
  }

  new Vue({
    el: ".admin-invoice-search",
    data: {
    },

    watch: {
    },

    methods: {

      /**
       * 検索条件"T/T Copy Status"に関するチェックボックス変更時
       * 他の"T/T Copy Status"に関するチェックボックスを全て未選択にする
       * @param event viewらのHTML要素イベント
       */
      handleTtCopyStatusCheckboxChange(event) {
        // イベント発生元の要素を取得(=clickしたチェックボックスを取得)
        let target = event.target;

        // チェックボックスが未選択の場合は何もしない
        if (!target.checked) return;

        // CSSクラス"tt-copy-status-cehck"を持つ全てのチェックボックスを取得
        let checkboxes = document.querySelectorAll(".tt-copy-status-cehck");

        // 他のチェックボックスを全て未選択にする
        checkboxes.forEach(checkbox => {
          if (checkbox !== event.target) {
            checkbox.checked = false;
          }
        });
      },

    },
  });
});
