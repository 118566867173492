import Vue from 'vue/dist/vue.esm'
import axios from 'axios';
import qs from 'qs';

import TurbolinksAdapter from 'vue-turbolinks';
Vue.use(TurbolinksAdapter);


document.addEventListener('turbolinks:load', () => {
    const selector = '.admin-carried_forwards_form';
    const elements = document.querySelectorAll(selector);
    if (elements.length === 0) {
        return
    }

    new Vue({
      el: ".admin-carried_forwards_form",
      data: function (){
        return {
          carried_forward: {},
          Step:'',
          userSearch: {
            modalInstance: null,
            users: [],
            q: {}
          },

        }
      },

      mounted: function(){
        const container = this.$el;//<div id="carried_forwards_form">
        this.carried_forward = JSON.parse(container.getAttribute('data-carried_forward'));



        //通貨の初期値
        if (this.carried_forward.currency == null){
          this.carried_forward.currency = 'dollar'
          this.Step = '0.01'
        }
        else if(this.carried_forward.currency == 'dollar'){
          this.Step = '0.01'
        }else if(this.carried_forward.currency == 'yen'){
          this.Step = '1'
          this.carried_forward.fee = Number(this.carried_forward.fee)
        }
      },

      beforeDestroy(){//インスタンスが破棄される前に
        if (this.userSearch.modalInstance){
          $('.modal-backdrop').remove();//モーダルを表示したままブラウザの戻るボタンや進むボタンを押した際に操作できなくなる問題対応
          this.userSearch.modalInstance.modal('dispose');
        }
      },
      methods: {
        showUserSearchModal(index) {
          this.userSearch.modalInstance = $(this.$refs.userSearchModal).modal({keyboard: false, show:true});
          const $modalBody = $('.modal-body', this.$refs.userSearchModal)
          $modalBody.height($modalBody.height() + 1)
        },
        hideUserSearchModal() {
          if (this.userSearch.modalInstance){
            this.userSearch.modalInstance.modal('hide');
          }
        },
        onClickChooseUser: function(userId){
          this.carried_forward.user_id =  userId;
          this.hideUserSearchModal();
        },
        onClickUserSearch: async function (){
          const result = await axios.get(
              '/admin/api/search_user2.json',
              {
                params: {
                  q: this.userSearch.q
                },
                paramsSerializer: function(params) {
                  return qs.stringify(params, {arrayFormat: 'brackets'})
                }
              }
          )
          this.userSearch.users = result.data;


        },

        //通貨によって金額のstepを変える
        currencyChange(currency) {
          if (currency == 'dollar'){
              this.Step = '0.01'
              this.carried_forward.fee = ''
            }else if(currency == 'yen'){
              this.Step = '1'
              this.carried_forward.fee = ''
          }
        },
      },
  })
});

document.addEventListener('turbolinks:visit', () => {

});