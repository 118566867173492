import Vue from 'vue/dist/vue.esm'
import axios from 'axios'
import _, { isArray } from 'lodash'
const { DateTime } = require("luxon");


import InfiniteLoading from 'vue-infinite-loading';
Vue.use(InfiniteLoading, { /* options */ });

import TurbolinksAdapter from 'vue-turbolinks';
Vue.use(TurbolinksAdapter);

import consumer from "../channels/consumer"
import {appearingEventEmitter} from "./channels/appearing_channel";

document.addEventListener('turbolinks:load', () => {

  const element = document.getElementById("message-vue");
  if (element === null) {
    return
  }

  if (location.hash === '#message-vue') {
    window.scrollBy(0, -1 * $('header.sticky-top').height())
  }

  let subscription = null;
  new Vue({
    el: '#message-vue',
    data: function () {
      return {
        account: 'user',//'user'|'admin'
        currentAdminUser: {
          id: null,
          role: null
        },
        currentAdminUserRole: null,
        csrfToken: '',
        order: {
          id: 0,
          user: {
            id: 0,
            username: '',
            last_appeared_at: ''//ISO 8601 文字列(2022-01-24T03:02:21.074Z)
          }
        },
        messagesUrl: '',
        tooManyPosts: false,

        text: '',
        sending: false,
        sendError: '',
        isShownReplyPostForm: false,

        firstLoadingIsCompleted: false,
        loadError: '',
        messages: [],
        refreshing: false,
        intervalId: null,
        infiniteLoadingStarted: false,

        messageTemplate: null,
        messageTemplates: [],
        textAsTemplateModal: null,
        textAsTemplate: {
          title: '',
          message: ''
        },

        isShownMemoForm: false,
        memoIsSaved: false,
        memoError: '',

        typingIndicator: {
          isTyping: false,
          timeoutId: null
        },

        userOnlineIndicator: {
          isOnline: false
        },

        adminOnlineIndicator: {
          isOnline: false,
          intervalId: null,
          heartbeat: false
        },

        isTextareaDisabled: false
      };
    },
    filters: {
      /**
       * 2022-01-24T03:02:21.074Z という型式を受け取り、日付フォーマットして返す
       * @param iso
       */
      dateFormatFromIsoDateString(iso){
        return DateTime.fromISO(iso).toFormat("'last seen 'yyyy-LL-dd 'at' T");
      }
    },
    computed: {
      disabled(){
        return this.sending || this.tooManyPosts || this.isTextareaDisabled
      },
      reversedMessages(){
        const clone = [...this.messages];
        return clone.reverse();
      },
      canSendAdminMessage(){
        if (!this.order['admin_user_id']) {
          return true; //オーダーの担当者が未設定の場合は誰でも返信できる
        }
        if (this.order['admin_user_id'] == this.currentAdminUser['id']) {
          return true; //ログイン中の管理者が、オーダーの担当者である場合は返信できる
        }
        if (['super_user', 'member'].includes(this.currentAdminUser['role'])) {
          return true; //ログイン中の管理者のロールが、super_user か member であれば返信できる。
        }
        return false
      }
    },
    beforeDestroy(){//インスタンスが破棄される前に

      this.clearTypingTimeout();
      this.clearAdminOnlineInterval();
      if (this.account === 'admin') {
        this.adminDisappear();
        window.removeEventListener('unload', this.bindedAdminDisappear)//created()を参照
      }

      consumer.subscriptions.remove(subscription)

      if (this.textAsTemplateModal){
        $('.modal-backdrop').remove();//モーダルを表示したままブラウザの戻るボタンや進むボタンを押した際に操作できなくなる問題対応
        this.textAsTemplateModal.modal('dispose');
      }
      appearingEventEmitter.removeListener('changed', this.onUserAppearanceChanged)
    },
    mounted(){//インスタンスがマウントされた後に
      this.csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

      const container = this.$el;//<div id="message-vue">
      const info = JSON.parse(container.getAttribute('data-info'));
      this.account = info['account'];
      if (info['current_admin_user_json']) {
        this.currentAdminUser = JSON.parse(info['current_admin_user_json'])
      }

      this.order = JSON.parse(info['order_json']);

      this.messagesUrl = info['url'];
      this.tooManyPosts = info['too_many_posts'];

      if (this.account === 'admin'){
        this.loadMessageTemplates();
      }

      const self = this;
      subscription = consumer.subscriptions.create({'channel': "MessageChatChannel", 'order_id':self['order']['id']}, {
        connected() {
          // Called when the subscription is ready for use on the server
          if (self.account === 'user') {
            self.requestAdminHeartbeat();
          }
          if(self.account === 'admin') {
            self.adminAppear()
          }
        },

        disconnected() {
          // Called when the subscription has been terminated by the server
          // console.log("disconnected")
        },

        received(data) {
          // Called when there's incoming data on the websocket for this channel
          // console.log("received")
          switch (data['type']){
            case 'NEW_MESSAGE':
              self.refresh();
              break;
            case 'TYPING':
              self.onReceiveTyping(data['account'])
              break;
            case 'APPEARING':
              if (data['account'] == 'admin') {
                self.onAdminUserAppearanceChanged(data['is_appearing'])
              }
              break;
            case 'HEARTBEAT_REQUESTED':
              self.replyAdminHeartbeat()
              break;
            case 'HEARTBEAT_REPLIED':
              self.onReceiveAdminHeartbeatReplied()
              break;
          }
        }
      });

      appearingEventEmitter.on('changed', this.onUserAppearanceChanged)
    },
    created() {
      //キー入力のたびに this.performTyping() を実行するとサーバーに負荷がかかるため、 lodash ライブラリの throttle メソッドで軽減する
      this.throttledPerformTyping = _.throttle(this.performTyping, 2000)

      this.bindedAdminDisappear = this.adminDisappear.bind(this);
      window.addEventListener('unload', this.bindedAdminDisappear);
    },
    updated() {
      if (!this.firstLoadingIsCompleted && this.messages.length > 0) {//初回読み込み時だけの処理
        this.firstLoadingIsCompleted = true;//次回以降は処理させないようにfalseをセットする
        this.scrollToMessage(this.messages[0]);//最新のメッセージの箇所まで自動でスクロールする
      }
    },
    watch: {
      'adminOnlineIndicator.isOnline': function(){
        if (this.account != 'user') {
          return;//ユーザー画面側がadminの生存を確認する処理なので、管理画面側の場合は何もしない
        }
        if (this.adminOnlineIndicator.isOnline) {
          this.setAdminOnlineInterval();
        } else {
          this.clearAdminOnlineInterval();
        }
      }
    },
    methods: {//メソッド

      //メッセージ詳細画面 chat bot apiによるメッセージ生成のテストメソッド
      buttonFetchAndSetTemplate() {
        const testButton = document.getElementById('chatgpt');
        // テキストエリアに何か入力されている場合は、何もしない
        if (this.text.length > 0) {
          console.log('🚀 ~ message text area is not empty');
          return null;
        }

        // disable testButton
        testButton.disabled = true;

        this.getTemplateFromAiChat()
            .then((data) => {
              console.log('🚀 ~ chatGPT message ~ :', data.detail);
              this.text = data.detail; // テキストエリアにテンプレートをセット
            })
            .catch((error) => {
              console.log('🚀 ~ error: ', error, error.response?.data?.detail);
            })
            .finally(() => {
              // enable testButton
              testButton.disabled = false;
            });
      },

      // 2023/11/29 時点で未使用だが、仕様変更を考慮して残しておく
      // fetchAndSetTemplate() {
      //
      //
      //   // テキストエリアに何か入力されている場合は、何もしない
      //   if (this.text.length > 0) {
      //     console.log('🚀 ~ message text area is not empty');
      //     return null;
      //   }
      //
      //   // テキストエリアを一時無効化
      //   this.isTextareaDisabled = true;
      //
      //   // aichatからテンプレートを取得
      //   this.getTemplateFromAiChat()
      //       .then((data) => {
      //         this.text = data.detail; // テキストエリアにテンプレートをセット
      //       })
      //       .catch((error) => {
      //         console.log('🚀 ~ error: ', error, error.response?.data?.detail);
      //       })
      //       .finally(() => {
      //         // テキストエリアを有効化
      //         this.isTextareaDisabled = false;
      //       });
      // },

      async getTemplateFromAiChat() {
        let dataToSend = {
          orderId: this.order['id'],
        };

        let response = await axios.post(
            '/admin/api/message_template_from_aichat'
            , dataToSend
            , {
              headers: {
                "X-Requested-With": "XMLHttpRequest", // ajaxリクエストであることを伝えるため。コントローラーでrequest.xhr?で判定できる。
                "X-CSRF-Token": this.csrfToken // CSRF対策のため。
              },
              // timeout: 5000 // 5秒でタイムアウト
            }
        );

        return response.data;
      },
      onKeyup(){
        this.throttledPerformTyping();//created()を参照
      },
      performTyping(){
        //created()を参照
        subscription.perform('typing')
      },
      clearTypingTimeout(){
        if (this.typingIndicator.timeoutId) {
          clearTimeout(this.typingIndicator.timeoutId);
        }
      },
      onReceiveTyping(account){
        if(account == this.account) {
          //入力中なのが自分である場合はなにもしない。
          return;
        }
        this.typingIndicator.isTyping = true;
        this.clearTypingTimeout();
        this.typingIndicator.timeoutId = setTimeout(()=>{this.typingIndicator.isTyping = false}, 2500)
      },
      adminAppear(){
        subscription.perform('appearing', {'is_appearing': true})
      },
      adminDisappear(){
        subscription.perform('appearing', {'is_appearing': false})
      },
      onAdminUserAppearanceChanged(isOnline){
        this.adminOnlineIndicator.isOnline = isOnline;
        this.adminOnlineIndicator.heartbeat = isOnline;
      },
      clearAdminOnlineInterval(){
        clearInterval(this.adminOnlineIndicator.intervalId);
        this.adminOnlineIndicator.intervalId = null;
      },
      setAdminOnlineInterval(){
        if (this.account != 'user') {
          return;//ユーザー画面側がadminの生存を確認する処理なので、管理画面側の場合は何もしない
        }
        if (this.adminOnlineIndicator.intervalId !== null) {
          return null;
        }
        this.adminOnlineIndicator.intervalId = setInterval(()=>{this.requestAdminHeartbeat()}, 5000)//それ以降は数秒ごとに実行。
      },
      requestAdminHeartbeat(){
        this.adminOnlineIndicator.isOnline = this.adminOnlineIndicator.heartbeat;
        this.adminOnlineIndicator.heartbeat = false;//応答がなかった場合、次回のinterval実行時に、このfalseがisOnlineへ代入される。
        subscription.perform('request_heartbeat')
      },
      replyAdminHeartbeat(){
        if (this.account != 'admin') {
          return;//管理画面側が生存報告をするための処理。ユーザー画面側は何もしない
        }
        subscription.perform('reply_heartbeat')
      },
      onReceiveAdminHeartbeatReplied() {
        if (this.account != 'user') {
          return;//ユーザー画面側がadminの生存を確認する処理。管理画面側は何もしない
        }
        this.adminOnlineIndicator.isOnline = true;
        this.adminOnlineIndicator.heartbeat = true;
      },
      onClickSaveTextAsTemplateModal(){
        this.textAsTemplate.message = this.text
        this.textAsTemplateModal = $(this.$refs.saveTextAsTemplateModal).modal({backdrop: 'static', keyboard: false, show:true});//<div class="modal fade" ref="saveTextAsTemplateModal">
      },
      onClickSaveTextAsTemplate: async function (){
        await this.saveTextAsTemplate(this.textAsTemplate.title, this.textAsTemplate.message);
        await this.loadMessageTemplates();
        this.textAsTemplate.title = '';
        this.textAsTemplateModal.modal('hide');
      },
      onTemplateChanged: function (){
        if (!this.messageTemplate){
          return;
        }

        this.text = this.messageTemplate.message;
      },
      onClickDeleteTemplate: async function (){
        try{
          const id =  this.messageTemplate.id;
          this.messageTemplate = null;
          await this.deleteMessageTemplate(id);
          await this.loadMessageTemplates();
        }catch (error){
          console.error(error)
        }
      },
      // 返信ボタンクリックで、メッセージフォーム表示機能
      onClickReplyBtn() {
        this.isShownReplyPostForm = !this.isShownReplyPostForm

        // 11/29 時点、以下の内容は未使用だが、仕様変更を考慮して残しておく
        // "REPLY"ボタン押下と同時に、AIチャットから返信メッセージを取得する。
        // テストのため、コメントアウト。本番に導入する時、コメントを外す。
        // if (this.isShownReplyPostForm === true) {
        //   this.fetchAndSetTemplate();
        // }

      },
      // Enter memo リンクをクリックで、メモフォーム表示機能
      onClickEnterMemoLink() {
        this.isShownMemoForm = !this.isShownMemoForm;
      },
      onClickSaveMemo: async function(){
        await this.saveMemo()
      },
      onUserAppearanceChanged(appearances){

        const appearingData = appearances[this.order['user']['id']];
        if (!appearingData) {
          return
        }

        this.userOnlineIndicator.isOnline = appearingData.isOnline
        this.order['user']['last_appeared_at'] = appearingData['user']['last_appeared_at']
      },
      async send(attachmentRefName) {
        if (this.sending) {
          return ;
        }

        const formData = new FormData();

        const refsAttachment = this.$refs[attachmentRefName];
        // <input ref="attachment_top"> or <input ref="attachment_reply">
        const fileElement = isArray(refsAttachment) ? refsAttachment[0] : refsAttachment;

        var totalFileSize = 0
        // 添付ファイル
        for (var i = 0; i < fileElement.files.length; i++) {
          totalFileSize += fileElement.files[i].size
          formData.append('message[multiple_attachment_file][]', fileElement.files[i]);
        }
        formData.append('message[message]', this.text);
        // ファイル制限
        if ((totalFileSize / 1024 ** 2) > 5) {
          alert("File size should be less than 5MB")
          return
        }

        this.sending = true;
        try{
          await axios.post(
              this.messagesUrl,
              formData,
              {
                headers:{
                  'Content-Type': 'multipart/form-data',
                  "X-Requested-With": "XMLHttpRequest",
                  "X-CSRF-Token": this.csrfToken
                }
              }
          )
          this.sendError = '';
        }catch (error) {
          this.sendError = 'Faild to send messsage.';
        }

        if (!this.sendError) {
          this.text = '';
          fileElement.value = '';
        }

        this.sending = false;
      },
      newestMessageId() {
        if (this.messages[0]) {
          return this.messages[0].id;
        } else {
          return 0;
        }
      },
      oldestMessageId() {
        if (this.messages.length) {
          return this.messages[this.messages.length -1].id;
        } else {
          return 0;
        }
      },
      refresh() {
        return new Promise(async (resolve, reject) => {

          if (this.refreshing) {
            resolve([]);
            return;
          }
          try{
            this.refreshing = true;
            this.typingIndicator.isTyping = false
            this.clearTypingTimeout();

            const unseen_ids = this.messages.filter((message)=>!message.seen).map(message=>message.id);
            const response = await axios.get(
                `${this.messagesUrl}/current.json`,
                {params: {
                    'q[id_gt]': this.newestMessageId(),
                    'unseen_ids': unseen_ids
                  }}
            );

            const messages =
                response.data.messages.concat(this.messages) //既存データと取得したデータを連結する。
                    .map((message)=>{
                      const seen_message = response.data.seen_messages.find((seen_message)=>seen_message.id === message.id)
                      if (!seen_message){
                        return message;
                      }
                      return seen_message;
                    });//既読になったメッセージを置き換える


            this.messages = _.unionBy(messages, 'id');//読み込み処理の時間差によっては重複することがあるので重複除去

            this.tooManyPosts = Boolean(response.data['too_many_posts'])

            if (response.data['order']){
              this.order['admin_user_id'] = response.data['order']['admin_user_id']
            }

            this.loadError = '';
            resolve(response.data);
          }catch (error) {
            this.loadError = 'Faild to load messsages.';
            reject(error)
          }finally {
            this.refreshing = false;
          }
        })
      },
      loadOlderMessages() {
        return new Promise(async (resolve, reject) => {
          let params;
          if (this.messages.length === 0 && !this.infiniteLoadingStarted) {
            //既存データがないので最新を取得し、取得済みを記録。
            params = {};
            this.infiniteLoadingStarted = true;
          } else {
            //既存データよりも古いものを取得
            params = {'q[id_lt]': this.oldestMessageId()};
          }

          try{
            const response = await axios.get(
                `${this.messagesUrl}.json`,
                {params: params}
            );
            const messages = this.messages.concat(response.data);
            this.messages = _.unionBy(messages, 'id');//読み込み処理の時間差によっては重複することがあるので重複除去
            this.loadError = '';
            resolve(response.data);
          }catch (error) {
            this.loadError = 'Faild to load messsages.';
            reject(error)
          }
        })
      },
      async infiniteHandler($state) {
        const loadedMessages = await this.loadOlderMessages();
        if (loadedMessages.length) {
          $state.loaded();
        } else {
          $state.complete();
        }
      },
      isOwnMessage(message){
        if (this.account === 'user' && message.user) {
          return true;
        }
        if (this.account === 'admin' && message.admin_user){
          return true;
        }
        return false;
      },
      scrollToMessage(message){
        if (!message || this.account === 'admin'){
          return;
        }
        const container = this.$el;//<div id="message-vue">
        const elementPosition = $(`[data-message-id=${message.id}]`, container).offset().top;
        var headerHeight = $("header.sticky-top").outerHeight();
        var carInfoHeight = 0;
        var $carInfo = $(".message>.container-fluid>.sticky-top");
        if ($carInfo.length > 0){
          carInfoHeight = $carInfo.outerHeight();
          $(".message>.container-fluid>.sticky-top").css('top', headerHeight + "px");
        }
        const position = elementPosition - (headerHeight + carInfoHeight) - 15;
        $("html, body").animate({ scrollTop: position }, 1000);
      },
      async loadMessageTemplates(){
        return new Promise(async (resolve, reject)=>{
          try{
            const response = await axios.get(`/admin/message_templates.json`);
            resolve(this.messageTemplates = response.data);
          }catch (error) {
            reject(error)
          }
        })
      },
      async deleteMessageTemplate(id){
        return axios.delete(
            `/admin/message_templates/${id}.json`,
            {
              headers:{
                "X-Requested-With": "XMLHttpRequest",
                "X-CSRF-Token": this.csrfToken
              }
            }
        )
      },
      async saveTextAsTemplate(title, message){
        return axios.post(`/admin/message_templates.json`,
            {
              message_template:{
                title: title,
                message: message
              }
            },
            {
              headers:{
                "X-Requested-With": "XMLHttpRequest",
                "X-CSRF-Token": this.csrfToken
              }
            }
        )
      },
      async saveMemo() {
        try{
          this.memoIsSaved = false;
          this.memoError = ''
          await axios.put(`/admin/orders/${this.order['id']}.json`,
              {
                'order': {memo: this.order.memo}
              },
              {
                headers:{
                  "X-Requested-With": "XMLHttpRequest",
                  "X-CSRF-Token": this.csrfToken
                }
              }
          )
          this.memoIsSaved = true;
        }catch (error) {
          this.memoError = 'Failed to save memo.';
        }
      }
    }
  });
});
