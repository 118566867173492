import Vue from 'vue/dist/vue.esm'
import _ from 'lodash'

import TurbolinksAdapter from 'vue-turbolinks';
import axios from "axios";
Vue.use(TurbolinksAdapter);

document.addEventListener('turbolinks:load', () => {
    const selector = '.admin-bids-index-vue';
    const elements = document.querySelectorAll(selector);
    if (elements.length === 0) {
        return
    }

    new Vue({
        el: selector,
        data: {
            q:{
                auction_in:[],
                marka_name_eq: '',
                car_model_name_eq: '',
            },
            auctionSites : {},
            checkedWeekdays: [],
            models: {}
        },
        mounted() {
            const container = this.$el;//<div class="admin-bids-index-vue">

            this.auctionSites = JSON.parse(container.getAttribute('data-auctions'));
            this.models = JSON.parse(container.getAttribute('data-models'));
            const q = JSON.parse(container.getAttribute('data-q'))
            if (q){
                this.q = q;
            }

        },
        computed:{
            carModelNames: function(){
                if (!this.models[this.q['marka_name_eq']]) {
                    return []
                }
                return this.models[this.q['marka_name_eq']];
            }
        },
        methods: {
            onClickWeekday: function (){
                for (const dayName in this.auctionSites) {
                    let auctions = [];
                    if (this.checkedWeekdays.includes(dayName)) {
                        auctions = _.concat(this.q['auction_in'], this.auctionSites[dayName])
                    } else {
                        auctions = _.difference(this.q['auction_in'], this.auctionSites[dayName])
                    }
                    this.q['auction_in'] = _.uniq(auctions)
                }
            },
            onChangeMarkaName: function (){
                this.q['car_model_name_eq'] = ''
            }
        }
    })
})