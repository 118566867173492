import Vue from 'vue/dist/vue.esm'
import axios from "axios";

export function enablePaymentDetailModal() {

  if (!document.getElementById('payment_detail_modal')) {
    return;
  }

  // 支払い詳細モーダル(#payment_detail_modal)の中のボディ(.modal-body)だけを処理します。
  // 支払い詳細モーダルウィンドウ(#payment_detail_modal)自体の表示や非表示はbootstrapの機能で行います。
  // app/views/admin/orders/index.html.slimに、モーダルウィンドウのHTMLコードがあります。
  const paymentModalBodyVue = new Vue({
    el: "#payment_detail_modal .modal-body",
    data: function () {
      return {
        loading: false,
        hasError: false,
        order: {
          invoice: {
            id: 0,
            currency: 'yen',
            total_price_yen: 0,
            total_price:0,
            number_of_payments: 1,
            invoice_prices: {
              1: {'num': 1, 'price': 0, 'payment_due': 1},
            }
          },
          sale: {
            id: 0,
            dollar_sales: 0,
            yen_sales: 0,
            sale_payments: {
              1: {
                num: 1,
                currency: 'yen',
                payment: 0,
                transaction_code: null,
                confirmation_date: null
              }}
          }
        }
      }
    },
    methods: {
      showBody: async function (orderId) {
        this.loading = true;
        this.hasError = false;

        try{
          //http://localhost:3000/admin/orders/30500/payment_modal.json
          const values = await axios.get(`/admin/orders/${orderId}/payment_modal.json`)
          this.order = values.data;
        }catch (e) {
          this.hasError = true;
        }

        this.loading = false;
      },
      hideBody: function () {
        this.loading = true;
        this.hasError = false;
      },
      currencyFormat(currency, price, precision = 0){
        if (price === null) {
          return null;
        }

        if (currency === 'JPY') {
          return Intl.NumberFormat('ja-JP', {style: 'currency', currency: 'JPY'}).format(price)
        }
        if (currency === 'USD') {
          return Intl.NumberFormat('us-EN', {style: 'currency', currency: 'USD', minimumFractionDigits: precision, maximumFractionDigits: precision}).format(price)
        }
        return price;
      }
    }
  });

  //_order_task_check.html.slimに、 data-target="#payment_detail_modal" 属性を持つボタンがあります。
  $('#payment_detail_modal').on('show.bs.modal', function (event) {
    const button = $(event.relatedTarget);
    const orderId = button.data('id');
    paymentModalBodyVue.showBody(orderId);
  })
  $('#payment_detail_modal').on('hide.bs.modal', function (event) {
    paymentModalBodyVue.hideBody();
  })
}