import Vue from 'vue/dist/vue.esm'
import axios from 'axios'
import 'jquery-ui-dist/jquery-ui'

import TurbolinksAdapter from 'vue-turbolinks';
Vue.use(TurbolinksAdapter);

import VueCookies from 'vue-cookies';
Vue.use(VueCookies);

document.addEventListener('turbolinks:load', () => {
  const selector = '#hader_search_group';
  const element = document.querySelector(selector);
  if (element === null) {
    return
  }

  new Vue({
    el: selector,
    data: {
      sourceArrayFromFile: [],
      db: '',
    },
    created() {
    },
    async mounted() {
      try {
        await this.initDatabase(); //indexedDBの初期化
      } catch (error) {
        // indexedDBの初期化に失敗した場合は、直接コントローラーにリクエスト
        await this.getAvailableSource();
      }
      this.searchAutoCompleteFromFile(); // オートコンプリート機能を追加
    },
    beforeDestroy(){
    },
    filters: {
    },
    computed: {
    },
    watch: {
    },
    methods: {

      /**
       * ファイルからリスト表示に使うデータ全件(配列)を取得し、Vueインスタンスの変数に格納
       */
      getAvailableSource: async function() {
        const result = await axios.post(`/api/head_search_source_json_from_file`,{},{
          headers:{
            "X-Requested-With": "XMLHttpRequest",
            "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
          }
        });
        this.sourceArrayFromFile = result.data
      },

      /**
       * 検索フォームにオートコンプリート機能を追加
       * Vueインスタンスの変数に格納したデータを元に、リスト表示
       */
      searchAutoCompleteFromFile: function() {
        const dataArray = this.sourceArrayFromFile

        $('#hader_search_input').autocomplete({
          source: function(request, response) {                   // request: 入力値, response: 表示リスト
            let searchKeyword = request.term.toLowerCase();     // キーワード(検索文字列)を小文字に変換しておく
            let filteredData = dataArray.filter(function(item) { // データからキーワードで始まるものを抽出
              return item.toLowerCase().startsWith(searchKeyword);        // データ1件づつ小文字に変換して検索
            });
            let slicedData = filteredData.slice(0, 10);          // 検索結果を最大10件に絞る
            response(slicedData);                                         // 表示リストへ反映
          },
          // appendTo: "#parent div's id that is fixed height", // ヘッダに付くように指定すると、リスト一部しか表示されないので使用しない。
          open: function() {
            // ヘッダのautocomplete専用仕様。
            // リスト表示時に、リストの位置をヘッダの検索フォームと同じ位置に固定して
            // スクロールしても常にヘッダ位置でリストが表示されるようにする。
            $( this ).autocomplete("widget").css({
                "position": "fixed",
            });
          },
          minLength: 1,                                                   // リスト表示に必要な最小文字数
          delay: 500,                                                     // リスト表示までの時間（ミリ秒）
        });

        $('#hader_search_input_mobile').autocomplete({
          source: function(request, response) {                   // request: 入力値, response: 表示リスト
            let searchKeyword = request.term.toLowerCase();     // キーワード(検索文字列)を小文字に変換しておく
            let filteredData = dataArray.filter(function(item) { // データからキーワードで始まるものを抽出
              return item.toLowerCase().startsWith(searchKeyword);        // データ1件づつ小文字に変換して検索
            });
            let slicedData = filteredData.slice(0, 5);           // 検索結果を最大5件に絞る
            response(slicedData);                                         // 表示リストへ反映
          },
          select: function(event, ui) {                           // リストから選択した場合の処理
            // 基本動作無効化
            event.preventDefault();

            // 検索フォームに選択した値をセット
            $('#hader_search_input_mobile').val(ui.item.value)

            // 表示リストを閉じるイベントが、
            // 検索フォームのナビゲーションメニューまで閉じてしまう問題を解決
            event.stopPropagation();
          },
          appendTo: "#hader_search_div_mobile",                           // リストの親elementを指定
          minLength: 1,                                                   // リスト表示に必要な最小文字数
          delay: 500,                                                     // リスト表示までの時間（ミリ秒）
        });
      },


      /**
       * indexedDBの初期化
       *
       * DB名: SBIAFDatabase
       * Object Store名（=テーブル名）: keywordContent
       * レコードkey-value: searchKeyword - ['keyword1', 'keyword2', 'keyword3', ...]
       * @returns {Promise}
       */
      initDatabase: function() {
        const vm = this; // Vueインスタンスのthis

        let now = new Date();
        let nowJPT = new Date(now.toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' }));
        let year = nowJPT.getFullYear();
        let month = (nowJPT.getMonth() + 1).toString().padStart(2, '0'); // JSの'月'は0から始まるので1を足す, '月'を2桁にする(01, 02, ...)
        let day = nowJPT.getDate().toString().padStart(2, '0'); // '日'を2桁にする(01, 02, ...)
        let currentVersion = `${year}${month}${day}`;

        return new Promise((resolve, reject) => {
          // indexedDBの生成、バージョンアップ
          const request = indexedDB.open("SBIAFDatabase", parseInt(currentVersion));

          request.onupgradeneeded = function(event) { // DBを生成、バージョンアップする際に実行される
            const db = event.target.result;
            // DBながない or Object Storeがない or バージョンアップされた場合、生成(上書き)
            if (!db.objectStoreNames.contains("keywordContent")) {
              db.createObjectStore("keywordContent");
            }
          };

          request.onsuccess = async function (event) { // 正常にDBオープンした場合に実行される
            vm.db = event.target.result;
            resolve(vm.getDataFromIndexedDB());
          };

          request.onerror = function(event) { // DBオープン失敗した場合に実行される
            console.error("Error opening IndexedDB:", event);
            reject(event);
          };
        });
      },

      /**
       * indexedDBからデータを取得し、Vueインスタンスの変数に格納
       * もしDBにデータが無い場合は、サーバーから取得してDBに格納
       * @returns {Promise<unknown>}
       */
      getDataFromIndexedDB: async function() {
        const vm = this; // Vueインスタンスのthis
        const transaction = vm.db.transaction(["keywordContent"]);
        const objectStore = transaction.objectStore("keywordContent");
        const request = objectStore.get("searchKeyword");
        return new Promise(async (resolve, reject) => {
          request.onsuccess = async function (event) {
            let data = event.target.result;
            // DBにデータが無い場合 or 配列では無い場合 or 空き配列の場合、サーバーから取得
            if (!data || !Array.isArray(data) || data.length === 0) {
              const response = await axios.post(`/api/head_search_source_json_from_file`,{},{
                headers:{
                  "X-Requested-With": "XMLHttpRequest",
                  "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                }
              });
              data = response.data;
              vm.storeDataInIndexedDB(data); // 取得したデータをindexedDBに保存
            }
            // データ用意完了
            vm.sourceArrayFromFile = data;
            resolve(vm.sourceArrayFromFile);
          };

          request.onerror = function (event) {
            console.error("Error fetching data from IndexedDB:", event);
            reject(event);
          };
        });

      },

      /**
       * indexedDBにデータを保存
       * サーバーから取得したキーワードリストを配列にして保存
       * 以下の形で保存しておく
       * key: 'searchKeyword'
       * value: ['keyword1', 'keyword2', ...]
       * @param data
       */
      storeDataInIndexedDB: function(data) {
        const vm = this; // Vueインスタンスのthis

        const transaction = vm.db.transaction(["keywordContent"], "readwrite");
        const objectStore = transaction.objectStore("keywordContent");
        const request = objectStore.put(data, 'searchKeyword');

        request.onsuccess = function (event) {
          // 処理成功...console.logなどでデバッグ
        };

        request.onerror = function (event) {
          console.error("Error storing data in IndexedDB:", event);
        };
      },

    }
  })
})