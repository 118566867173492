import 'raty-js';

document.addEventListener('turbolinks:load', () => {
  const $star = $('.raty-star');
  if (!$star.length) {
    return;
  }

  $star.each(function(){
    const option = $(this).data('option')
    $(this).raty(option);
  })
})