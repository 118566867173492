import Vue from 'vue/dist/vue.esm'
import TurbolinksAdapter from 'vue-turbolinks';

Vue.use(TurbolinksAdapter);

document.addEventListener('turbolinks:load', () => {
  const element = document.querySelector("#admin-car-search-div");
  if (!element) {
    return
  }

  const app = new Vue({
    el: "#admin-car-search-div",
    
    data: {
    },

    watch: {
    },

    methods: {

      /**
       * 'Purchasing Manager'検索条件のdrop-down menu管理
       */
      handleStockistCheck: function() {
        let self = this;

        // 選択後、dropdown-menuが閉じてしまうのを防ぐ
        $('.stockist-dropdown-menu').click(function (e) {
          e.stopPropagation();
        });

        // チェックボックスの状態が変わったら、ボタンのテキストを更新
        $('.stockist-checkbox').off('change').on('change', function() {
          self.updateStockistDropdownButtonState();
        });

        // ラベルやチェックボックス本体以外の箇所（例: stockist名の少し右）をクリックしても
        // チェックボックスの状態が変わるようにする
        $('.stockist-dropdown-item').off('click').on('click', function (e) {
          // ラベルやチェックボックス本体をクリックした場合は何もしない（そもそもチェック入るので。）
          if ($(e.target).is('label, input')) return;

          // アイテム（=aタグ。ラベルやチェックボックスの親要素）をクリックした場合
          // チェックボックスの状態を変更する（toggle）
          let checkbox = $(this).find('input[type="checkbox"]');
          checkbox.prop('checked', !checkbox.prop('checked')).trigger('change');
        });
      },

      /**
       * 'Purchasing Manager'検索条件のdrop-down menuのボタンのテキストを更新
       */
      updateStockistDropdownButtonState: function() {
        // チェックされたチェックボックスの数を取得
        let checkedCount = $('.stockist-checkbox:checked').length;
        // ドロップダウンメニューのボタン
        let $dropdownButton = $('#stockistDropdownMenuButton');

        if (checkedCount > 0) {
          // 1つ以上チェックされている場合のテキスト
          $dropdownButton.text('SELECTED');
          $dropdownButton.removeClass('btn-light').addClass('btn-success');
        } else {
          // 1つもチェックされていない場合のテキスト
          $dropdownButton.text('Please select');
          $dropdownButton.removeClass('btn-success').addClass('btn-light');
        }
      },
    },
    
    /* Lifecycle hooks */
    beforeCreate: function() {
    },
    created: function() {
    },
    beforeMount: function() {
    },
    mounted: function() {
      // こ要素が読み込まれた状態
      this.$nextTick(function() {
        this.handleStockistCheck();
        this.updateStockistDropdownButtonState();
      });
    },
    beforeUpdate: function() {
    },
    updated: function() {
    },
    beforeDestroy: function() {
    },
    destroyed: function() {
    }
  });
});
