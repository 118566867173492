import Vue from 'vue/dist/vue.esm'

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

import TurbolinksAdapter from 'vue-turbolinks'
Vue.use(TurbolinksAdapter)

import AdminSidebar from '../vue-components/admin_sidebar/AdminSidebar.vue'
import AdminSidebarAccordion from '../vue-components/admin_sidebar/AdminSidebarAccordion.vue'

/**
 * @description 管理者 サイドバー
 * ハンバーガーメニュー
 * 項目のアコーディオン
 * @param {void}
 * @returns {void}
 */
const adminSidebarHamburgerMenuVue = () => {

  const rootElement = document.getElementById('js-admin-sidebar-hamburger-vue')
  if (rootElement === null) {
    return
  }

  Vue.component('vue-admin-sidebar', AdminSidebar)
  Vue.component('vue-admin-sidebar-accordion', AdminSidebarAccordion)

  new Vue({
    el: rootElement,
    data() {
      return {
        windowSize: window.innerWidth,
        isPcMenuTogglerChecked: true,
        isSpMenuTogglerChecked: false,
      }
    },
    computed: {
      isAdminSidebarOpen() {
        const isPcAdminSidebarOpen = this.isPcMenuTogglerChecked && this.windowSize >= 768
        const isSpAdminSidebarOpen = this.isSpMenuTogglerChecked && this.windowSize < 767
        return isPcAdminSidebarOpen || isSpAdminSidebarOpen
      }
    },
    created() {
      // PC表示での管理者サイドバーの表示 or 非表示をCookieで保存する。
      const pcSidebarDisplay = this.$cookies.get('pc-sidebar-display')
      this.isPcMenuTogglerChecked = pcSidebarDisplay === 'false' ? false : true
      this.onChangePcMenuToggler()
    },
    mounted: function () {
      this.setFillHamburgerSidebarHeight()
      window.addEventListener('resize', this.setFillHamburgerSidebarHeight)
      window.addEventListener('resize', this.setWindowWidth)
    },
    beforeDestroy: function () {
      window.removeEventListener('resize', this.setFillHamburgerSidebarHeight)
      window.removeEventListener('resize', this.setWindowWidth)
    },
    methods: {
      setFillHamburgerSidebarHeight() {
        // iOS Safari対応。
        // * Safariのアドレスバーで、サイドバーの一部が隠れることを防ぐ。
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--sidebar-vh-supporting-ios', `${vh}px`)
      },
      onChangePcMenuToggler() {

        if (this.isPcMenuTogglerChecked === false) {
          // サイドバー閉じる Cookieセット
          this.$cookies.set('pc-sidebar-display', false)
        } else {
          // サイドバー開く Cookie削除
          this.$cookies.remove('pc-sidebar-display')
        }
      },
      onChangeSpMenuToggler() {

        const documentElement = document.querySelector("html")

        if (this.isSpMenuTogglerChecked === false) {
          documentElement.classList.remove("js-sp-sidebar-is-open")
        } else {
          // サイドバー開いた状態のクラスセット
          documentElement.classList.add("js-sp-sidebar-is-open")
        }

      },
      onClickSpAdminSidebarOverlay() {
        // SP表示 オーバーレイクリックでサイドバー閉じる
        this.isSpMenuTogglerChecked = false
        this.onChangeSpMenuToggler()
      },
      setWindowWidth() {
        this.windowSize = window.innerWidth
      }
    },
  })
}

document.addEventListener('turbolinks:load', adminSidebarHamburgerMenuVue)
