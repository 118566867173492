import Vue from 'vue/dist/vue.esm'
import TurbolinksAdapter from 'vue-turbolinks';
import { debounce } from 'lodash';

Vue.use(TurbolinksAdapter);

document.addEventListener('turbolinks:load', () => {
  const element = document.querySelector(".admin-ships-edit"); // ship編集画面
  if (!element) {
    return
  }

  const app = new Vue({
    el: ".admin-ships-edit",
    
    /* Data option */
    data: {
      dhlSendDateInitialValue: '未設定',
      dhlSendDateChangedMessage: '',
      dhlSendDateChanged: false,
      today: new Date().toISOString().slice(0, 10), // 'YYYY-MM-DD'
      
    },
    
    methods: {
      /* DHL発送日の初期値取得 */
      getInitialValueDhlSendDate: function() {
        // DHL発送日入力欄
        const element = document.getElementById('ship_dhl_shipment_send_date');
        // DHL発送日入力欄に値がある場合、DHL追跡番号入力欄に今日の日付を入力
        this.dhlSendDateInitialValue = element && element.value ? element.value : this.dhlSendDateInitialValue;
      },
      
      /* DHL発送日のchangeイベントメソッド */
      compareWithInitialValueDhlSendDate: function(event) {
        // DHL発送日入力欄の値
        let newValue = event.target.value || '未設定'; // 値がない場合、'未設定'
        // 表示メッセージ内容更新
        this.dhlSendDateChangedMessage = `${this.dhlSendDateInitialValue} → ${newValue}`;
        // 表示フラグtoggle
        this.dhlSendDateChanged = this.dhlSendDateInitialValue !== newValue;
      },
      
      updateAndFlashDhlShipmentSendDate: function() {
        this.addFlashToShipmentSendDateField(); // 周知のためCSSクラス追加と削除
        this.updateShipmentSendDate(); // DHL発送日入力欄に値をセット
        this.triggerChangeEvent('ship_dhl_shipment_send_date'); // changeイベントを発動させる
      },
      
      addFlashToShipmentSendDateField: function() {
        // DHL発送日入力欄
        const dhlShipmentSendDateInputElement = document.getElementById('ship_dhl_shipment_send_date');
        // DHL発送日入力欄と親DIVにCSSフラッシュを追加(周知のため)
        if (dhlShipmentSendDateInputElement) {
          const parentDiv = dhlShipmentSendDateInputElement.parentElement;
          this.addAndRemoveFlash(dhlShipmentSendDateInputElement);
          this.addAndRemoveFlash(parentDiv);
        }
      },
      
      updateShipmentSendDate: function() {
        // DHL追跡番号入力欄
        const dhlTrackingNumberInputElement = document.getElementById('ship_dhl');
        let trackingNumberValue = dhlTrackingNumberInputElement ? dhlTrackingNumberInputElement.value.trim() : '';

        // DHL発送日入力欄
        const dhlShipmentSendDateInputElement = document.getElementById('ship_dhl_shipment_send_date');
      
        //DHL追跡番号入力欄に値がある場合、DHL発送日入力欄に今日の日付を入力
        if (trackingNumberValue) {
          dhlShipmentSendDateInputElement.value = this.today;
        } else { // ない場合、nullを入力
          dhlShipmentSendDateInputElement.value = null;
        }
      },
  
      addAndRemoveFlash: function(targetElement) {
        // 要素にCSSフラッシュ用クラスを追加
        targetElement.classList.add("ships-edit-flash");
        
        // 2秒後クラスを削除
        setTimeout(function(){
          targetElement.classList.remove("ships-edit-flash");
        }, 2000);
      },
      
      triggerChangeEvent: function(elementId) {
        // changeイベントを発動させる
        const element = document.getElementById(elementId);
        if (element) {
          const event = new Event('change');
          element.dispatchEvent(event);
        }
      },
    },
    
    /* Lifecycle hooks */
    created: function() {
      // 'methods'ライフサイクル内部で直接debounceを使うと、コンテクスト(this.~~)関連の問題があるため、'created'ライフサイクル内でラッピング。
      this.updateAndFlashDhlShipmentSendDate = debounce(this.updateAndFlashDhlShipmentSendDate, 100); // 100ms以内でのイベントは無視(debounce)
    },
    mounted: function() {
      console.log('mounted');
      this.getInitialValueDhlSendDate();
    },
  });
});
